import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Amountdetail } from '../schemas/amountdetail';
import { Investment } from '../schemas/investment';
import { ColDetail } from '../schemas/col-detail';
import { Pension1 } from '../schemas/Pension1';
import { Pension2 } from '../schemas/Pension2';
import { AddDefferedPercent } from '../schemas/add-deffered-percent';
import { ClientData } from '../schemas/client-data';
import { SuperAdmin } from '../schemas/super-admin';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class AmountService {
     //url = 'http://localhost:4000';
     //url = 'http://cjcwealth360.com';
      url = environment.APP_URL;
   constructor(private http:HttpClient) { }

   /*--------------SuperAdmin Starts Form Here----------------*/


    addAdmin(param: SuperAdmin):Observable<SuperAdmin> {
       // return this.http.post<SuperAdmin>(this.url+'/admin/add',param)
        return this.http.post<SuperAdmin>(this.url+'/admin/add',param)
    }

    getAdminList(){
        //return this.http.get(this.url+'/admin/')
        return this.http.get(this.url+'/admin/')
    }

    signin(param){
        //return this.http.post(this.url+'/admin/finduser',param)
        return this.http.post(this.url+'/admin/finduser',param)
        
    }
  


   /*--------------SuperAdmin End Form Here----------------*/

   /*--------------Admin Starts Form Here----------------*/
   
   addClient(param: ClientData):Observable<ClientData> {
    console.log(param);
        //return this.http.post<ClientData>(this.url+'/clinfo/add',param)
        return this.http.post<ClientData>(this.url+'/clinfo/add',param)
    }

    findN(q){
        //console.log(q);
      //return this.http.get(this.url+'/clinfo/auto',q)
        return this.http.get(this.url+'/clinfo/auto',q)

    }

   getAdminList1(parms){      
      //return this.http.post(this.url+'/clinfo/findA/',parms)
        return this.http.post(this.url+'/clinfo/findA/',parms)
    }

    getClientList(){
        //return this.http.get(this.url+'/clinfo/')
          return this.http.get(`${this.url}/clinfo/`)
    }

    getCltList(parms){
        //return this.http.post(this.url+'/clinfo/findOne/',parms)
          return this.http.post(`${this.url}/clinfo/findOne/`,parms)
    }

    updateSpouse(id, param){
        //return this.http.post(this.url+'/clinfo/updateOne/'+id,param)
          return this.http.post(this.url+'/clinfo/updateOne/'+id,param)
    }

    updatClient(id, param){
        //return this.http.post(this.url+'/clinfo/update/'+id,param)
          return this.http.post(this.url+'/clinfo/update/'+id,param)
    }

    getClient(parms){
        //return this.http.post(this.url+'/clinfo/findAll/',parms)
          return this.http.post(this.url+'/clinfo/findAll/',parms)
    }

     getClt(parms){
        //return this.http.post(this.url+'/clinfo/findN/',parms)
          return this.http.post(this.url+'/clinfo/findN/',parms)
    }


   /*--------------Admin End Form Here----------------*/


    addAmount(param: Amountdetail):Observable<Amountdetail> {
        //return this.http.post<Amountdetail>(this.url+'/amount/add',param)
          return this.http.post<Amountdetail>(this.url+'/amount/add',param)
    }

    getamount(){
        //return this.http.get(this.url+'/amount/')
           return this.http.get(this.url+'/amount/')
    }

    colAmount(param:any):Observable<any>{
          //return this.http.post<ColDetail>(this.url+'/collage/add',param)
            return this.http.post<ColDetail>(this.url+'/collage/add',param)

    }
    invAmount(param:any):Observable<any>{
         return this.http.post<Investment>(this.url+'/investment/add',param)
    }

    getcolamount(){
        //return this.http.get(this.url+'/collage/')
          return this.http.get(this.url+'/collage/')
    }


    /*------------Client Pension Start Here--------------*/

    addpension(param: any):Observable<any> {
       //return this.http.post<Pension1>(this.url+'/pension1/addMany',param)
      return this.http.post<Pension1>(this.url+'/pension1/addMany',param)
    }

    updateAmt(param: any,name: any){
        console.log(param);
        console.log(name);
         // return this.http.post<Pension1>(this.url+'/pension1/update',param,name)
            return this.http.post<Pension1>(this.url+'/pension1/update',param,name)
    }

    getPensionData(parms){
        //return this.http.post(this.url+'/pension1/findAll/',parms)
          return this.http.post(this.url+'/pension1/findAll/',parms)
    }

    getpensionamount(){
        //return this.http.get(this.url+'/pension1/')
          return this.http.get(this.url+'/pension1/')
    }

    /*  getByClientId(parmsA){
        return this.http.post(this.url+'/pension1/findP/',parmsA)
        //  return this.http.post(`${this.url}/pension1/findP/`,parmsA)
    }
    */
    GetId(parms){
        //return this.http.post(this.url+'/pension1/findId/',parms)
          return this.http.post(this.url+'/pension1/findId/',parms)
    }

    getCurramount(parmsA){
        console.log(parmsA);
          //return this.http.post(this.url+'/pension1/findOne/',parmsA)
            return this.http.post(this.url+'/pension1/findOne/',parmsA)
    
}
    getListP(parms){
        //return this.http.post(this.url+'/pension1/findlst/',parms)
           return this.http.post(this.url+'/pension1/findlst/',parms)
    }


    deleteData(parms){
          //return this.http.post(this.url+'/pension1/del/',parms)
             return this.http.post(this.url+'/pension1/del/',parms)
    }


    addDeff1Percnt(param: AddDefferedPercent):Observable<AddDefferedPercent> {
        //return this.http.post<AddDefferedPercent>(this.url+'/deff1_Percent/add',param)
          return this.http.post<AddDefferedPercent>(this.url+'/deff1_Percent/add',param)
    }

    getDeff1Percnt(){
          //return this.http.get(this.url+'/deff1_Percent/')
          return this.http.get(this.url+'/deff1_Percent/')
    }

    /*------------Spouse Pension Start Here--------------*/

    addpension2(param: any):Observable<any> {
          //return this.http.post<Pension2>(this.url+'/pension2/addMany',param)
          return this.http.post<Pension2>(this.url+'/pension2/addMany',param)
    }

    updateAmt2(param: any,name: any){
        //return this.http.post<Pension2>(this.url+'/pension2/update',param,name)
          return this.http.post<Pension2>(this.url+'/pension2/update',param,name)
    }

    getPensionData2(parms){
       // return this.http.post(this.url+'/pension2/findAll/',parms)
          return this.http.post(this.url+'/pension2/findAll/',parms)
    }

    getpensionamount2(){
        //return this.http.get(this.url+'/pension2/')
          return this.http.get(this.url+'/pension2/')
    }

    getCurramount2(parmsA){
        //return this.http.post(this.url+'/pension2/findOne/',parmsA)
          return this.http.post(this.url+'/pension2/findOne/',parmsA)   
    }

    getListP2(parms){
        //return this.http.post(this.url+'/pension2/findlst/',parms)
          return this.http.post(this.url+'/pension2/findlst/',parms)
    }

    getInvest(){
        return this.http.get(this.url+'/investment/');  
    }
    defaultValuee(){
        return this.http.get(this.url + '/')
    }

}   

