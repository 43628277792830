import { Injectable } from '@angular/core';
import { Saving } from '../schemas/Saving';
import { ColDetail } from '../schemas/col-detail';
import { Investment } from '../schemas/investment';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SavingService {
   //url = 'http://localhost:4000';
    // url = 'http://cjcwealth360.com';
         url = environment.APP_URL;
   constructor(private http:HttpClient) { }

    addAmount(param: any):Observable<any> {
		//return this.http.post<Saving>(this.url+'/saving/add',param)
		return this.http.post<Saving>(this.url+'/saving/add',param)
	}

	getamount(){
		  //return this.http.get(this.url+'/saving/')
		    return this.http.get(this.url+'/saving/')
	}

    getCurrentA(parms){		
		//return this.http.post(this.url+'/saving/findOne/',parms)
		  return this.http.post(this.url+'/saving/findOne/',parms)
	}
	getCurrentI(parms){
		return this.http.post(this.url+'/investment/findOne/',parms)
	}

	getCurrentC(parms){		
		//return this.http.post(this.url+'/saving/findOne/',parms)
		  return this.http.post(this.url+'/collage/findOne/',parms)
	}

	updateA(param: any,name: any){
		//return this.http.post<Saving>(this.url+'/saving/update/',param,name)
		  return this.http.post<Saving>(this.url+'/saving/update',param,name)
	}

	getList(parms){		
		//return this.http.post(this.url+'/saving/findlist/',parms)
		  return this.http.post(this.url+'/saving/findlist/',parms)
	}

	/*-------collage--------*/
	updateC(param: any, name: any){
		return this.http.post<ColDetail>(this.url+'/collage/update',param,name)
	}

	updateI(param: any, name: any){
		return this.http.post<Investment>(this.url+'/investment/update',param,name)
	}

	getCollageList(parms){	
		  return this.http.post(this.url+'/collage/findlist/',parms)
	}
	getInvestList(parms){
		return this.http.post(this.url+'/investment/findlist/',parms)
	}
	/*getData(id){
		console.log(id);
		return this

	}*/
}
