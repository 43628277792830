 <div class="loading" *ngIf ="loader">Loading&#8230;</div> 
<div class="pagerow">
    <div class="laft-bar bg-light float-left">
        <div class="site-logo">
            <a href="#"><img alt="Logo" class="side-logo" src="../assets/images/logo.png"></a>
        </div>
        <div class="admin-panel">
            <ul>
                     <li> <a  (click)="Index()"><i class="fas fa-home"></i> Model</a></li>
                 
                 <!-- <li> <a [routerLink]="['/index']" routerLinkActive="active"><i class="fas fa-home"></i> Model</a></li> -->
                  <li ><a [routerLink]="['/client-info']" routerLinkActive="active"><i aria-hidden="true" class="fa fa-info-circle"></i> Client Info</a></li>
                   <li tabindex="0"><a [routerLink]="['/protection']" routerLinkActive="active"><img src="../assets/images/protection.png" class="pro"> Protection</a></li>
            </ul>
        </div>
    </div>
    <div class="container-fluid content-area">
        <div class="cover-header">
            <div class="top-row">
                <div class="search-wrap">

                          <input type="text" #searchbar class="option_input" style="text-transform: capitalize;" [(ngModel)]="searchInput" id="myInput" (keyup)="fetchName($event)" placeholder="Search.." />
                        <button type="submit" name="submit" class="info-menu"><i class="fas fa-search"></i></button>
                          <div *ngIf="showSearch" class="search-list">                       
                           <li  *ngFor="let item of items|filter:searchInput" (click)="getClientList(item._id)">{{item.Admin_Name | titlecase}}</li>
                           <!-- {{item.Admin_Name | titlecase}} -->
                    </div>
                </div>
                <div class="info-menu">
                    <ul >
                      <!--   <li ><a href="#"><i class="far fa-bell"></i></a></li>
                        <li ><a href="#"><i class="far fa-question-circle"></i></a></li> -->
                      <li>
                        <h4><i class="fa fa-user"></i>Login as {{adminN | titlecase}}</h4>
                      </li>
                        <li >
                            <!-- <button >SIGN UP</button> -->
                            <button   (click)="openModal()">Add Client</button>        
                        </li>
                        <li>
                        <button class="loginbtn" (click)="logOut()">Log Out</button>
                          </li>
                    </ul>
                </div>
            </div>
        
        
           
        </div>
      
        <div class="dashboard">
            <div class="left-content">

            <div class="bredcrumb">
                <ul >
                    <li ><a href="#"><i class="fas fa-info-circle"></i> Client Info</a></li> -
                    <li >Dashboard</li>
                </ul>
            </div>

                <div class="frm-box">
                <form id="needs-validation" novalidate="" class="ng-untouched ng-pristine ng-valid">
                    <div class="d-flex header-form">
                    <div class="w-100">
                    <h4 >About the Client:</h4>
                    <div class="d-block my-3">
                        <p >Are you currently married?</p>
                        <label class="custom-control custom-radio">
                            <input class="custom-control-input input-checkbox" id="yes-1-id" required="" type="checkbox"><span class="custom-control-description">Yes</span></label>
                        <label class="custom-control custom-radio">
                            <input class="custom-control-input" id="no-id-1" required="" type="checkbox"><span class="custom-control-description">No</span></label>
                    </div>
                    <div class="d-block my-3">
                        <p >Were you previously married?</p>
                        <label class="custom-control custom-radio">
                            <input class="custom-control-input" id="yes-id" required="" type="checkbox"><span class="custom-control-description">Yes</span></label>
                        <label class="custom-control custom-radio">
                            <input class="custom-control-input" id="no-id" required="" type="checkbox"><span class="custom-control-description">No</span></label>
                    </div>
                    </div>
                   <!--  <div>
                        <button class="hide_btn">Hide Children</button>
                    </div> -->
                    </div>
                </form>
                 <ul>
                      <li><button class="shifticon" (click)="saveInfo()" >Save</button></li>
                  </ul>

                    <div class="dashboard dash-head">
                        <div class="saprator-1">
                            <span class="sapbox img-size"><img src="../assets/images/add_user.png"/></span>
                        </div>
                        </div>
            </div>

                <div class="top-forms rowfirst
                 ">
                    <div class=" width-46 width-left client-info-width">
                        <div class="family-info-inner">
                            <h5 >PERSONAL INFORMATION<span > (Client)</span></h5>
                            <div class="top-form">
                                <p class="sub-title ag-left">Age:</p>
                                <ul class="infohead fieldtitle_bg li-3 no-bdr">
                                    <li class="widthequl">
                                        <p ></p>
                                    </li>
                                    <li class="eql22">
                                        <p >Surname </p>
                                    </li>
                                    <li >
                                        <p >First</p>
                                    </li>
                                    <li >
                                        <p >Last </p>
                                    </li>
                                    <li >
                                        <p >Birthday </p>
                                    </li>
                                    <li >
                                        <p >Sex </p>
                                    </li>  
                                </ul>
                                <ul class="input-box form-row infohead li-3 newstyle11">
                                    <li class="form-group" style="width:20% !important;">
                                        <input class="outline-none" placeholder="Client:" readonly="" type="text">
                                    </li>
                                    <li class="form-group reducewidth">
                                        <input name="srnm" placeholder="Mr." type="text" [(ngModel)]="surname">
                                    </li>
                                    <li class="form-group">
                                        <input name="firstName" type="text" [(ngModel)]="fstName"   >
                                    </li>
                                    <li class="form-group">
                                        <input name="last" type="text" [(ngModel)]="lstName">
                                    </li>
                                    <li class="form-group bdy">
                                        <input type="date" id="birthday" name="birthday" [(ngModel)]="birth">
                                    </li>
                                    <li class="form-group">
                                        <input name="sex" type="text" [(ngModel)]="sex">
                                    </li>
                                </ul>
                            </div>
                            <div class="info-title">
                                <ol >
                                    <li ><i aria-hidden="true" class="fas fa-plus"></i></li>
                                    <li >OCCUPATION</li>
                                    <li ><i aria-hidden="true" class="fas fa-minus"></i></li>
                                </ol>
                            </div>
                            <form class="dirction-left ng-untouched ng-pristine ng-valid" novalidate="">
                                <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-5">
                                        <input class="outline-none" placeholder="OTHER INCOME" readonly="" type="text">
                                    </li>
                                    <li class="form-group w-30 text-center">
                                        <input class="outline-none" placeholder="INCOME" readonly="" type="text">
                                    </li>
                                </ul>
                                <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-10">
                                        <input placeholder="Accountant" [(ngModel)]="accountant" [ngModelOptions]="{standalone: true}" style="text-transform: capitalize;" type="text" >
                                    </li>
                                    <li class="form-group w-30 d-flex main-info">
                                        <span class="SizeDollar">$</span>
                                        <input placeholder="0" [(ngModel)]="account" [ngModelOptions]="{standalone: true}" class="outline-none" (keyup)="Acc1($event)"  (blur)="SumofAll2()" type="text">
                                    </li>
                                </ul>
                                <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-10">
                                        <input placeholder="Entrepreneur" style="text-transform: capitalize;" [(ngModel)]="entrepreneur" [ngModelOptions]="{standalone: true}" type="text">
                                    </li>
                                    <li class="form-group w-30  d-flex main-info">
                                        <span class="SizeDollar">$</span>
                                        <input placeholder="0" [(ngModel)]="enterp" [ngModelOptions]="{standalone: true}" class="outline-none" (keyup)="Ent1($event)" type="text" (blur)="SumofAll2()">
                                    </li>
                                </ul>
                                <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-5">
                                        <input class="outline-none" placeholder="TOTAL INCOME" readonly="" type="text">
                                    </li>
                                    <li class="form-group w-30 d-flex main-info">
                                        <span class="SizeDollar">$</span>
                                        <input placeholder="0" [(ngModel)]="totalOcp" class="outline-none" [ngModelOptions]="{standalone: true}" type="text" readonly="">
                                    </li>
                                </ul>
                            </form>
                            <div class="info-title">
                                <ol >
                                    <li ><i aria-hidden="true" class="fas fa-plus" (click)="funcAppendProfile()"></i></li>
                                    <li >CHILDREN</li>
                                    <li ><i aria-hidden="true" class="fas fa-minus" (click)="funcRemoveProfile()"></i></li>
                                </ol>
                            </div>
                            <form novalidate="" class="ng-untouched ng-pristine ng-valid"  *ngFor="let prfCount of loopFromNumber(profileControls); let i =index">
                                
                                <ul class="form-row d-flex input-style list-style-border" >
                                    <li class="form-group w-70 pl-5 ">
                                        <input class="outline-none font-weight-bold" placeholder="Child A" readonly="" type="text">
                                    </li>
                                    <li class="form-group w-30 text-center ">
                                        <input class="outline-none" placeholder="18" type="text">
                                    </li>
                                </ul>
                                <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-10"> 
                                     <ul class="sublist">
                                            <li>Married 1 <input type="checkbox" name="married1"></li>
                                            <li>Married 2 <input type="checkbox" name="married1"></li>
                                        </ul>
                                    </li>
                                    <li class="form-group w-30 text-center">
                                        <label class="custom-control custom-radio box-style"><span >Yes</span>
                                            <input class="custom-control-input height-auto"  required="" type="checkbox"  name="yesss"    (click)="marrigeDetail('yes')">
                                        </label>
                                        <label class="custom-control custom-radio box-style"><span >No</span>
                                            <input class="custom-control-input height-auto"  required="" type="checkbox"  name="nooo"   (click)="marrigeDetail('no')">
                                        </label>
                                    </li>
                                </ul>
                                <ul class="form-row d-flex input-style list-style-border" *ngIf ="child == true">
                                    <li class="form-group w-70 pl-10">
                                        <input class="outline-none" placeholder="How Many Children?
" readonly="" type="text">
                                    </li>
                                    <li class="form-group w-30 text-center">
                                        <input class="outline-none" placeholder="2" type="text">
                                    </li>
                                </ul>
                                <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-10">
                                        <input class="outline-none" placeholder="Education Cost" readonly="" type="text">
                                    </li>
                                    <li class="form-group w-30 text-center">
                                        <input class="outline-none" placeholder="$1000" type="text">
                                    </li>
                                </ul>
                                  <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-10">
                                        <input class="outline-none" placeholder="Special Needs" readonly="" type="text">
                                    </li>
                                    <li class="form-group w-30 text-center">
                                        <label class="custom-control custom-radio box-style"><span >Yes</span>
                                            <input class="custom-control-input height-auto"  required="" type="checkbox" >
                                        </label>
                                        <label class="custom-control custom-radio box-style"><span >No</span>
                                            <input class="custom-control-input height-auto"  required="" type="checkbox">
                                        </label>
                                    </li>
                                </ul>
                            </form>
                            
                           <!--  <form class="form-horizontal ng-untouched ng-pristine ng-valid" novalidate="">
                                <label class="text-centre w-100">Special Needs</label>
                                <textarea class="form-control-text" id="texttt" name="texttt" row="3"></textarea>
                            </form> -->
                      
                        </div>
                    </div>
                    <div class=" width-46 width-right client-info-width">
                        <div class="family-info-inner">
                            <h5 >PERSONAL INFORMATION <span >(Spouse)</span></h5>
                            <div class="top-form">
                                <p class="sub-title ag-left">Age:</p>
                                <ul class="infohead fieldtitle_bg li-3 no-bdr">
                                    <li class="widthequl">
                                        <p ></p>
                                    </li>
                                    <li class="eql22">
                                        <p >Surname </p>
                                    </li>
                                    <li >
                                        <p >First </p>
                                    </li>
                                    <li >
                                        <p >Last </p>
                                    </li>
                                    <li >
                                        <p >Birthday </p>
                                    </li>
                                    <li >
                                        <p >Sex </p>
                                    </li>
                                </ul>
                                <div >
                                    <ul class="input-box form-row infohead li-3 newstyle11">
                                        <li class="form-group" style="width:20% !important;">
                                            <input class="outline-none" placeholder="Spouse:" readonly="" type="text">
                                        </li>
                                        <li class="form-group reducewidth">
                                        <input name="srnm" placeholder="Mr." type="text" [(ngModel)]="SpouseSr">
                                    </li>
                                        <li class="form-group">
                                            <input name="fst" type="text" [(ngModel)]="SpouseNm">
                                        </li>
                                        <li class="form-group">
                                            <input name="txtlast" type="text" [(ngModel)]="Spouselast">
                                        </li>
                                        <li class="form-group bdy">
                                        <input type="date" id="birthday" name="birthday" [(ngModel)]="Spousebirth">
                                    </li>
                                        <li class="form-group">
                                            <input name="txtsex" type="text" [(ngModel)]="SpouseSx">
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="info-title">
                                <ol >
                                    <li ><i aria-hidden="true" class="fas fa-plus"></i></li>
                                    <li >OCCUPATION</li>
                                    <li ><i aria-hidden="true" class="fas fa-minus"></i></li>
                                </ol>
                            </div>
                            <form class="dirction-left ng-untouched ng-pristine ng-valid" novalidate="">
                                <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-5">
                                        <input class="outline-none" placeholder="OTHER INCOME" readonly="" type="text">
                                    </li>
                                    <li class="form-group w-30 text-center">
                                        <input class="outline-none" placeholder="INCOME" readonly="" type="text">
                                    </li>
                                </ul>
                                 <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-10">
                                        <input placeholder="Accountant" [(ngModel)]="SpouseAcc1" [ngModelOptions]="{standalone: true}" style="text-transform: capitalize;" type="text" >
                                    </li>
                                    <li class="form-group w-30 d-flex main-info">
                                        <span class="SizeDollar">$</span>
                                        <input placeholder="0" [(ngModel)]="Spouse_In1" [ngModelOptions]="{standalone: true}" class="outline-none" (keyup)="Acc2($event)"  (blur)="SumofAll1()" type="text">
                                    </li>
                                </ul>
                                <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-10">
                                        <input placeholder="Entrepreneur" style="text-transform: capitalize;" [(ngModel)]="SpouseEnt1" [ngModelOptions]="{standalone: true}" type="text">
                                    </li>
                                    <li class="form-group w-30  d-flex main-info">
                                        <span class="SizeDollar">$</span>
                                        <input placeholder="0" [(ngModel)]="Spouse_In2" [ngModelOptions]="{standalone: true}" class="outline-none" (keyup)="Ent1($event)" type="text" (blur)="SumofAll1()">
                                    </li>
                                </ul>
                                 <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-5">
                                        <input class="outline-none" placeholder="TOTAL INCOME" readonly="" type="text">
                                    </li>
                                    <li class="form-group w-30 d-flex main-info">
                                        <span class="SizeDollar">$</span>
                                        <input placeholder="0" [(ngModel)]="Spouse_Total1" class="outline-none" [ngModelOptions]="{standalone: true}" type="text" readonly="">
                                    </li>
                                </ul>
                            </form>
                            <div class="info-title">
                                <ol >
                                    <li ><i aria-hidden="true" class="fas fa-plus" (click)="ChildCol()"></i></li>
                                    <li >CHILDREN</li>
                                    <li ><i aria-hidden="true" class="fas fa-minus" (click)="ChildExp()"></i></li>
                                </ol>
                            </div>
                            <form novalidate="" class="ng-untouched ng-pristine ng-valid" *ngFor="let prfCount of loopFromNumber(ChildDetails); let i =index">
                                <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-5 ">
                                        <input class="outline-none font-weight-bold" placeholder="Child A" readonly="" type="text">
                                    </li>
                                    <li class="form-group w-30 text-center ">
                                        <input class="outline-none" placeholder="18" type="text">
                                    </li>
                                </ul>
                                 <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-10"> 
                                     <ul class="sublist">
                                            <li>Married 1 <input type="checkbox" name="married1"></li>
                                            <li>Married 2 <input type="checkbox" name="married1"></li>
                                        </ul>
                                    </li>
                                    <li class="form-group w-30 text-center">
                                        <label class="custom-control custom-radio box-style"><span >Yes</span>
                                            <input class="custom-control-input height-auto" id="yes-1-id" required="" type="checkbox" (click)="marrigeDetail1('yes')">
                                        </label>
                                        <label class="custom-control custom-radio box-style"><span >No</span>
                                            <input class="custom-control-input height-auto" id="yes-1-id" required="" type="checkbox" (click)="marrigeDetail1('no')">
                                        </label>
                                    </li>
                                </ul>
                                <ul class="form-row d-flex input-style list-style-border" *ngIf ="child1 == true">
                                    <li class="form-group w-70 pl-10">
                                        <input class="outline-none" placeholder="How Many Children?
" readonly="" type="text">
                                    </li>
                                    <li class="form-group w-30 text-center">
                                        <input class="outline-none" placeholder="2" type="text">
                                    </li>
                                </ul>
                                <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-10">
                                        <input class="outline-none" placeholder="Education Cost" readonly="" type="text">
                                    </li>
                                    <li class="form-group w-30 text-center">
                                        <input class="outline-none" placeholder="$1000" type="text">
                                    </li>
                                </ul>
                                    <ul class="form-row d-flex input-style list-style-border">
                                    <li class="form-group w-70 pl-10">
                                        <input class="outline-none" placeholder="Special Needs" readonly="" type="text">
                                    </li>
                                    <li class="form-group w-30 text-center">
                                        <label class="custom-control custom-radio box-style"><span >Yes</span>
                                            <input class="custom-control-input height-auto"  required="" type="checkbox" >
                                        </label>
                                        <label class="custom-control custom-radio box-style"><span >No</span>
                                            <input class="custom-control-input height-auto"  required="" type="checkbox">
                                        </label>
                                    </li>
                                </ul>
                            </form>
                           <!--  <hr >
                            <form class="form-horizontal ng-untouched ng-pristine ng-valid" novalidate="">
                                <label class="text-centre w-100">Special Needs</label>
                                <textarea class="form-control-text" id="texttt" name="texttt" row="3"></textarea>
                            </form> -->
                            <!-- <div class="top-form">
                                <h5 class="sub-title ">RETIREMENT</h5>
                                <div class="form-1">
                                    <form class="form-horizontal ng-untouched ng-pristine ng-valid" novalidate="">
                                        <div class="d-flex">
                                            <div class="form-group">
                                                <label >When do you plan to retire:</label>
                                                <input id="textinput" name="textinput" type="text">
                                            </div>
                                            <div class="form-group chk-box">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Retired</span></div>
                                        </div>
                                        <textarea class="form-control-text" id="textarea" name="textarea" row="3"></textarea>
                                    </form>
                                </div>
                            </div>
                            <div class="border-hr"></div> -->
                        </div>
                    </div>
                </div>
             <div class="top-forms rowfirst ">
                    <div class=" width-46 width-left client-info-width">
                        <div class="family-info-inner">
                            <div class="top-form">
                                <h5 class="sub-title ">RETIREMENT</h5>
                                <div class="form-1">
                                    <form class="form-horizontal ng-untouched ng-pristine ng-valid" novalidate="">
                                        <div class="d-flex">
                                            <div class="form-group">
                                                <label >When do you plan to retire:</label>
                                                <input id="textinput" name="textinput" type="text">
                                            </div>
                                            <div class="form-group chk-box">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Retired</span></div>
                                        </div>
                                        <textarea class="form-control-text" id="textarea" name="textarea" row="3"></textarea>
                                    </form>
                                </div>
                                <div class="border-hr"></div>
                            </div>
                        </div>
                    </div>
               

            <div class=" width-46 width-right client-info-width">
                        <div class="family-info-inner">
                            <div class="top-form">
                                <h5 class="sub-title ">RETIREMENT</h5>
                                <div class="form-1">
                                    <form class="form-horizontal ng-untouched ng-pristine ng-valid" novalidate="">
                                        <div class="d-flex">
                                            <div class="form-group">
                                                <label >When do you plan to retire:</label>
                                                <input id="textinput" name="textinput" type="text">
                                            </div>
                                            <div class="form-group chk-box">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Retired</span></div>
                                        </div>
                                        <textarea class="form-control-text" id="textarea" name="textarea" row="3"></textarea>
                                    </form>
                                </div>
                                <div class="border-hr"></div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="saprator-blue"><span class="sapbox img-size"><img src="../assets/images/employeeinfo.png"></span></div>
                <div class="top-forms rowfirst ">
                    <div class=" width-46 gray-bg width-left client-info-width">
                        <div class="family-info-inner">
                            <h5 >EMPLOYMENT INFORMATION <span >(Client)</span></h5>
                            <div class="top-form boxwrap">
                                <div class="form-2 ">
                                    <form class="form-horizontal ng-untouched ng-pristine ng-valid" novalidate="">
                                        <div class="d-flex">
                                            <div class="form-group form-2-group">
                                                <label class="label-width">Years with the Federal Government:</label>
                                                <input id="textinput10" name="textinput" type="text">
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group chk-box-2">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Not a Federal Employee</span></div>
                                        </div>
                                        <p class="sub-title pension-font"><i class="fas fa-caret-down"></i>Pension Type:</p>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group chk-box-3">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >FERS</span>
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >CSRS</span>
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >SENATE</span>
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >FAA</span>
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >FOREIGN SERVICE</span></div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group">
                                                <label class="label-width"><i class="fas fa-caret-right"></i> Employer:</label>
                                                <input id="textinput11" name="textinput" type="text">
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group">
                                                <label class="label-width"><i class="fas fa-caret-right"></i> Nature of Business:</label>
                                                <input id="textinput12" name="textinput" type="text">
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group">
                                                <label class="label-width"><i class="fas fa-caret-right"></i> Occupation:</label>
                                                <input id="textinput13" name="textinput" type="text">
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group">
                                                <label class="label-width"><i class="fas fa-caret-right"></i> Title:</label>
                                                <input id="textinput14" name="textinput" type="text">
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group">
                                                <label class="label-width"><i class="fas fa-caret-right"></i> Employer Phone Number:</label>
                                                <input id="textinput15" name="textinput" type="text">
                                            </div>
                                        </div>
                                        <p class=" pension-font em-left"><i class="fas fa-caret-right"></i>Email:</p>
                                        <div class="lft-in">
                                            <div class="d-flex">
                                                <div class="form-group form-2-group">
                                                    <label class="checkbox-inline">
                                                        <input type="checkbox">
                                                    </label><span >Business</span>
                                                    <input id="textinput1" name="textinput" type="text">
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="form-group form-2-group">
                                                    <label class="checkbox-inline">
                                                        <input type="checkbox">
                                                    </label><span >Business</span>
                                                    <input id="textinput1" name="textinput" type="text">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" width-46 gray-bg width-left client-info-width">
                        <div class="family-info-inner">
                            <h5 >EMPLOYMENT INFORMATION <span >(Spouse)</span></h5>
                            <div class="top-form boxwrap">
                                <div class="form-2 ">
                                    <form class="form-horizontal ng-untouched ng-pristine ng-valid" novalidate="">
                                        <div class="d-flex">
                                            <div class="form-group form-2-group">
                                                <label class="label-width">Years with the Federal Government:</label>
                                                <input id="textinput10" name="textinput" type="text">
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group chk-box-2">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Not a Federal Employee</span></div>
                                        </div>
                                        <p class="sub-title pension-font"><i class="fas fa-caret-down"></i>Pension Type:</p>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group chk-box-3">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >FERS</span>
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >CSRS</span>
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >SENATE</span>
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >FAA</span>
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >FOREIGN SERVICE</span></div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group">
                                                <label class="label-width"><i class="fas fa-caret-right"></i> Employer:</label>
                                                <input id="textinput11" name="textinput" type="text">
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group">
                                                <label class="label-width"><i class="fas fa-caret-right"></i> Nature of Business:</label>
                                                <input id="textinput12" name="textinput" type="text">
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group">
                                                <label class="label-width"><i class="fas fa-caret-right"></i> Occupation:</label>
                                                <input id="textinput13" name="textinput" type="text">
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group">
                                                <label class="label-width"><i class="fas fa-caret-right"></i> Title:</label>
                                                <input id="textinput14" name="textinput" type="text">
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="form-group form-2-group">
                                                <label class="label-width"><i class="fas fa-caret-right"></i> Employer Phone Number:</label>
                                                <input id="textinput15" name="textinput" type="text">
                                            </div>
                                        </div>
                                        <p class=" pension-font em-left"><i class="fas fa-caret-right"></i>Email:</p>
                                        <div class="lft-in">
                                            <div class="d-flex">
                                                <div class="form-group form-2-group">
                                                    <label class="checkbox-inline">
                                                        <input type="checkbox">
                                                    </label><span >Business</span>
                                                    <input id="textinput1" name="textinput" type="text">
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="form-group form-2-group">
                                                    <label class="checkbox-inline">
                                                        <input type="checkbox">
                                                    </label><span >Business</span>
                                                    <input id="textinput1" name="textinput" type="text">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="saprator-blue"><span class="sapbox img-size"><img src="../assets/images/concers.png"></span></div>
                <div class="top-forms rowfirst ">
                    <div class=" width-46 gray-bg width-left client-info-width">
                        <div class="family-info-inner">
                            <h5 >BIGGEST CONCERNS <span >(Client)</span></h5>
                            <div class="top-form boxwrap">
                                <div class="form-2 ">
                                    <form class="form-horizontal ng-untouched ng-pristine ng-valid" novalidate="">
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox">
                                            </label><span >Long - Term Care</span></div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox">
                                            </label><span >Running Out of Money / Not Having Enough</span></div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox">
                                            </label><span >Moving</span></div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox">
                                            </label><span >College</span></div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox">
                                            </label><span >Mortgage</span></div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox">
                                            </label><span >Will I have Enough Money to Live on in Retirement:</span></div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <div class="select">
                                                <select id="slct">
                                                    <option >Select</option>
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" width-46 gray-bg width-left client-info-width">
                        <div class="family-info-inner">
                            <h5 >BIGGEST CONCERNS <span >(Spouse)</span></h5>
                            <div class="top-form boxwrap">
                                <div class="form-2 ">
                                    <form class="form-horizontal ng-untouched ng-pristine ng-valid" novalidate="">
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox">
                                            </label><span >Long - Term Care</span></div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox">
                                            </label><span >Running Out of Money / Not Having Enough</span></div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox">
                                            </label><span >Moving</span></div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox">
                                            </label><span >College</span></div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox">
                                            </label><span >Mortgage</span></div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox">
                                            </label><span >Will I have Enough Money to Live on in Retirement:</span></div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <div class="select">
                                                <select id="slct">
                                                    <option >Select</option>
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                <div class="saprator-blue w-50">
                    <span class="sapbox img-size"><img src="../assets/images/real_estate.png"></span>
                </div>
                 <div class="saprator-blue w-50">
                    <span class="sapbox img-size">   <img src="../assets/images/plus.png"></span>
                </div>
             </div>
                <div class="top-forms rowfirst ">
                    <div class=" width-46 width-left client-info-width">
                    <div class=" width-100 gray-bg width-left client-info-width">
                        <div class="family-info-inner">
                            <h5 >REAL ESTATE </h5>
                            <div class="top-form boxwrap">                            
                                <div class="form-2 ">
                                    <div class="estate_box">
                                    <div class="d-flex planwrap justify-content-between">
                                        <div>1 Do you plan to Move:</div>
                                       <div class="d-flex yes_no">
                                            <div> <label>Yes</label><input type="checkbox" name="showplan"    (click)="showDetails('showplan')"></div>
                                           <div> <label>No</label><input type="checkbox" name="hideplan"  (click)="showDetails('hideplan')"></div>
                                        </div>
                                    </div>
                                     <div class=" planwrap">
                                     <div *ngIf ="show == true">                    
                                         <textarea ></textarea>
                                    </div>
                                    </div>
                                    </div>
                                     <div class="estate_box">
                                    <div class="d-flex planwrap justify-content-between">
                                        <div>2 Will the new Home you purchase be paid off form Existing Home
</div>
                                       <div class="d-flex yes_no">
                                            <div><label>Yes</label><input type="checkbox" name=""></div>
                                            <div><label>No</label><input type="checkbox" name=""></div>
                                        </div>
                                    </div>
                                   
                                    </div>
 <div class="estate_box">
                                    <div class="d-flex planwrap justify-content-between">
                                        <div>3 How Much will the new home be
</div>
                                    
                                    </div>
                                     <div class=" planwrap">
                                     <div>                                       
                                         <textarea ></textarea>
                                    </div>
                                    </div>
                                    </div>
                                     <div class="estate_box">
                                    <div class="d-flex planwrap justify-content-between">
                                        <div>4 Plan for Moving</div>                                    
                                    </div>
                                     <div class=" planwrap">
                                     <div>                                       
                                         <textarea ></textarea>
                                    </div>
                                    </div>
                                    </div>                             
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
              
             
                    <div class=" width-46 width-right client-info-width">
                    <div class=" width-100 gray-bg width-left client-info-width">
                        <div class="family-info-inner">
                            <h5 >LONG - TERM CARE </h5>
                            <div class="top-form boxwrap">
                                <div class="form-2 ">
                                    <form class="form-horizontal ng-untouched ng-pristine ng-valid" novalidate="">
                                        <div class="d-flex subheader">
                                        <div>
                                        <div class="mb-3">
                                            1. Are you Open to disscusions on how to plan for long term care 
                                           </div>  
                                           <div>2. What are your feelings on this topic?  </div>
                                       </div>
                                        <div class="d-flex yes_no">
                                            <div> <label>Yes</label><input type="checkbox" name=""></div>
                                           <div> <label>No</label><input type="checkbox" name=""></div>
                                        </div>
                                    </div>
                                        <div class="form-group form-2-group realstate-box">
                                            <textarea ></textarea>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="saprator-blue "><span class="sapbox img-size">
                    <img src="../assets/images/dallersign.png">
                    </span></div>
                <div class="top-forms rowfirst ">
                    <div class=" width-100 gray-bg width-left client-info-width">
                        <div class="family-info-inner">
                            <h5 >WHAT IS THE PURPOSE OF YOUR MONEY? </h5>
                            <div class="top-form boxwrap">
                                <div class="form-2 ">
                                    <form class="form-horizontal ng-untouched ng-pristine ng-valid" novalidate="">
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox">
                                            </label><span >Legacy:</span></div>
                                        <div class="dollor-left-chk">
                                            <div class="form-group form-2-group biggest-lable">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Not a Big Concern</span></div>
                                            <div class="form-group form-2-group biggest-lable">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >50 / 50</span></div>
                                            <div class="form-group form-2-group biggest-lable">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox" name="Bigconcern" [(ngModel)]="theCheckbox2" (change)="showConcern($event)" [ngModelOptions]="{standalone: true}">
                                                </label><span>Big Concern</span></div>
                                            <div class="form-group form-2-group dollor-text-box" *ngIf = "concern"><i class="far fa-circle"></i>
                                                <textarea ></textarea>
                                            </div>
                                            <div class="dollor-left-chk" *ngIf="!concern">
                                            
                                        </div>
                                        </div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox"  name="capital" [(ngModel)]="theCheckbox1" (change)="showCaptial($event)" [ngModelOptions]="{standalone: true}">
                                            </label><span >Capital Preservation
</span></div>
                                        <div class="dollor-left-chk" *ngIf = "Capital">
                                            <div class="form-group form-2-group dollor-text-box"><i class="far fa-circle"></i>
                                                <textarea ></textarea>
                                            </div>
                                        </div>
                                        <div class="dollor-left-chk" *ngIf="!Capital">
                                            
                                        </div>
                                        <div class="form-group form-2-group biggest-lable">
                                            <label class="checkbox-inline">
                                                <input type="checkbox" [(ngModel)]="theCheckbox" (change)="toggleVisibility($event)" [ngModelOptions]="{standalone: true}">
                                            </label><span >Would prefer Strategies for Maximum Income
                                     </span></div>
                                        <div class="dollor-left-chk" *ngIf="marked">
                                            <div class="form-group form-2-group dollor-text-box"><i class="far fa-circle"></i>
                                                <textarea></textarea>
                                            </div>
                                        </div>
                                        <div class="dollor-left-chk" *ngIf="!marked">
                                            
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="saprator-blue "><span class="sapbox img-size">
                    <img src="../assets/images/building.png">
                    </span></div>
                <div class="top-forms rowfirst ">
                    <div class=" width-46 gray-bg width-left client-info-width mb-6">
                        <div class="family-info-inner">
                            <h5 >INVESTMENT OPTIONS (Client) <span >(Client)</span></h5>
                            <div class="top-form boxwrap">
                                <div class="form-2 ">
                                    <form class="p-0 form-horizontal ng-untouched ng-pristine ng-valid" novalidate="">
                                        <p class=" pension-font em-left"><i class="fas fa-caret-right"></i>What is your Risk Tolerance</p>
                                        <div class="lft-invest ">
                                            <div class="form-group form-2-group">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Risky</span></div>
                                            <div class="form-group form-2-group">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Moderate</span></div>
                                            <div class="form-group form-2-group">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Conservative</span></div>
                                        </div>
                                        <div class="bordr-bottm"></div>
                                        <p class=" pension-font em-left"><i class="fas fa-caret-right"></i>What is your Investment Experience</p>
                                        <div class="lft-invest ">
                                            <div class="form-group form-2-group">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Very Experienced</span></div>
                                            <div class="form-group form-2-group">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Average Experience </span></div>
                                            <div class="form-group form-2-group">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Little Experience</span></div>
                                        </div>
                                        <div class="bordr-bottm"></div>
                                        <p class=" pension-font em-left"><i class="fas fa-caret-right"></i>How are you Invested in TSP:</p>
                                        <ul class="tsp-ul equal-layout">
                                            <li >Contribution</li>
                                            <li >Allocation</li>
                                        </ul>
                                        <div class="lft-invest ">
                                             <div class="d-flex form-group form-2-group  layout-style">
                                                <div class="l-width">
                                                    <label class="checkbox-inline">
                                                        <input type="radio">
                                                    </label><span > C Fund</span></div>
                                                 <div class="input-inside">
                                                <input class="tsp-inn" type="=text" value="">
                                                <input class="tsp-inn" type="=text " value="">
                                                </div>
                                            </div>

                                      
                                            <div class="d-flex form-group form-2-group  layout-style">
                                                <div class="l-width">
                                                    <label class="checkbox-inline">
                                                        <input type="radio">
                                                    </label><span > S Fund</span></div>
                                                      <div class="input-inside">
                                                <input class="tsp-inn" type="=text" value="">
                                                <input class="tsp-inn" type="=text " value="">
                                            </div>
                                            </div>
                                            <div class="d-flex form-group form-2-group  layout-style">
                                                <div class="l-width">
                                                    <label class="checkbox-inline">
                                                        <input type="radio">
                                                    </label><span > I Fund</span></div>
                                                     <div class="input-inside">
                                                <input class="tsp-inn" type="=text" value="">
                                                <input class="tsp-inn" type="=text " value="">
                                            </div>
                                        </div>
                                           <div class="d-flex form-group form-2-group  layout-style">
                                                <div class="l-width">
                                                    <label class="checkbox-inline">
                                                        <input type="radio">
                                                    </label><span > F Fund</span></div>
                                                     <div class="input-inside">
                                                <input class="tsp-inn" type="=text" value="">
                                                <input class="tsp-inn" type="=text " value="">
                                            </div>
                                        </div>
                                          <div class="d-flex form-group form-2-group  layout-style">
                                                <div class="l-width">
                                                    <label class="checkbox-inline">
                                                        <input type="radio">
                                                    </label><span > G Fund</span></div>
                                                     <div class="input-inside">
                                                <input class="tsp-inn" type="=text" value="">
                                                <input class="tsp-inn" type="=text " value="">
                                            </div>
                                        </div>
                                            <div class="d-flex form-group form-2-group layout-style" *ngFor="let disaCount of loopFromNumber(fundControl);let i=index">
                                                <div class="l-width">
                                                    <label class="checkbox-inline">
                                                     <i  aria-hidden="true" class="fas fa-plus" (click)="fundProfile()"></i>
                                                        <input type="radio">
                                                    </label><span > L Fund</span></div>
                                                     <div class="input-inside">
                                                <input class="tsp-inn join-inpt" type="=text" value="">
                                                <input class="tsp-inn join-inpt" type="=text" value="">
                                                <input class="tsp-inn no-lft" type="=text " value="">
                                            </div>
                                        </div>
                                        </div>
                                       
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" width-46 gray-bg width-left client-info-width ">
                        <div class="family-info-inner">
                            <h5 >INVESTMENT OPTIONS (Client) <span >(Spouse)</span></h5>
                            <div class="top-form boxwrap">
                                <div class="form-2 ">
                                    <form class="p-0 form-horizontal ng-untouched ng-pristine ng-valid" novalidate="">
                                        <p class=" pension-font em-left"><i class="fas fa-caret-right"></i>What is your Risk Tolerance</p>
                                        <div class="lft-invest ">
                                            <div class="form-group form-2-group">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Risky</span></div>
                                            <div class="form-group form-2-group">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Moderate</span></div>
                                            <div class="form-group form-2-group">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Conservative</span></div>
                                        </div>
                                        <div class="bordr-bottm"></div>
                                        <p class=" pension-font em-left"><i class="fas fa-caret-right"></i>What is your Investment Experience</p>
                                        <div class="lft-invest ">
                                            <div class="form-group form-2-group">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Very Experienced</span></div>
                                            <div class="form-group form-2-group">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Average Experience </span></div>
                                            <div class="form-group form-2-group">
                                                <label class="checkbox-inline">
                                                    <input type="checkbox">
                                                </label><span >Little Experience</span></div>
                                        </div>
                                        <div class="bordr-bottm"></div>
                                        <p class=" pension-font em-left"><i class="fas fa-caret-right"></i>How are you Invested in TSP:</p>
                                        <ul class="tsp-ul equal-layout">
                                            <li >Contribution</li>
                                            <li >Allocation</li>
                                        </ul>
                                        <div class="lft-invest ">
                                             <div class="d-flex form-group form-2-group  layout-style">
                                                <div class="l-width">
                                                    <label class="checkbox-inline">
                                                        <input type="radio">
                                                    </label><span > C Fund</span></div>
                                                 <div class="input-inside">
                                                <input class="tsp-inn" type="=text" value="">
                                                <input class="tsp-inn" type="=text " value="">
                                                </div>
                                            </div>

                                      
                                            <div class="d-flex form-group form-2-group  layout-style">
                                                <div class="l-width">
                                                    <label class="checkbox-inline">
                                                        <input type="radio">
                                                    </label><span > S Fund</span></div>
                                                      <div class="input-inside">
                                                <input class="tsp-inn" type="=text" value="">
                                                <input class="tsp-inn" type="=text " value="">
                                            </div>
                                            </div>
                                            <div class="d-flex form-group form-2-group  layout-style">
                                                <div class="l-width">
                                                    <label class="checkbox-inline">
                                                        <input type="radio">
                                                    </label><span > I Fund</span></div>
                                                     <div class="input-inside">
                                                <input class="tsp-inn" type="=text" value="">
                                                <input class="tsp-inn" type="=text " value="">
                                            </div>
                                        </div>
                                           <div class="d-flex form-group form-2-group  layout-style">
                                                <div class="l-width">
                                                    <label class="checkbox-inline">
                                                        <input type="radio">
                                                    </label><span > F Fund</span></div>
                                                     <div class="input-inside">
                                                <input class="tsp-inn" type="=text" value="">
                                                <input class="tsp-inn" type="=text " value="">
                                            </div>
                                        </div>
                                          <div class="d-flex form-group form-2-group  layout-style">
                                                <div class="l-width">
                                                    <label class="checkbox-inline">
                                                        <input type="radio">
                                                    </label><span > G Fund</span></div>
                                                     <div class="input-inside">
                                                <input class="tsp-inn" type="=text" value="">
                                                <input class="tsp-inn" type="=text " value="">
                                            </div>
                                        </div>
                                            <div class="d-flex form-group form-2-group layout-style" *ngFor="let disaCount of loopFromNumber(fundControl);let i=index">
                                                <div class="l-width">
                                                    <label class="checkbox-inline">
                                                     <i  aria-hidden="true" class="fas fa-plus" (click)="fundProfile()"></i>
                                                        <input type="radio">
                                                    </label><span > L Fund</span></div>
                                                     <div class="input-inside">
                                                <input class="tsp-inn join-inpt" type="=text" value="">
                                                <input class="tsp-inn join-inpt" type="=text" value="">
                                                <input class="tsp-inn no-lft" type="=text " value="">
                                            </div>
                                        </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
    

 <div class="top-forms rowfirst ">
                    <div class="width-46 gray-bg width-left client-info-width mb-6">
                        <div class="family-info-inner">
                            <h5 class="btmmrgn">Pension & Social Security  <span >(Client)</span></h5>
                            <div class="top-form tppd boxwrap">
                                <div class="form-2 ">
                                        <ul class="tsp-ul equal-layout">
                                            <li >Age</li>
                                            <li >Amount</li>
                                        </ul>
                                        <div class="lft-invest-new ">
                                               <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Starting Social Security</label>
                                                </div>
                                                <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                                </div>
                                            </div>

                                           <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Starting Social Security</label>
                                                </div> <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div>
                                        </div>
                                            <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Stopping Social Security</label>
                                                </div>
                                                 <div class="input-inside d-flex input-inside2">
                                                <input class="tsp-inn-2" type="=text" value="">
                                            </div>
                                            </div>
                                         <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Restarting Social Security</label>
                                                </div>
                                                 <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div>
                                            </div>
                                        </div>
                                        <div class="lft-invest-new mb-6 ">
                                             <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Starting Pension # 1</label>
                                                </div>
                                                 <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div>
                                            </div>
                                           <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Ending Pension # 1</label>
                                                </div>
                                                 <div class="input-inside d-flex input-inside2">
                                                <input class="tsp-inn-2" type="=text" value="">
                                            </div>
                                            </div>
                                        <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Starting Pension # 2</label>
                                                </div>
                                                 <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div>
                                        </div>
                                         <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Ending Pension # 2</label>
                                                </div>
                                                 <div class="input-inside d-flex input-inside2">
                                                <input class="tsp-inn-2" type="=text" value="">
                                            </div>
                                            </div>
                                        </div>
                                        <ul class="tsp-ul equal-layout">
                                            <li >Age</li>
                                            <li >Monthly Amt</li>
                                        </ul>
                                        <div class="lft-invest-new  ">
                                    <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Starting Social Security </label>
                                                </div>
                                                 <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div>
                                            </div>
                                         <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Stopping Social Security</label>
                                                </div>
                                                 <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div></div>
                                      <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Restarting Social Security</label>
                                                </div>
                                                 <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div></div>
                                           <div class="d-flex form-group form-2-group allctn-input layout-style">
                                                <textarea ></textarea>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                
                    <div class="width-46 gray-bg width-right client-info-width mb-6">
                        <div class="family-info-inner">
                            <h5 class="btmmrgn">Pension & Social Security  <span >(Spouse)</span></h5>
                          <div class="top-form tppd boxwrap">
                                <div class="form-2 ">
                                        <ul class="tsp-ul equal-layout">
                                            <li >Age</li>
                                            <li >Amount</li>
                                        </ul>
                                        <div class="lft-invest-new ">
                                               <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Starting Social Security</label>
                                                </div>
                                                <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                                </div>
                                            </div>

                                           <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Starting Social Security</label>
                                                </div> <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div>
                                        </div>
                                            <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Stopping Social Security</label>
                                                </div>
                                                 <div class="input-inside d-flex input-inside2">
                                                <input class="tsp-inn-2" type="=text" value="">
                                            </div>
                                            </div>
                                         <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Restarting Social Security</label>
                                                </div>
                                                 <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div>
                                            </div>
                                        </div>
                                        <div class="lft-invest-new mb-6 ">
                                             <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Starting Pension # 1</label>
                                                </div>
                                                 <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div>
                                            </div>
                                           <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Ending Pension # 1</label>
                                                </div>
                                                 <div class="input-inside d-flex input-inside2">
                                                <input class="tsp-inn-2" type="=text" value="">
                                            </div>
                                            </div>
                                        <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Starting Pension # 2</label>
                                                </div>
                                                 <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div>
                                        </div>
                                         <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Ending Pension # 2</label>
                                                </div>
                                                 <div class="input-inside d-flex input-inside2">
                                                <input class="tsp-inn-2" type="=text" value="">
                                            </div>
                                            </div>
                                        </div>
                                        <ul class="tsp-ul equal-layout">
                                            <li >Age</li>
                                            <li >Monthly Amt</li>
                                        </ul>
                                        <div class="lft-invest-new  ">
                                    <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Starting Social Security </label>
                                                </div>
                                                 <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div>
                                            </div>
                                         <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Stopping Social Security</label>
                                                </div>
                                                 <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div></div>
                                      <div class="d-flex form-group form-2-group layout-style layout-2">
                                                <div class="m-width">
                                                    <label >Restarting Social Security</label>
                                                </div>
                                                 <div class="input-inside d-flex">
                                                <input class="tsp-inn-2" type="=text" value="">
                                                <input class="tsp-inn-2" type="=text " value="$">
                                            </div></div>
                                           <div class="d-flex form-group form-2-group allctn-input layout-style">
                                                <textarea ></textarea>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                </div> 
      </div>
  </div>



</div>