import { Component, OnInit, ViewChild, ElementRef , Output, EventEmitter,TemplateRef } from '@angular/core';
import * as myGlob from '../../globals';
import { IndexComponent } from '../index/index.component';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AmountService } from '../services/amount.service';
import { Retirement1Service } from '../services/retirement1.service';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { SavingService } from '../services/saving.service';

import { Amountdetail } from '../schemas/amountdetail';
import { AddClientComponent } from '../component/add-client/add-client.component';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-client-info',
    templateUrl: './client-info.component.html',
    styleUrls: ['./client-info.component.css']
})
export class ClientInfoComponent implements OnInit {
    showSpouseForm: boolean = false;
    @ViewChild('txtAcc') txtAcc : ElementRef;
    @ViewChild('txtEnt') txtEnt : ElementRef;
    modalRef: BsModalRef;
    showSearch: boolean =true;
    account: any;
    enterp: any;
    
    baseUrl= myGlob.baseUrl;
    profileControls=1;
    ChildDetails=1;
    fundControl=1;
    fundControl1=1;
    show: boolean = false;
    child: boolean = true;
    child1: boolean = true;
    concern: boolean = false;
    Capital: boolean = false;
    marked: boolean =false;
    data: any;
    message:string;
    
    theCheckbox = false;
    theCheckbox1 = false;
    theCheckbox2 = false;
    theCheckbox3 = false;
    theCheckbox4 = false;
    viewAdmin: any;
    ShowAddClt: boolean = true;
    ShowCltName: boolean = false;
    dataA: any;
    adminN:any;
    admin : any;
    loader:boolean= false;
    searchInput: any;
    Acc: any;
    constructor(private auth: AuthService,
        private _amunt: AmountService,
        private _ret: Retirement1Service,
        private fb: FormBuilder,
        private router: Router,
        private _Sav : SavingService,
        private modalService: BsModalService,
        private ngbService: NgbModal) { }
    name: any;
    parentPosts: any[]=[];
    fstName: any;
    lstName: any;
    birth: any;
    sex: any;
    surname: any;
    SpouseSr: any;
    SpouseNm: any;
    Spouselast: any;
    Spousebirth: any;
    SpouseSx: any;
    SpouseAcc1:any;
    SpouseEnt1: any;
    Spouse_In1: any;
    Spouse_In2: any;
    Spouse_Total1: any;
    searchResult1: Array<any> = [];
    dataa: any;
    idd: any;
    totalOcp: any;
    accountant: any;
    entrepreneur: any;
    items: string[]
    ngOnInit() {

        const user = this.auth.getUser();
        if(user._id){
            let res = user;
            this.showClientdata(res)
            
        }


        this._amunt.getAdminList().subscribe(res =>{
            let data: any = res;
            this.viewAdmin = data;
            
        })
    }


    showClientdata(user){
      this.adminN = user.Admin_Name;
            //this.fstName = user.Admin_Name;
            this.fstName = user.Admin_Name.split(' ')[0];
            this.lstName = user.Admin_Name.substring(this.fstName.length).trim();
            this.birth = user.Birthday;
            this.surname = user.Surname;
            this.sex = user.Sex;
            this.enterp=user.Entrepreneur;
            this.account = user.Account;
            this.totalOcp = user.Total;
            this.accountant = user.Accountant;
            this.entrepreneur = user.EntrepName;
            this.ShowCltName = true;
            this.SpouseSr = user.Spouse_Surname;
            this.SpouseNm = user.Spouse_First;
            this.Spouselast = user.Spouse_Last;
            this.Spousebirth = user.Spouse_Birth;
            this.SpouseSx = user.Spouse_Sex;
            this.Spouse_In1 = user.Spouse_Income1;
            this.Spouse_In2 = user.Spouse_Income2;
            this.Spouse_Total1  = user.Spouse_Total;
            this.SpouseAcc1 = user.SpouseAcc;
            this.SpouseEnt1 = user.SpouseEnt;
            let param = {
                'Admin_Name': user.Admin_Name,
                'Admin_Id': user._id
            }  
            this._Sav.getList(param).subscribe(res =>{
             let data: any = res;
             if(data > 0){
                this.SpouseNm = data[0].Spouse_Name;
            }
        });
            this._ret.getList(param).subscribe(res =>{
                let data: any = res;
                if(data > 0){
                    this.SpouseNm = data[0].Spouse_Name;
                }
            });
        }

        marrigeDetail(event){
            if(event == 'yes'){
                this.child = true;
            }else{
                this.child = false;
            }
        }
        marrigeDetail1(event){
            if(event == 'yes'){
                this.child1 = true;
            }else{
                this.child1 = false;
            }
        }

        funcAppendProfile(){
            this.profileControls++;
        }

        funcRemoveProfile(){
            if(this.profileControls>1)
                this.profileControls--;  
        }

        ChildCol(){
            this.ChildDetails++;

        }

        fundProfile(){
            this.fundControl++;
        }
        fundProfile1(){
            this.fundControl1++;
        }


        ChildExp(){
            if(this.ChildDetails>1)
                this.ChildDetails--;
        }

        loopFromNumber(number){
            var items: number[] = [];
            for(var i = 1; i <= number; i++){
                items.push(i);
            }
            return items;
        }


        showConcern(e){
            this.concern= e.target.checked;
        }

        showCaptial(e){
            this.Capital= e.target.checked;
        }
        toggleVisibility(e){
            this.marked= e.target.checked;
        }
        showDetails(event){
            if(event == 'showplan'){
                this.show = true;
            }else{
                this.show = false;
            }

        }

        private getDismissReason(reason: any): string {
            if (reason === ModalDismissReasons.ESC) {
              return "by pressing ESC";
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
              return "by clicking on a backdrop";
          } else {
              return `with: ${reason}`;
          }
      }

          openModal() {
           const ngmodalRef = this.ngbService.open(AddClientComponent, {
              size: 'sm',
              backdrop: 'static'
          });

           ngmodalRef.componentInstance.updateNewClient.subscribe((rdata) => {
              let data:any = rdata;
              this.dataA = rdata;
              this.admin = data._id;
              this.adminN  = data.Admin_Name;
              let adminId = this.admin;
              this.auth.addData(data);            
              this.update(data)
              this.ShowAddClt = false;
              this.ShowCltName = true;
          });
       }


           fetchName(event){

             let data = event.target.value;
             if(event.target.value == ""){
                this.showSearch = false;
            }else{
                this.searchResult1 = []
                
                this.showSearch = true;
                var query = {
                    "Admin_Name": event.target.value
                }
                
                this._amunt.getClientList().subscribe(res => {
                    let arr=[];
                    let data: any = res;
                    this.dataa = res;
                    for(let item of this.dataa){
                       arr.push({Admin_Name: item.Admin_Name, _id:item._id})
                   }
                   this.items = arr;
               });
               /* this._amunt.getClient(query).subscribe(res => {
                    let data: any = res;
                    this.dataa = res;
                    for(var i=0;i<this.dataa.length;i++){

                        this.searchResult1 = this.dataa;

                    }  
                })*/
            }
        }

        getClientList(_id){
            this.loader = true;

            this.idd = _id
            let parms = {
                '_id': this.idd
            }   
            this._amunt.getCltList(parms).subscribe(res =>{
              let data: any = res;
              this.update(data)
              this.searchInput = "";
              this.showSearch = false;
              this.loader = false;
          });
        }

    update(data){
      this.auth.addData(data);

      const user = this.auth.getUser();
      if(user._id){
        let res = user;
        this.adminN = user.Admin_Name;
        this.fstName = user.Firstname;
        this.lstName = user.Lastname;
        this.birth = user.Birthday;
        this.surname = user.Surname;
        this.sex = user.Sex;
        this.account = user.Account;
        this.enterp = user.Entrepreneur;
        this.totalOcp = user.Total;
        this.accountant = user.Accountant;
        this.entrepreneur = user.EntrepName;
        this.SpouseSr = user.Spouse_Surname;
        this.SpouseNm = user.Spouse_First;
        this.Spouselast = user.Spouse_Last;
        this.Spousebirth = user.Spouse_Birth;
        this.SpouseSx = user.Spouse_Sex;
        this.SpouseAcc1 = user.SpouseAcc;
        this.SpouseEnt1 = user.SpouseEnt;
        this.Spouse_Total1 = user.Spouse_Total;
        this.Spouse_In1 = user.Spouse_Income1;
        this.Spouse_In2 = user.Spouse_Income2;
        this.ShowCltName = true;
    }
    }

    logOut(){
      this.auth.removeData();
      this.router.navigate(['/']);
    }


/*----Save and update client info-----------*/

    saveInfo(){
           //this.auth.addData(data);
           const user = this.auth.getUser();
           if(user._id != undefined){
            let id = user._id
            let param = {
                Surname: this.surname,
                Admin_Name: this.fstName.toLowerCase()+ " " +this.lstName.toLowerCase(),
                Firstname : this.fstName.toLowerCase(),
                Lastname: this.lstName.toLowerCase(),
                Birthday: this.birth,
                Sex: this.sex,
                Phone: user.Phone,
                Email: user.Email,
                Account: this.account,
                Entrepreneur: this.enterp,
                Total: this.totalOcp,
                Accountant: this.accountant,
                EntrepName: this.entrepreneur,
                Spouse_Surname: this.SpouseSr,
                Spouse_First: this.SpouseNm,
                Spouse_Last: this.Spouselast,
                Spouse_Birth: this.Spousebirth,
                Spouse_Sex: this.SpouseSx,
                SpouseAcc: this.SpouseAcc1,
                SpouseEnt: this.SpouseEnt1,
                Spouse_Total: this.Spouse_Total1,
                Spouse_Income1: this.Spouse_In1,
                Spouse_Income2: this.Spouse_In2,

            }
            console.log(param)
            this.loader = true;
            this._amunt.updatClient(id,param).subscribe(res =>{
              let data: any = res;
              this.auth.addData(data);
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Client Info has been updated successfully.',
                showConfirmButton: false,
                timer: 1500
            })
              this.loader = false;
              setTimeout(() => {
                  window.location.reload();
              }, );
              
          });
        }else{
         Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Add Client Is Mandateory.',
            showConfirmButton: false,
            timer: 1500
        })
     }
    }


        Index(){
            const user = this.auth.getUser();
            if(user == false){
             this.router.navigate(['/index']);
         }else{
             let param = {
              "Admin_Name": user.Admin_Name,
              "Admin_Id": user._id
          }
          this._ret.getList(param).subscribe(res =>{
           console.log(res)
           let data: any =  res;
           if(data != 0){
            this.router.navigate(['/edit-client']);

        }else{
            this._Sav.getList(param).subscribe(res =>{
                console.log(res)
                let data: any =  res;
                if(data != 0){
                    this.router.navigate(['/edit-client']);
                }else{
                    
                    this.router.navigate(['/index']);
                }
            });

        }
        });
        }
        }

    Acc1(element){
       let RSAccount = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
       element.target.value = RSAccount; 
    }

    Ent1(element){
       let RSEntp = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
       element.target.value = RSEntp; 
    }

    Acc2(element){
       let RSAccount1 = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
       element.target.value = RSAccount1; 
    }

        Ent2(element){
           let RSEntp1 = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
           element.target.value = RSEntp1; 
        }


        SumofAll1(){
            let total :number = 0;
            let fetch1:any = '';
            fetch1 = this.Spouse_In1;
            if(fetch1!=undefined)
                total  += parseInt(fetch1.replace(/,/g, ''));
            let acc = total;

            let total2 :number=0;
            let fetch2:any = '';
            fetch2 = this.Spouse_In2;
            if(fetch2!=undefined)
                total2  += parseInt(fetch2.replace(/,/g, ''));
            let emp = total2;
            
            if(acc >=0 || emp >=0){
                console
                acc+=emp;
                
            }
            this.Spouse_Total1 = new Intl.NumberFormat().format(acc);
        }



        SumofAll2(){

           let total :number = 0;
           let fetch1:any = '';
           fetch1 = this.account;
           if(fetch1!=undefined)
            total  += parseInt(fetch1.replace(/,/g, ''));
        let acc = total;

        let total2 :number=0;
        let fetch2:any = '';
        fetch2 = this.enterp;
        if(fetch2!=undefined)
            total2  += parseInt(fetch2.replace(/,/g, ''));
        let emp = total2;

        if(acc >=0 || emp >=0){
            console
            acc+=emp;
            
        }

        this.totalOcp = new Intl.NumberFormat().format(acc);

        }

}