import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TaxDefferd1 } from '../schemas/tax-defferd1';
import { TaxFree } from '../schemas/tax-free';
import { TaxDeductible } from '../schemas/tax-deductible';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class Retirement1Service {
         //url = 'http://localhost:4000';
         //url = 'http://cjcwealth360.com';
         url = environment.APP_URL;
  constructor(private http:HttpClient) { }

    addAmount(param: any):Observable<any> {
		    //return this.http.post<TaxDefferd1>(this.url+'/deffered1/add',param)
		      return this.http.post<TaxDefferd1>(this.url+'/deffered1/add',param)
	}

	getamount(){
		    //return this.http.get(this.url+'/deffered1/')
		      return this.http.get(this.url+'/deffered1/')
	}

	getCurrentA(parms){		
		  //return this.http.post(this.url+'/deffered1/findOne/',parms)
		    return this.http.post(this.url+'/deffered1/findOne/',parms)
	}

	getData(parms){		
		      //return this.http.post(this.url+'/deffered1/findAll/',parms)
		         return this.http.post(this.url+'/deffered1/findAll/',parms)
	}

	getList(parms){		
		     //return this.http.post(this.url+'/deffered1/findlist/',parms)
		       return this.http.post(this.url+'/deffered1/findlist/',parms)
	}

	updateA(param: any,name: any){
		     //return this.http.post<TaxDefferd1>(this.url+'/deffered1/update/',param,name)
		       return this.http.post<TaxDefferd1>(this.url+'/deffered1/update',param,name)
	}

	deleteD(parms){	
		   //return this.http.post(this.url+'/deffered1/del/',parms)
		     return this.http.post(this.url+'/deffered1/del/',parms)
	}



	addTax1(parmTax: any) {
          //return this.http.post<TaxFree>(this.url+'/tax1/add',parmTax)
            return this.http.post<TaxFree>(this.url+'/tax1/add',parmTax)
    }

    getTax1(){
           //return this.http.get(this.url+'/tax1/')
             return this.http.get(this.url+'/tax1/')
    }

    getCurrentTax(parmT){
           //return this.http.post(this.url+'/tax1/findOne/',parmT)
             return this.http.post(this.url+'/tax1/findOne/',parmT)
    }
    
    getTaxData(parms){		
		   //return this.http.post(this.url+'/tax1/findAll/',parms)
		      return this.http.post(this.url+'/tax1/findAll/',parms)
	}

	getTaxList(parms){
		    //return this.http.post(this.url+'/tax1/findTaxlist/',parms)
		      return this.http.post(this.url+'/tax1/findTaxlist/',parms)
	}

    updateTax(parmTax: any,name: any){
           //return this.http.post<TaxFree>(this.url+'/tax1/update',parmTax,name)
             return this.http.post<TaxFree>(this.url+'/tax1/update',parmTax,name)
    }

	  addDed1(param: any) {
		  //return this.http.post<TaxDeductible>(this.url+'/ded1/add',param)
		     return this.http.post<TaxDeductible>(this.url+'/ded1/add',param)
	}

	getDed1(){
		  //return this.http.get(this.url+'/ded1/')
		    return this.http.get(this.url+'/ded1/')
	}

    getCurrentDed(prmTax){
        //return this.http.post(this.url+'/ded1/findOne/',prmTax)
          return this.http.post(this.url+'/ded1/findOne/',prmTax)
    }

     getDedData(parms){
		//return this.http.post(this.url+'/ded1/findAll/',parms)
		  return this.http.post(this.url+'/ded1/findAll/',parms)
	}

	getListDed(parms){
		//return this.http.post(this.url+'/ded1/findlistD/',parms)
		  return this.http.post(this.url+'/ded1/findlistD/',parms)
	}

    updateDed(prmTax: any,name: any){
        //return this.http.post<TaxDeductible>(this.url+'/ded1/update',prmTax,name)
            return this.http.post<TaxDeductible>(this.url+'/ded1/update',prmTax,name)
    }



}
