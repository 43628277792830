<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="">
    <meta name="author" content="">
    <title>Dashboard</title>

</head>
<body>
             <div class="loading" *ngIf ="loader">Loading&#8230;</div> 
    <div class="pagerow">
        <div class="laft-bar bg-light float-left">

            <div class="site-logo">
                <a href="#">
                   <img src="../assets/images/logo.png" class="side-logo" alt="Logo">
                </a>
            </div>
            <div class="admin-panel">
                <ul>
                     <li> <a  (click)="Index()"><i class="fas fa-home"></i> Model</a></li>
                  <li ><a [routerLink]="['/client-info']" routerLinkActive="active"><i aria-hidden="true" class="fa fa-info-circle"></i> Client Info</a></li>
                   <li><a [routerLink]="['/protection']" routerLinkActive="active"><img src="../assets/images/protection.png" class="pro"> Protection</a></li>

                </ul>
            </div>
        </div>
        <div class="container-fluid content-area">
            <div class="cover-header">
                <div class="top-row">
                    <div class="search-wrap">

                          <input type="text" #searchbar class="option_input" style="text-transform: capitalize;" [(ngModel)]="searchInput" id="myInput" (keyup)="fetchName($event)" placeholder="Search.." />
                        <button type="submit" name="submit" class="info-menu"><i class="fas fa-search"></i></button>
                          <div *ngIf="showSearch" class="search-list">
                           <li  *ngFor="let item of items|filter:searchInput" (click)="getClientList(item._id,item.Admin_Name)"> {{item.Admin_Name | titlecase}}</li>
                       </div>
                    </div>

                           
            
                    <div class="info-menu">
                    <ul >
                      <!--   <li ><a href="#"><i class="far fa-bell"></i></a></li>
                        <li ><a href="#"><i class="far fa-question-circle"></i></a></li> -->
                        <li>
                          <h4><i class="fa fa-user"></i>Login as {{adminN | titlecase}}</h4>
                        </li>
                        <li >
                            <!-- <button >SIGN UP</button> -->
                            <button   (click)="openModal()">Add Client</button>         
                        </li>
                        <li>
                        <button class="loginbtn" (click)="logOut()">Log Out</button>
                          </li>
                    </ul>
                </div>
                </div>
               
            </div>

            <div class="dashboard">
                <div class="left-content">
                     <div class="bredcrumb">
                    <ul>
                        <li><a href="#"><i class="fas fa-home"></i> Home</a></li> -
                        <li>Dashboard</li>
                    </ul>
                </div>
                   <!-- <ul let i ="index">
                      <li><button class="shifticon" (click)="saveInfo(i)" >Save</button></li>
                  </ul> -->
                    <!-- First Section Starts Here -->
                    <div class="top-forms rowfirst ">
                     
                                </div>
                            <div class="scrolled_div scrollbar" id="style-3">
                            <div class="fixed_top">
                                  <!-- <div class="saprator-blue"><span  class="sapbox img-size"><img  src="../assets/images/user.png"></span></div> -->
                            
                                 <div class="d-flex">
                                    <div class="arrow_list">
                                    <div class="arrow_listinside">
                                        <div class="d-flex">
                                            <div class="box-repeat" *ngFor="let prfCount of loopFromNumber(intervalControls); let i =index">
                                        <div class="title-box title-box-one"  (click)="getyear($event,i);dataSavingPlus(i);SumofAll();SumofAcc();SumofAcc1();SumofAll2();Sumofclick();PensionData(i);Sumofclick2()" style="cursor: pointer">
                                            <div class="action_box" >
                                                <ul>
                                                   <li><i aria-hidden="true" class="fas fa-plus" (click)="InterValCol()" style="cursor: pointer"></i></li>
                                                    <li (click)="NameIntCol(i)" style="cursor: pointer">C</li>
                                                    <li><i aria-hidden="true" class="fas fa-minus" (click)="InterValRem()" style="cursor: pointer"></i></li>
                                                    <li (click)="NameIntExp(i)" style="cursor: pointer">E</li>
                                                </ul>
                                            </div>
                                            <div class="box_info">
                                                <span>
                                                     <input  onkeyup="this.style.width = ((this.value.length + 1) * 8) + 'px';" name="intName{{i}}" [style.width.px]="width" type="text" placeholder="Name {{i}}" class="option_input"  [(ngModel)]="NameControl[i]"(click)="getyear($event,i);dataSavingPlus(i);SumofAll();SumofAcc();SumofAcc1();SumofAll2();Sumofclick();PensionData(i);Sumofclick2()" (blur)="ShowName(i);showIntValue();"   required>
                                                     
                                                </span>                                              
                                                
                                            </div>
                                        </div>
                                        <div *ngIf= "showYear">
                                          <div class="title-box title-box22" >
                                         
                                            <div class="box_info box_info2 box_info_data">
                                                <span class="full-row border-btm">
                                                     <input type="text" [(ngModel)]="clientt[0]"  placeholder="Client">
                                              
                                                  </span>
                                                 <span class="full-row border-btm">START AGE<b><input type="text" [(ngModel)]="start[i]" placeholder="0" name="strt{{i}}" (blur)="getyear($event,i)" maxlength="3" class= "clnt" required></b></span>

                                                 <span>STOP AGE<b><input type="text" placeholder="0"  name="stp{{i}}" [(ngModel)]="stop[i] || start[i+1]"   (blur)="getyear($event,i)" maxlength="3"  class= "clnt"></b></span>
                                                                            
                                            </div>
                                        </div>
                                          <div class="title-box title-box22" *ngIf="spList">
                                          <div class="box_info box_info2 box_info_data">
                                             

                                               <span class="full-row border-btm">
                                                 <input type="text" [(ngModel)]="spousee[0]"placeholder="Spouse">
                                               <!--  <input type="text" [(ngModel)]="spouse" [ngModelOptions]="{standalone: true}" placeholder="Spouse"> -->
                                               </span>

                                                <span class="full-row border-btm">START AGE <b><input size="1" type="text" name="" placeholder="0"  maxlength="3" [(ngModel)]="start1[i]" name="strt1{{i}}" (blur)="getyear($event,i)" maxlength="3" class= "clnt"></b></span> 
                                               
                                                <span>STOP AGE <b><input size="1" type="text" name="" name="stp1{{i}}" [(ngModel)]="stop1[i] || start1[i+1]" placeholder="0"  (blur)="getyear($event,i);dataSaving();retireLeft();" maxlength="3" class= "clnt"></b></span> 
                                                           
                                            </div>                                         
                                        </div>
                                        
                                         <div class="title-box" >                                         
                                            <div class="box_info box_info2">
                                                 <h2>YEARS {{showdiff[i]}}</h2>               </div>
                                        </div>
                                        </div>
                                        <div class="bottom_sign"> 
                                       <button name="iconNo{{i}}" (click)="getyear($event,i);dataSavingPlus(i);ShowName(i);SumofAll();SumofAcc();SumofAcc1();SumofAll2();Sumofclick();Sumofclick2();PensionData(i);" class="fas fa-plus"
                                                ></button>                          </div>
<br>
                                                <div class="bottom_sign"> 
                                        <button input type="hidden" placeholder=""  name="s{{i}}"  (click)="AddAmount(i);" class="fas fa-save" 
                                                ></button>                         
                                                 </div>

                                        </div>
                                        </div>

                                         
                                 <div class="showname-center" *ngIf="showNamee">
                                    <b class="set_row">
                                  <h2 *ngIf="showInt">{{showName | uppercase}} (
                                  {{first}} to {{last}} ) </h2>
                              </b>
                                  </div>                 
                                    </div>
                                    </div>                        
</div> </div>
                             

                               <!--  <div class="saprator"></div> -->

                                <!-- First Section Ends Here --->

                                <!-- Second Section Starts Here --->

                                <div class="top-forms rowfirst" id="step110">
                                    <div class="icon_row coll-exp">
                                      <ul>
                                         <li style="cursor: pointer;" class="test" [class.active]="stepp" href="#step110" (click)="collapseAdditional()">C</li>

                                          <li (click)="expandAdditional()" style="cursor: pointer;">E</li>
                                      </ul>
                                  </div>
                                    <div class="family-info retirement">
                                        <div class="family-info-inner">
                                            <div class="box-icon bluebg">
                                                <i class="fas fa-plus"></i>
                                            </div>
                                            <h5>ADDITIONAL INFORMATION</h5>

                                            <div class="top-form" *ngIf="Additionalform">
                                                <!-- pension form begins here -->
                                                <form>

                                                    <ul class="form-row d-flex">
                                                        <li class="form-group w-50">
                                                            <input type="text" placeholder="Line 100" required>
                                                        </li>
                                                        <li class="form-group w-50 no-outline">
                                                            <span class="SizeDollar">$</span>
                                                            <input class="SizeAmount text-right" type="text" placeholder="30,000" required>
                                                        </li>
                                                    </ul>
<!-- </div>
</div> -->
</form>
<p class="sub-title text-right text-right2">Risk Score</p>
<form class="dirction-left">

    <ul class="form-row d-flex">
        <li class="form-group w-50">
            <input type="text" placeholder="ANDREW" required>
        </li>
        <li class="form-group w-50">
            <input type="text" placeholder="">
        </li>
    </ul>
    <ul class="form-row d-flex">
        <li class="form-group w-50">
            <input type="text" placeholder="DEBBIE" required>
        </li>
        <li class="form-group w-50">
            <input type="text" placeholder="">
        </li>
    </ul>

</form>
<!-- total of above table ends here -->
</div>
</div>
</div>
<div class="middle-section middle-section1" *ngIf="Additionalform">
    <!-- ------------Saving Middle------------- -->

           <div class=""  *ngIf = "ShowMidSaving">
                <ul class="total_amount Turn form-row d-flex new-amm ">
                    <li><span *ngIf="this.ShowMiddleSav && !showDivSaving">${{ShowMiddleSav}}</span></li>
                    <li class="rightSign"> <i class="fa fa-arrow-right" aria-hidden="true"></i></li>
                </ul>
            </div>

            <div class="action-row new-action-add" *ngIf="showDivSaving">
                <ul *ngFor="let RVal of loopFromNumber(savingControls);let i=index" class="line-c form-row d-flex Turn">                  
                <li class="middleStyle">
                     <span class="middlee">$</span>
                     <input  onkeyup="this.style.width = ((this.value.length + 1) * 8) + 'px';" size="2" (keyup)="RSaving($event)" (blur)="SavingAmnut(i)"  [(ngModel)]="SavAmount[i]" type="text" name="svng{{i}}" [style.width.px]="width" id="norml2{{i}}" value="" class="custmwdth" *ngIf="mSav1 || showMiddleArrowSav == i" placeholder="0">
                    <input type="{{i}}" [(ngModel)]="SavAmo[i]" *ngIf="!mSav1" [hidden]="showMiddleArrowSav==i?true:false " (click)="tstSav($event,i)" id="aftrclick2{{i}}"  value="" class="custmwdth 1" placeholder="0" readonly>
                 </li>

                 <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
             </ul>
         </div>


    <!-- ------------Collage Middle----------- -->

       <div class="dirction-row"  *ngIf = "ShowMidCollage">
                <ul class="total_amount form-row d-flex Turn new-amm">
                    <li><span *ngIf="this.ShowMiddleCol && !showDivCollage">${{ShowMiddleCol}}</span></li>
                    <li class="rightSign"> <i class="fa fa-arrow-right" aria-hidden="true"></i></li>
                </ul>
            </div>

            <div class="amm-set action-row" *ngIf="showDivCollage">
                <ul *ngFor="let RVal of loopFromNumber(savingControlscollage);let i=index" class="line-c form-row d-flex Turn">                  
                    <li class="middleStyle">
                     <span class="middlee">$</span>
                     <input size="2" onkeyup="this.style.width = ((this.value.length + 1) * 8) + 'px';" (keyup)="RCollage($event)" (blur)="CollageAmnut(i)" [(ngModel)]="ColAmount[i]" [style.width.px]="width" type="text" name="RSAgAmntc{{i}}" value="" class="custmwdth" placeholder="0" *ngIf="mCol || showMiddleArrowCol == i">
                     <input type="{{i}}" [(ngModel)]="ColAmount1[i]" *ngIf="!mCol" [hidden]="showMiddleArrowCol==i?true:false " (click)="tstCol($event,i)" id="aftrclick2{{i}}"  value="" class="custmwdth 1" placeholder="0" readonly>
                 </li>

                 <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
             </ul>
         </div>
</div>
<div class="family-info retirement">
    <div class="family-info-inner">
        <div class="box-icon bluebg">
            <i class="fas fa-dollar-sign"></i>
        </div>
        <h5>saving </h5>

        <div class="top-form" *ngIf="Additionalform">
            <!-- pension form begins here -->
            <div class="personForm">
                <ol>
                    <li>
                        <div class="icon_row">
                            <ul>
                                <li><i (click)="savingAddRow()" aria-hidden="true" class="fas fa-plus"></i></li>
                                <li (click)="collapseSaving()" style="cursor: pointer;">C</li>
</ul>
</div>
</li>
<li>Saving</li>
<li class="equal-width">
    <div class="icon_row">
        <ul>
            <li (click)="expandSaving()" style="cursor: pointer;">E</li>
            <li><i (click)="savingRemRow()" aria-hidden="true" class="fas fa-minus"></i></li>
        </ul>
    </div>
</li>
</ol>
</div>
<div  *ngIf = "ShowtotalSaving">
    <ul>
        <li>Total</li>
        <!-- <li class="text-right">$263,000</li> -->
        <li class="text-right amountRight fontSizetotal"><span *ngIf="this.RetireSaving && !showDivSaving">${{RetireSaving}}</span></li>
    </ul>
</div>
<form>
    <div>
        <div *ngIf="showDivSaving">
            <ul *ngFor="let disaCount of loopFromNumber(savingControls);let i=index" class="form-row d-flex">
                <li class="form-group w-50">
                    <input name="savingName{{i}}" type="text" placeholder="Saving" [(ngModel)]="SavingControlsDeff[i]"  required>
                </li>
                <li class="form-group w-50 no-outline" *ngIf="!amuntSav">
                    <span class="SizeDollar">$</span>
                    <input name="savingAmt{{i}}" (keyup)="RSaving($event)" (blur)="SavingAmnut(i)"  [(ngModel)]="SavingControlsAmt[i]" type="text" placeholder="0" class ="SizeAmount" required>
                </li>
                 <li class="form-group w-50 no-outline" *ngIf="amuntSav">
                    <span class="SizeDollar">$</span>
                    <input name="Amt{{i}}" (blur)="SavingAmnut(i)" (click)="Sav($event,i)" (keyup)="RSaving($event)" [(ngModel)]="SavingControlsA[i]" type="text" placeholder="0" class ="SizeAmount" required>
                </li>
            </ul>
        </div>
    </div>
</form>
<div class="personForm">
    <ol>
        <li>
            <div class="icon_row">
                <ul>
                    <li><i (click)="savingCollageAddRow()" aria-hidden="true" class="fas fa-plus"></i></li>
                    <li style="cursor: pointer;" (click)="collapseCollage()">C</li>
</ul>
</div>
</li>
<li>Collage</li>
<li class="equal-width">
    <div class="icon_row">
        <ul>
            <li (click)="expandCollage()" style="cursor: pointer;">E</li>
            <li><i (click)="savingCollageRemRow()" class="fas fa-minus" aria-hidden="true"></i></li>
        </ul>
    </div>
</li>
</ol>
</div>
<div  *ngIf = "ShowtotalCollage">
    <ul>
        <li>Total</li>
        <li class="text-right amountRight fontSizetotal"><span *ngIf="this.CollageTotal && !showDivCollage">${{CollageTotal}}</span></li>
    </ul>
</div>
<form>
    <div>
        <div *ngIf="showDivCollage">
            <ul *ngFor="let disaCount of loopFromNumber(savingControlscollage);let i=index" class="form-row d-flex">
                <li class="form-group w-50">
                   <input name="savingCollageName{{i}}" type="text" placeholder="Saving" [(ngModel)]="CollageControlsDeff[i]"  required>
               </li>
               <li class="form-group w-50 no-outline">
                <span class="SizeDollar">$</span>
                <input name="savingCollageAmt{{i}}" (keyup)="RCollage($event)" (blur)="CollageAmnut(i)" [(ngModel)]="CollageControlsAmt[i]" type="text" placeholder="0" class ="SizeAmount"
                [hidden]="!amuntCol" required >
            
                <input name="savingCollageAmt{{i}}" (blur)="SavingAmnut(i)" (click)="SavCol($event,i)" (keyup)="RCollage($event)" [(ngModel)]="CollageControlsA[i]" type="text" placeholder="0" class ="SizeAmount" required [hidden]="amuntCol">
                </li>
        </ul>
    </div>
</div>
</form>
</div>
</div>
</div>
<div class="sidebar" *ngIf="Additionalform">
    <!--------------Saving Sidebar----- --------->
   <div  *ngIf = "ShowSideSav">
    <ul class="Turn">
        <li class = "fontSizetotal"> <i class="fa fa-arrow-right" aria-hidden="true"></i><span *ngIf="this.showSide1 && !showDivSaving1">${{showSide1}}</span></li>
    </ul>
</div>
<div class="indicated-data " *ngIf = "showDivSaving1">
    <ul *ngFor="let RVal of loopFromNumber(savingControls);let i=index" class="form-row d-flex Turn1">
        <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
        <li>

           <input (blur)="SavingAmnut(i)"  type="text" name="RSAgrowthPercntSS{{i}}" [(ngModel)]="SavingSidbar[i]" placeholder="0" onfocus="this.select();" onmouseup="return false;" maxlength="3" >
           <span class="SizeDollar1">%</span>
       </li>
       <li>
           <span class="difSize">$</span>
           <div class="show">{{TotSav[i]}} </div>
          
       </li>
   </ul>
</div>
    <!--------------Collage Sidebar-------------- -->
    <div  *ngIf = "ShowSideCol">
    <ul class="Turn">

        <li class = "fontSizetotal"><i class="fa fa-arrow-right" aria-hidden="true"></i><span *ngIf="this.showSide2 && !showDivCollage1">${{showSide2}}</span> </li>
    </ul>
</div>
<div class="indicated-data  amm-set p-set-2" *ngIf = "showDivCollage1">
    <ul *ngFor="let RVal of loopFromNumber(savingControlscollage);let i=index" class="form-row d-flex Turn1">
        <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
        <li>

           <input (blur)="CollageAmnut(i)"  type="text" name="RSAgrowthPercntCC{{i}}" [(ngModel)]="CollageSidebar[i]" placeholder="0" onfocus="this.select();" onmouseup="return false;" maxlength="3" >
           <span class="SizeDollar1">%</span>
       </li>
       <li>
           <span class="difSize">$</span>
           <div class="show">{{TotCol[i]}} </div>
          
       </li>
   </ul>
</div>
</div>
</div>

<div class="saprator">
    <span class="sapbox">
        <i class="fas fa-male"></i>
        <span class="counter">1</span>
    </span>
</div>

<!-- Second Section Ends Here --->

<!-- Third Section Starts Here --->

<div class="top-forms custom-row" id="step1">
      <div class="icon_row coll-exp">
        <ul>
           <li style="cursor: pointer;" [class.active]="stepp1" href="#step1" (click)="collapseClientRet()">C</li>

            <li (click)="expandClientRet()" style="cursor: pointer;">E</li>
        </ul>
</div>
    <div class="family-info retirement ">
        <div class="family-info-inner">
            <div class="top-form">
                <!-- Tax Deferred form begins here -->
                <div class="inner-box">
                        <h5>({{ClName}}) retirement income projection</h5>
                    <div class="personForm" *ngIf="ClientRetirement1">
                        <!-- <h6 *ngIf= "showClient">{{adminN}}</h6> -->
                        <ol class="li-4 d-flex heading_row">
                            <li>W
                            </li>
                            <li>C</li>
                            <li>Tax Deferred</li>
                            <li>Amount</li>

                        </ol>
                        <div  *ngIf = "ShowtotalRetired">
                            <ul>
                                <li>Total</li>
                                <li  class="amountRight fontSizetotal"><span *ngIf="this.showAmountRetired && !showRetired">${{showAmountRetired}}</span></li>
                            </ul>
                        </div>

                        <div>

                            <div>
                                <div *ngIf="showRetired" >
                                    <ul class="inputrow form-row li-4 d-flex" *ngFor="let RVal of loopFromNumber(RetirmentControls);let i=index">
                                        <li class="form-group d-flexstyle">
                                            <input type="text" placeholder="0" name="rateRetd{{i}}" maxlength="3" [(ngModel)]="rateRet[i]" (change)="funcCalPrjAmt($event,i)" (blur)="SumofAll()" onfocus="this.select();" onmouseup="return false;">
                                            <span class="SizePercent">%</span>

                                        </li>
                                        <li class="form-group">
                                            <input type="text" placeholder="cola" required>
                                        </li>
                                        <li class="form-group">
                                            <input type="text" placeholder="TAX DEFERRED {{i}}" [(ngModel)]="RetirmentControlsDeff[i]" name="txtDeff{{i}}" required >
                                        </li>
                                        <li class="form-group no-outline">
                                            <span class="SizeDollar">$</span>
                                            <input type="text" class="SizeAmount text-right "  [(ngModel)]="RetirmentControlsDeffTax[i]"  name="txtDeffTaxRate{{i}}" placeholder="0" readonly required >
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- Tax Deferred form ends here -->

                    <!-- Tax Free form begins here -->

                    <div class="personForm" *ngIf="ClientRetirement1">
                        <ol>
                            <li></li>
                            <li>Tax Free</li>
                            <li></li>
                        </ol>
                       <div  *ngIf = "ShowtotalRetTax">
                            <ul>
                                <li>Total</li>
                                <li  class="amountRight fontSizetotal"><span *ngIf="this.showAmountTax && !showRsaTaxFree">${{showAmountTax}}</span></li>
                            </ul>
                        </div>
                        <form>
                            <div>
                                <div *ngIf="showRsaTaxFree">
                                    <ul *ngFor="let prfCount of loopFromNumber(RSATaxfreeControls);let i=index" class="inputrow form-row  d-flex">
                                        <li class="form-group w-33">
                                           <input type="text" placeholder="WD {{i}}" [(ngModel)]="RetirmentTaxFreeDeff[i]" name="RSATaxfreeWD{{i}}" required >
                                        </li>

                                        <li class="form-group w-33 d-flexstyle">
                                             <input type="text" placeholder="0" name="rateRetf{{i}}" maxlength="3" [(ngModel)]="rateTaxF[i]" (change)="funcCalPrjAmt1($event,i)" (blur)="SumofAll()" onfocus="this.select();" onmouseup="return false;">
                                              <span class="SizePercent">%</span>
                                        </li>

                                        <li class="form-group w-33 no-outline">                
                                              <span class="SizeDollar">$</span>
                                            <input type="text" class="SizeAmount text-right SizeAmount"  [(ngModel)]="RetirmentControlsTaxFree[i]"  name="txtTaxRate{{i}}" placeholder="0" readonly required >
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Tax Free form ends here -->
                    <div class="personForm" *ngIf="ClientRetirement1">
                        <ol>
                            <li></li>
                            <li>Tax Deductible</li>
                            <li></li>
                        </ol>
                       <div  *ngIf = "ShowtotalRetDed">
                            <ul>
                                <li>Total</li>
                                <li  class="amountRight fontSizetotal"><span *ngIf="this.showAmountDed && !showRsaDedFree">${{showAmountDed}}</span></li>
                            </ul>
                        </div>
                        <form>
                            <div>
                                <div *ngIf="showRsaDedFree">
                                    <ul *ngFor="let prfCount of loopFromNumber(RSATaxDeductionControls);let i=index"class="inputrow form-row d-flex">
                                        <li class="form-group w-33 ">
                                            <input type="text" placeholder="WD {{i}}" [(ngModel)]="RetirmentDeductDeff[i]" name="RSATaxdedectWD{{i}}" required>
                                        </li>
                                        <li class="form-group w-33 d-flexstyle">
                                           <input type="text" placeholder="0" name="rateRetded{{i}}" maxlength="3" [(ngModel)]="rateDed[i]" (change)="funcCalPrjAmt2($event,i)" (blur)="SumofAll()" onfocus="this.select();" onmouseup="return false;">
                                            <span class="SizePercent">%</span>
                                        </li>

                                        <li class="form-group w-33 no-outline">
                                            <span class="SizeDollar">$</span>
                                            <input type="text" class="SizeAmount text-right SizeAmount" [(ngModel)]="RetirmentControlsDed[i]"  name="txtded{{i}}" placeholder="0" readonly required >
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Tax Deferred form ends here -->

                    <!-- social security form begins here -->

                    <div class="personForm" *ngIf="ClientRetirement1">
                        <ol>
                            <li>
                                <i class="fas fa-plus" (click)="RSAPensionAdd()"></i></li>
                                <li>Pension & Social Security</li>
                                <li><i class="fas fa-minus" (click)="RSAPensionRem();SumofAll();"></i></li>
                            </ol>
                            <form>
                                <div>
                                    <div *ngIf="showRsapensionFree">
                                        <ul *ngFor="let prfCount of loopFromNumber(RSAPensionControls);let i=index" class="form-row d-flex">
                                            <li class="form-group w-33 ">
                                                <input type="text" placeholder="WD" required>
                                            </li>
                                            <li class="form-group w-33 ">
                                                <input type="text" [(ngModel)]="NamePension[i]" placeholder="Name {{i}}" [ngModelOptions]="{standalone: true}" required>
                                            </li>

                                            <li class="form-group w-33 no-outline">
                                                <span class="SizeDollar">$</span>
                                                <input name="Pension{{i}}" type="text" [(ngModel)]="RetirmentPension1[i]" class="text-right SizeAmount" (keyup)="RSAPension($event)" (blur)="SumofAll()" id="istclk{{i}}" placeholder="0" required>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- social security form ends here -->

                        <!-- total of above table comes here -->


                        <div class="total">
                            <!--h4>Total</h4-->
                            <ul>
                                <li>Total</li>
                                <li class="text-right">${{TotalAll1}}</li>
                            </ul>
                        </div>
                    </div>
                    <!-- total of above table ends here -->
                </div>
            </div>
        </div>
<!------------Tax Deffered Middle1--------- -->
          <div class="middle-section middle-section1" *ngIf="ClientRetirement1">

            <div class="dirction-row"  *ngIf = "ShowMiddletotal">
                <ul class="total_amount form-row d-flex new-amm">
                    <li><span *ngIf="this.ShowMiddle && !showRetire1">${{ShowMiddle}}</span></li>
                    <li class="rightSign"> <i class="fa fa-arrow-right" aria-hidden="true"></i></li>
                </ul>
            </div>

            <div class="action-row full-width-row" *ngIf="showRetire1">
                <ul *ngFor="let RVal of loopFromNumber(RetirmentControls);let i=index" class="line-c form-row d-flex">                  
                    <li class="middleStyle">
                     <span class="middlee">$</span>
                     <input size="2" onkeyup="this.style.width = ((this.value.length + 1) * 8) + 'px';" (keyup)="RSAAmount($event)"  (blur)="RSAAmountCalComplete(i)" *ngIf="mDef1 || showMiddleArrow == i"  [(ngModel)]="RSAgrouwthAmount[i]" type="text" name="RSAgAmnt{{i}}" id="norml1{{i}}" placeholder="0" [style.width.px]="width" value="" class="custmwdth">
                     <input type="{{i}}" [(ngModel)]="RSAgrouwthAmo[i]" *ngIf="!mDef1" [hidden]="showMiddleArrow==i?true:false " (click)="tsting($event,i)" id="aftrclick1{{i}}"  value="" class="custmwdth 1" readonly>
                 </li>
                 
                 <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
             </ul>
         </div>
         <!-----------------------Tax Free Middle1------------------- -->
        <div class="dirction-row"  *ngIf = "ShowMiddletotalTax">
    <ul class="total_amount form-row d-flex new-amm">
        <li class="" ><span *ngIf="this.ShowMiddleTax1 && !showRsaTaxFree">${{ShowMiddleTax1}}</span> <i class="fa fa-arrow-right" aria-hidden="true"></i></li>
    </ul>
</div>
 
            <div class="action-row full-width-row" *ngIf="showRsaTaxFree">
                <ul *ngFor="let RVal of loopFromNumber(RSATaxfreeControls);let i=index" class="line-c form-row d-flex">                  
                    <li class="middleStyle">
                       <span class="middlee">$</span>
                        <input size="2" onkeyup="this.style.width = ((this.value.length + 1) * 8) + 'px';" (keyup)="RSAAmountTax1($event)" (blur)="RSAAmountCalTax(i)" *ngIf="mTax || showMiddleArrowT == i" placeholder="0" [(ngModel)]="RSAgrouwthTax[i]" type="text" name="RSAgAmnt{{i}}" id="norml2{{i}}" [style.width.px]="width" value="" class="custmwdth">
                         <input type="{{i}}" [(ngModel)]="RSAgrouwthT[i]" *ngIf="!mTax" [hidden]="showMiddleArrowT==i?true:false " (click)="taxMiddle($event,i)" id="aftrclick2{{i}}"  value="" class="custmwdth 1" readonly>
                    </li>
                   
                    <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
                </ul>
            </div>
<!----------------------Tax Deductible Middle1---------- -->

        <div class="dirction-row" *ngIf = "ShowMiddletotalDed">
    <ul class="total_amount form-row d-flex new-amm">
        <li class="" ><span *ngIf="this.ShowMiddleDed1 && !showRsaDedFree">${{ShowMiddleDed1}}</span> <i class="fa fa-arrow-right" aria-hidden="true"></i></li>
    </ul>
</div>
 
            <div class="action-row full-width-row" *ngIf="showRsaDedFree">
                <ul *ngFor="let RVal of loopFromNumber(RSATaxDeductionControls);let i=index" class="line-c form-row d-flex">                  
                    <li class="middleStyle">
                       <span class="middlee">$</span>
                        <input size="2" onkeyup="this.style.width = ((this.value.length + 1) * 8) + 'px';" (keyup)="RSAAmountDed1($event)" (blur)="RSAAmountCalDed(i)" *ngIf="mDed || showMiddleArrowD == i" [(ngModel)]="RSAgrouwthDed[i]" type="text" name="RSAgAmnt1{{i}}" id="norml4{{i}}" placeholder="0" [style.width.px]="width" value="">
                           <input type="{{i}}" [(ngModel)]="RSAgrouwthD[i]" *ngIf="!mDed" [hidden]="showMiddleArrowD==i?true:false " (click)="deductMiddle($event,i)" id="aftrclick4{{i}}"  value="" class="custmwdth 1" readonly>


                    </li>
                   
                    <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
                </ul>
            </div>
        </div>






       <div class="family-info retirement">
        <div class="showyear-center" *ngIf="showdate">
            <p>{{showdiff}} Year</p>
        </div>
        <div class="family-info-inner">
            <div class="top-form">
                <!-- Tax Deferred form begins here -->
                <div class="inner-box">
                        <h5>({{ClName}}) RETIREMENT SAVINGS ACCOUNTS</h5>
                    <div class="personForm" *ngIf="ClientRetirement1">
                        <!-- <h6 *ngIf= "showClient">{{adminN}}</h6> -->
                        <ol>
                            <li>
                                <div class="icon_row">
                                    <ul>
                                        <li><i class="fas fa-plus" (click)="funcAddRetirement()"></i></li>
                                        <li (click)="funcColRetirement()" style="cursor: pointer;">C</li>


</ul>
</div>
</li>
<li>Tax Deferred</li>
<li>
    <div class="icon_row">
        <ul>
            <li (click)="funcExpRetirement()" style="cursor: pointer;">E</li>
            <li><i class="fas fa-minus"  (click)="funcRemoveRetirement()" ></i></li>
        </ul>
    </div>
</li>
<!--   <div>
<ul *ngFor="let data of alls">
<li>{{data.total}}</li>      
</ul>
</div> -->
</ol>
<div  *ngIf = "Showtotal">
    <ul>
        <li>Total</li>
        <!-- <li class="text-right">$263,000</li> -->
        <li class="amountRight fontSizetotal"><span *ngIf="this.RetireTotal && !showRetire">${{RetireTotal}}</span></li>
    </ul>
</div>
<form>
    <div>
        <div *ngIf="showRetire">
            <ul *ngFor="let RVal of loopFromNumber(RetirmentControls);let i=index" class="form-row d-flex">
                <li class="form-group w-50">
                    <input type="text" placeholder="Tax Deferred {{i}}" [(ngModel)]="RetirmentControlsDeff[i]" name="txtDeff{{i}}" required >
                </li>
                <li class="form-group w-50 no-outline">
                    <span class="SizeDollar">$</span>
                    <input name="Amt{{i}}" (blur)="RSAAmountCalComplete(i);SumofAcc();" [hidden]="!amntTest" (keyup)="RSAAmount($event)" [(ngModel)]="RetirmentControlsAmt[i]" type="text" id="norml{{i}}" placeholder="0" class ="SizeAmount" required >
                    <input name="Amt{{i}}" (click)="taxdefferd($event,i)" [hidden]="amntTest"   [(ngModel)]="RetirmentControlsA[i]" type="text" placeholder="Amount" id="aftrclick{{i}}" class ="SizeAmount 1" required >
                </li>
               
            </ul>
        </div>
    </div>
</form>
</div>
<!-- Tax Deferred form ends here -->
<!-- Tax Free form begins here -->
<div class="personForm" *ngIf="ClientRetirement1">
    <ol>
        <li>
            <div class="icon_row">
                <ul>
                    <li><i (click)="RSATaxfreeAdd()" class="fas fa-plus"></i></li>
                    <li (click)="RSATaxFreeCol()" style="cursor: pointer;">C</li>
<!-- <li (click)="RSATaxFreeExp()" style="cursor: pointer;">E</li>
    <li><i (click)="RSATaxfreeRem()" class="fas fa-minus"></i></li> -->
</ul>
</div>
</li>
<li>Tax Free</li>
<li class="equal-width">
    <div class="icon_row">
        <ul>
            <li (click)="RSATaxFreeExp()" style="cursor: pointer;">E</li>
            <li><i (click)="RSATaxfreeRem()" class="fas fa-minus"></i></li>
        </ul>
    </div>
</li>
</ol>
<form>
    <div *ngIf = "Showtotal1">
        <ul>
            <li>Total</li>
            <!-- <li class="text-right">$263,000</li> -->
            <li class="text-right amountRight fontSizetotal"><span *ngIf="this.RetireTax && !showRsaTaxFree">$ {{RetireTax}}</span></li>
        </ul>
    </div>
    <div>
        <div *ngIf="showRsaTaxFree">
            <ul *ngFor="let prfCount of loopFromNumber(RSATaxfreeControls);let i=index" class="form-row d-flex">
                <li class="form-group w-50">
                  <input type="text" placeholder="WD {{i}}" [(ngModel)]="RetirmentTaxFreeDeff[i]" name="RSATaxfreeWD{{i}}" required >
              </li>

              <li class="form-group w-50 no-outline">
                <span class="SizeDollar">$</span>
                <input name="RSATaxfreepercent{{i}}" (keyup)="RSAAmountTax1($event)" (blur)="RSAAmountCalTax(i);SumofAcc();" [hidden]="!amntTax" [(ngModel)]="RetirmentTaxFreeAmt[i]" id="norml1{{i}}" type="text" placeholder="0" class ="SizeAmount" required >

                    <input name="RSATaxfreepercent{{i}}" (click)="Taxfree($event,i)" [hidden]="amntTax"  [(ngModel)]="RetirmentTaxFreeA[i]" type="text" placeholder="Amount" id="aftrclick1{{i}}" class ="SizeAmount 1" required >

            </li>
        </ul>
    </div>
</div>
</form>
</div>
<!-- Tax Free form ends here -->
<div class="personForm" *ngIf="ClientRetirement1">
    <ol>
        <li>
            <div class="icon_row">
                <ul>
                    <li><i (click)="RSATaxDeductionAdd()" class="fas fa-plus"></i></li>
                    <li (click)="RSATaxDedCol()" style="cursor: pointer;">C</li>
                    </ul>
                </div>
            </li>
            <li>Tax Deductible</li>
            <li class="equal-width">
                <div class="icon_row">
                    <ul>
                        <li (click)="RSATaxDedExp()" style="cursor: pointer;">E</li>
                        <li><i (click)="RSATaxDeductionRem()" class="fas fa-minus"></i></li>
                    </ul>
                </div>
            </li>
        </ol>
        <div  *ngIf = "Showtotal2">
            <ul>
                <li>Total</li>

                <li class="text-right amountRight fontSizetotal"><span *ngIf="this.Deduct && !showRsaDedFree">${{Deduct}}</span></li>
            </ul>
        </div>
        <form>
            <div>
                <div *ngIf="showRsaDedFree">
                    <ul *ngFor="let prfCount of loopFromNumber(RSATaxDeductionControls);let i=index" class="form-row d-flex">
                        <li class="form-group w-50">
                            <input type="text" placeholder="WD {{i}}" [(ngModel)]="RetirmentDeductDeff[i]" name="RSATaxdedectWD{{i}}" required>
                        </li>
                        <li class="form-group w-50 no-outline">
                            <span class="SizeDollar">$</span>
                            <input name="RSATaxdedectpercent{{i}}" [hidden]="!amuntded" (blur)="RSAAmountCalDed(i);SumofAcc();" (keyup)="RSAAmountDed1($event)" [(ngModel)]="RetirmentDeductAmt[i]" type="text" placeholder="0" class ="SizeAmount" id="normlk{{i}}" required >
                             <input name="RSATaxdedectpercent{{i}}" (click)="TaxDeduct($event,i)" [hidden]="amuntded"   [(ngModel)]="RetirmentDeductA[i]" type="text" placeholder="Amount" id="aftrclickl{{i}}" class ="SizeAmount 1" required >
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    </div>

    <!-- Tax Deferred form ends here -->

  

<!-- total of above table comes here -->

<div class="total">
    <!--h4>Total</h4-->
    <ul>
        <li>Total</li>
        <li class="text-right" *ngIf="beforeclick">${{TotalAllR}}</li>
        <li class="text-right" *ngIf="clickdef">${{TotalAllR11}}</li>
    </ul>
</div>
</div>

<!-- total of above table ends here -->

</div>
</div>
</div>

<!--------------Tax Deffered Sidebar1---------- -->
<div class="sidebar" *ngIf="ClientRetirement1">
  <div class="sidebarrow" *ngIf = "ShowSidetotal">
    <ul>

        <li class = "fontSizetotal"><i class="fa fa-arrow-right" aria-hidden="true"></i><span *ngIf="this.showSide && !showRetire2">${{showSide}}</span> </li>
    </ul>
</div>
<div class="indicated-data indicated-data2" *ngIf = "showRetire2">
    <ul *ngFor="let RVal of loopFromNumber(RetirmentControls);let i=index" class="form-row d-flex">
        <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
        <li>
        
           <input (blur)="RSAAmountCalComplete(i)"  type="text" name="RSAgrowthPercnt{{i}}" [(ngModel)]="RSAgrowthPercnt[i]" placeholder="0" onfocus="this.select();" onmouseup="return false;" maxlength="3" >
           <span class="SizeDollar1">%</span>
       </li>
       <li>
           <span class="difSize">$</span>
           <div class="show">{{RetContTotCalAmt[i]}} </div>
          
       </li>
   </ul>
</div>
<!----------------Tax Free Sidebar1--------- -->
 <div class="sidebarrow" *ngIf = "ShowSideTax1">
    <ul>

        <li class = "fontSizetotal"><i class="fa fa-arrow-right" aria-hidden="true"></i><span *ngIf="this.showSide && !showRsaTaxFree">${{showSide}}</span> </li>
    </ul>
</div>
<div class="indicated-data indicated-data2" *ngIf = "showRsaTaxFree">
    <ul *ngFor="let RVal of loopFromNumber(RSATaxfreeControls);let i=index" class="form-row d-flex">
        <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
        <li>

           <input (blur)="RSAAmountCalTax(i)"  type="text" name="RSAgrowthPercnt1{{i}}" [(ngModel)]="RSAgrowthPercntTex1[i]" placeholder="0" onfocus="this.select();" onmouseup="return false;" maxlength="3" >
           <span class="SizeDollar1">%</span>
       </li>
       <li>
           <span class="difSize">$</span>
           <div class="show">{{RetContTotTax1[i]}} </div>
          
       </li>
   </ul>
</div>



<!------------------Tax Deductible Sidebar1---------- -->
 <div class="sidebarrow" *ngIf = "ShowSideDed1">
    <ul>

        <li class = "fontSizetotal"> <i class="fa fa-arrow-right" aria-hidden="true"></i><span *ngIf="this.showSide && !showRsaDedFree">${{showSide}}</span></li>
    </ul>
</div>
<div class="indicated-data indicated-data2" *ngIf = "showRsaDedFree">
    <ul *ngFor="let RVal of loopFromNumber(RSATaxDeductionControls);let i=index" class="form-row d-flex">
        <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
        <li>

           <input (blur)="RSAAmountCalDed(i)"  type="text" name="RSAgrowthPercntDed1{{i}}" [(ngModel)]="RSAgrowthPercntDed1[i]" placeholder="0" onfocus="this.select();" onmouseup="return false;" maxlength="3" >
           <span class="SizeDollar1">%</span>
       </li>
       <li>
           <span class="difSize">$</span>
           <div class="show">{{RetContTotDed1[i]}} </div>
          
       </li>
   </ul>
</div>

</div>
</div>
<!-- Third Section Ends Here --->
<!-- Fourth Section Starts Here --->
<div class="top-forms custom-row" id="step2" *ngIf="spList">
  <div class="icon_row coll-exp">
        <ul>
           <li style="cursor: pointer;" [class.active]="stepp2" href="#step2" (click)="collapseSpouseRet()">C</li>

            <li (click)="expandSpouseRet()" style="cursor: pointer;">E</li>
        </ul>
</div>
    <div class="family-info retirement ">
        <div class="family-info-inner">
            <div class="top-form">

                <!-- Tax Deferred form begins here -->
                <div class="inner-box">
                        <h5>({{SpName}}) retirement income projection</h5>
                    <div class="personForm" *ngIf="SpouseRetirement1">
                        <!-- <h6 *ngIf= "showSpouse">{{SpouseN}}</h6> -->
                        <ol class="li-4 d-flex heading_row">
                            <li>W
                            </li>
                            <li>C</li>
                            <li>Tax Deferred</li>
                            <li>Amount</li>

                        </ol>
                        <div  *ngIf = "Showtotal32">
                            <ul>
                                <li>Total</li>
                                 <li  class="amountRight fontSizetotal"><span *ngIf="this.showAmountRet && !showRetd">${{showAmountRet}}</span></li>
                            </ul>
                        </div>

                        <div>
                            <div *ngIf="showRetd">
                                <ul *ngFor="let RVal of loopFromNumber(RSA2taxdefControls);let i=index" class="inputrow form-row li-4 d-flex">
                                   <li class="form-group d-flexstyle">
                                      <input type="text" placeholder="0" name="rateRetd{{i}}" maxlength="3" [(ngModel)]="rateRet1[i]" (blur)="SumofAll2()" (change)="funcCalPrj($event,i)" onfocus="this.select();" onmouseup="return false;">
                                      <span class="SizePercent">%</span>

                                </li>
                                <li class="form-group">
                                    <input type="text" placeholder="cola" required>
                                </li>
                                <li class="form-group">
                                 <input type="text" placeholder="Tax Deferred {{i}}" [(ngModel)]="Retirment2ndControlsDeff[i]" name="txtDeff{{i}}"required>
                             </li>
                             <li class="form-group w-33 no-outline">
                                <span class="SizeDollar">$</span>
                                <input type="text" class="SizeAmount text-right "  [(ngModel)]="RetirmentControlsDeffTax1[i]"  name="txtDeffTaxRate1{{i}}" placeholder="0" readonly required >
                            </li>                                        
                        </ul>
                    </div>
                </div>

            </div>

            <!-- Tax Deferred form ends here -->

            <!-- Tax Free form begins here -->

            <div class="personForm" *ngIf="SpouseRetirement1">
                <ol>
                    <li>
                        <li>Tax Free</li>
                        <li>
                        </ol>
                        <div  *ngIf = "Showtotalt2">
                            <ul>
                                <li>Total</li>
                                 <li  class="amountRight fontSizetotal"><span *ngIf="this.showATax && !showRetTax2">${{showATax}}</span></li>
                            </ul>
                        </div>
                        <form>
                            <div>
                                <div *ngIf="showRetTax2">
                                    <ul *ngFor="let RVal of loopFromNumber(RSA2taxFreeControls);let i=index" class="inputrow form-row d-flex">
                                        <li class="form-group w-33">
                                      <input type="text" placeholder="WD {{i}}" [(ngModel)]="RetirmentTaxFree2ndDeff[i]" name="taxfree22{{i}}"required>
                                  </li>
                                        <li class="form-group w-33 d-flexstyle">
                                            
                                              <input type="text" placeholder="0" name="rateRetf{{i}}" maxlength="3" [(ngModel)]="rateTaxF1[i]" (blur)="SumofAll2()" (change)="funcCalPrj1($event,i)" onfocus="this.select();" onmouseup="return false;">
                                               <span class="SizePercent">%</span>
                                        </li>

                                        <li class="form-group w-33 no-outline">
                                            <span class="SizeDollar">$</span>
                                           
                                            <input type="text" class="SizeAmount text-right SizeAmount"  [(ngModel)]="RetirmentControlsTaxFree1[i]"  name="txtTaxR{{i}}" placeholder="0" readonly required >
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Tax Free form ends here -->
                    <div class="personForm" *ngIf="SpouseRetirement1">
                        <ol>
                            <li>
                                <li>Tax Deductible</li>
                                <li>
                                   </li>
                                </ol>
                                 <div  *ngIf = "Showtotded">
                            <ul>
                                <li>Total</li>
                                 <li  class="amountRight fontSizetotal"><span *ngIf="this.showADed && !showRetDed2">${{showADed}}</span></li>
                            </ul>
                        </div>
                                <form>
                                    <div>
                                        <div *ngIf="showRetDed2">
                                            <ul *ngFor="let RVal of loopFromNumber(RSA2taxDeductControls);let i=index" class="inputrow form-row d-flex">
                                                <li class="form-group w-33 ">
                                                    
                                                     <input type="text" placeholder="WD {{i}}" [(ngModel)]="RetirmentTaxDeduct2ndDeff[i]" name="taxdeduct22{{i}}"required>
                                                </li>
                                                <li class="form-group w-33 d-flexstyle">
                                                    <input type="text" placeholder="0" name="rateR{{i}}" maxlength="3" (blur)="SumofAll2()" [(ngModel)]="rateDed1[i]" (change)="funcCalPrj2($event,i)" onfocus="this.select();" onmouseup="return false;">
                                                     <span class="SizePercent">%</span>
                                                </li>

                                                <li class="form-group w-33 no-outline" >
                                                    <span class="SizeDollar">$</span>
                                                   <input type="text" class="SizeAmount text-right SizeAmount"  [(ngModel)]="RetirmentControlsDed1[i]"  name="txtd{{i}}" placeholder="0" readonly required >
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <!-- Tax Deferred form ends here -->

                            <!-- social security form begins here -->

                            <div class="personForm" *ngIf="SpouseRetirement1">
                                <ol>

                                    <li><i (click)="RSA2PensionAdd()" class="fas fa-plus"></i></li>
                                    <li>Pension & Social Security</li>
                                    <li><i (click)="RSA2PensionRem();SumofAll2();" class="fas fa-minus"></i></li>
                                </ol>
                                <form>
                                    <div>
                                        <div *ngIf="showRetSavAcc3">
                                            <ul *ngFor="let RVal of loopFromNumber(RSA2PensionControls);let i=index" class="form-row d-flex">
                                                <li class="form-group w-33">
                                                     <input type="text" placeholder="WD" required>
                                                   
                                                </li>
                                                <li class="form-group w-33">
                                                    <input type="text" [(ngModel)]="NamePension1[i]" placeholder="Name {{i}}" [ngModelOptions]="{standalone: true}" required>
                                                </li>

                                                <li class="form-group no-outline w-33">
                                                    <span class="SizeDollar">$</span>
                                                    <input type="text" class="text-right SizeAmount" (blur)="SumofAll2()" (keyup)="RSAPension1($event)"[(ngModel)]="RetPension1[i]"  name="txtded{{i}}" placeholder="0" required>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <!-- social security form ends here -->

                            <!-- total of above table comes here -->

                            <div class="total">
                                <!--h4>Total</h4-->
                                <ul>
                                    <li>Total</li>
                                    <li class="text-right">${{TotalAll2}}</li>
                                </ul>
                            </div>
                        </div>

                        <!-- total of above table ends here -->

                    </div>
                </div>
            </div>
            <div class="middle-section middle-section1 mid" *ngIf="SpouseRetirement1">

                <!-- ----Tax Deffered Middle2 -->
                     <div class="dirction-row"  *ngIf = "ShowMiddletotal1">
                <ul class="total_amount form-row d-flex new-amm">
                    <li><span *ngIf="this.ShowMiddle1 && !showRetdef">${{ShowMiddle1}}</span></li>
                    <li class="rightSign"> <i class="fa fa-arrow-right" aria-hidden="true"></i></li>
                </ul>
            </div>

            <div class="action-row full-width-row" *ngIf="showRetdef">
                <ul *ngFor="let RVal of loopFromNumber(RSA2taxdefControls);let i=index" class="line-c form-row d-flex">                  
                    <li class="middleStyle">
                     <span class="middlee">$</span>
                     <input size="2" onkeyup="this.style.width = ((this.value.length + 1) * 8) + 'px';" (keyup)="RSAAmount1($event)" (blur)="RSAAmountCalComplete1(i)"  [(ngModel)]="RSAgrouwthAmount1[i]" type="text" name="RSAgAmnt1{{i}}"  [style.width.px]="width" value="" placeholder="0" class="custmwdth" *ngIf="mDef2 || showMiddleArrowD2 == i" id="nl2{{i}}">
                     <input type="{{i}}" [(ngModel)]="RSAgrouwthAm2[i]" *ngIf="!mDef2" [hidden]="showMiddleArrowD2==i?true:false " (click)="tstDef2($event,i)" id="aftrcl2{{i}}"  value="" class="custmwdth 1" readonly>
                 </li>

                 <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
             </ul>
         </div>
         <!------Tax Free Middle2--------- -->
        <div class="dirction-row"  *ngIf = "ShowMiddletotalTax1">
    <ul class="total_amount form-row d-flex new-amm">
        <li class="" ><span *ngIf="this.ShowMiddleTax2 && !taxFMid1">${{ShowMiddleTax2}}</span> <i class="fa fa-arrow-right" aria-hidden="true"></i></li>
    </ul>
</div>
 
            <div class="action-row full-width-row" *ngIf="taxFMid1">
                <ul *ngFor="let RVal of loopFromNumber(RSA2taxFreeControls);let i=index" class="line-c form-row d-flex">                  
                    <li class="middleStyle">
                       <span class="middlee">$</span>
                        <input size="2" onkeyup="this.style.width = ((this.value.length + 1) * 8) + 'px';" (keyup)="RSAAmountTax2($event)" (blur)="RSAAmountCalTax1(i)"  [(ngModel)]="RSAgrouwthTax1[i]" type="text" name="RSAgAmnt3{{i}}"  [style.width.px]="width" value="" placeholder="0" class="custmwdth" *ngIf="mTax1 || showMiddleArrowT1 == i" id="n2{{i}}">
                    <input type="{{i}}" [(ngModel)]="RSAgrouwthTax2[i]" *ngIf="!mTax1" [hidden]="showMiddleArrowT1==i?true:false " (click)="taxMiddle1($event,i)" id="aftr2{{i}}"  value="" class="custmwdth 1" readonly>
                    </li>
                   
                    <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
                </ul>
            </div>


         <!---------------Tax Deduct Middle2------- -->
          <div class="dirction-row" *ngIf = "ShowMiddletotalDed1">
    <ul class="total_amount form-row d-flex new-amm">
        <li class="" ><span *ngIf="this.ShowMiddleDed2 && !showmidded2">${{ShowMiddleDed2}}</span> <i class="fa fa-arrow-right" aria-hidden="true"></i></li>
    </ul>
</div>
 
            <div class="action-row full-width-row" *ngIf="showmidded2">
                <ul *ngFor="let RVal of loopFromNumber(RSA2taxDeductControls);let i=index" class="line-c form-row d-flex">                  
                    <li class="middleStyle">
                       <span class="middlee">$</span>
                        <input size="2" onkeyup="this.style.width = ((this.value.length + 1) * 8) + 'px';" (keyup)="RSAAmountDed2($event)" (blur)="RSAAmountCalDed1(i)"  [(ngModel)]="RSAgrouwthDed1[i]" type="text" name="RSAgAmnt2{{i}}"   [style.width.px]="width" value="" placeholder="0" *ngIf="mDed1 || showMiddleArrowD1 == i" id="d2{{i}}">
                        <input type="{{i}}" [(ngModel)]="RSAgrouwthDed2[i]" *ngIf="!mDed1" [hidden]="showMiddleArrowD1==i?true:false " (click)="deductMiddle1($event,i)" id="aftrd2{{i}}"  value="" class="custmwdth 1" readonly>
                    </li>
                   
                    <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
                </ul>
            </div>
            </div>
                           
                                    <div class="family-info retirement ">
                                        <div class="family-info-inner">
                                            <div class="top-form">
                                                <!-- Tax Deferred form begins here -->
                                                <div class="inner-box">
                                                        <h5>({{SpName}}) RETIREMENT SAVINGS ACCOUNTS</h5>
                                                    <div class="personForm" *ngIf="SpouseRetirement1">
                                                        <!-- <h6>Andrew</h6> -->
                                                       <!--  <h6 *ngIf= "showSpouse">{{SpouseN}}</h6> -->
                                                        <ol>
                                                            <li>
                                                                <div class="icon_row">
                                                                    <ul>
                                                                        <li><i (click)="RSA2taxDefAdd()" class="fas fa-plus"></i></li>
                                                                        <li (click)="RetSavAccCol()" style="cursor: pointer;">C</li>
</ul>
</div>
</li>
<li>Tax Deferred</li>
<li class="equal-width">
    <div class="icon_row">
        <ul>
            <li (click)="RetSavAccExp()" style="cursor: pointer;">E</li>
            <li><i (click)="RSA2taxDefRem()" class="fas fa-minus"></i></li>
        </ul>
    </div>
</li>
</ol>
<div  *ngIf = "Showtotal3">
    <ul>
        <li>Total</li>
        <!-- <li class="text-right">$263,000</li> -->
        <li class="text-right amountRight fontSizetotal"><span *ngIf="this.Retire1 && !showRetSavAcc">${{Retire1}}</span></li>
    </ul>
</div>
<form>
    <div>
        <div *ngIf="showRetSavAcc">
            <ul *ngFor="let RVal of loopFromNumber(RSA2taxdefControls);let i=index" class="form-row d-flex">

                <li class="form-group w-50">
                    <input type="text" placeholder="Tax Deferred {{i}}" [(ngModel)]="Retirment2ndControlsDeff[i]" name="txtDeff{{i}}"required>
                </li>
                <li class="form-group w-50 no-outline">
                    <span class="SizeDollar">$</span>
                    <input name="Amt1{{i}}" (keyup)="RSAAmount1($event)" (blur)="RSAAmountCalComplete1(i);SumofAcc1()"
                    [(ngModel)]="Retirment2ndControlsAmt[i]" type="text" [hidden]="!amntTest" placeholder="0" class ="SizeAmount" id="bclick{{i}}" required >
                    <input name="Amt1{{i}}" (click)="taxdefferd2($event,i)" [hidden]="amntTest"   [(ngModel)]="Retirment2ndControlsA[i]" type="text" placeholder="Amount" id="aftrclk{{i}}" class ="SizeAmount 1" required>
                </li>
            </ul>
        </div>
    </div>
</form>
</div>

<!-- Tax Deferred form ends here -->

<!-- Tax Free form begins here -->

<div class="personForm" *ngIf="SpouseRetirement1">
    <ol>
        <li>
            <div class="icon_row">
                <ul>
                    <li><i (click)="RSA2TaxFreeAdd()" class="fas fa-plus"></i></li>
                    <li (click)="RetSavAcc1Col()" style="cursor: pointer;">C</li>
                   
                    </ul>
                </div>
            </li>
            <li>Tax Free</li>
            <li class="equal-width">
                <div class="icon_row">
                    <ul>
                        <li (click)="RetSavAcc1Exp()" style="cursor: pointer;">E</li>
                        <li><i (click)="RSA2TaxFreeRem()" class="fas fa-minus"></i></li>
                    </ul>
                </div>
            </li>
        </ol>
        <div  *ngIf = "Showtotal4">
            <ul>
                <li>Total</li>
                <!-- <li class="text-right">$263,000</li> -->
                <li class="text-right amountRight fontSizetotal"><span *ngIf="this.Retiretaxfree2 && !showRetSavAcc1">${{Retiretaxfree2}}</span></li>
            </ul>
        </div>
        <form>
            <div>
                <div *ngIf="showRetSavAcc1">
                    <ul *ngFor="let RVal of loopFromNumber(RSA2taxFreeControls);let i=index" class="form-row d-flex">
                        <li class="form-group w-50">

                          <input type="text" placeholder="WD {{i}}" [(ngModel)]="RetirmentTaxFree2ndDeff[i]" name="taxfree22{{i}}"required>
                      </li>
                      <li class="form-group w-50 no-outline">
                          <span class="SizeDollar">$</span> 
                          <input name="taxfree2{{i}}" (keyup)="RSAAmountTax2($event)" [(ngModel)]="RetirmentTaxFree2ndAmt[i]" [hidden]="!amnttaxF1" id="tax22{{i}}" (blur)="RSAAmountCalTax1(i);SumofAcc1()" type="text" placeholder="0" class ="SizeAmount" required >
                     
                          <input name="taxfree2{{i}}" (keyup)="RSAAmountTax2($event)" [(ngModel)]="RetirmentTaxFree2ndA[i]" (click)="Taxfree2($event,i)" [hidden]="amnttaxF1" id="tax11{{i}}" type="text" placeholder="0" class ="SizeAmount" required >
                      </li>
                  </ul>
              </div>
          </div>
      </form>
  </div>

  <!-- Tax Free form ends here -->
  <div class="personForm" *ngIf="SpouseRetirement1">
    <ol>
        <li>
            <div class="icon_row">
                <ul>
                    <li><i (click)="RSA2TaxeductAdd()" class="fas fa-plus"></i></li>
                    <li (click)="RetSavAcc2Col()" style="cursor: pointer;">C</li>
                   
                    </ul>
                </div>
            </li>
            <li>Tax Deductible</li>
            <li class="equal-width">
                <div class="icon_row">
                    <ul>
                        <li (click)="RetSavAcc2Exp()" style="cursor: pointer;">E</li>
                        <li><i (click)="RSA2TaxeductRem()" class="fas fa-minus"></i></li>
                    </ul>
                </div>
            </li>
        </ol>
        <div  *ngIf = "Showtotal5">
            <ul>
                <li>Total</li>
                <li class="text-right amountRight fontSizetotal"><span *ngIf="this.RetireDeduct2 && !showRetSavAcc2">${{RetireDeduct2}}</span></li>
            </ul>
        </div>
        <form>
            <div>
                <div *ngIf="showRetSavAcc2">
                    <ul *ngFor="let RVal of loopFromNumber(RSA2taxDeductControls);let i=index" class="form-row d-flex">
                        <li class="form-group w-50">
                         <input type="text" placeholder="WD {{i}}" [(ngModel)]="RetirmentTaxDeduct2ndDeff[i]" name="taxdeduct22{{i}}"required>
                     </li>
                     <li class="form-group w-50 no-outline">
                       <span class="SizeDollar">$</span>
                       <input name="taxdeduct2{{i}}" (keyup)="RSAAmountDed2($event)" (blur)="RSAAmountCalDed1(i);SumofAcc1();" [(ngModel)]="RetirmentTaxDeduct2ndAmt[i]" [hidden]="!amuntdeduct" id="ded22{{i}}" type="text" placeholder="0" class ="SizeAmount" required >
                       <input name="taxdeduct2{{i}}" (keyup)="RSAAmountDed2($event)" [hidden]="amuntdeduct" id="ded2{{i}}" (click)="TaxDeduct2($event,i)" [(ngModel)]="RetirmentTaxDeduct2ndA[i]" type="text" placeholder="0" class ="SizeAmount" required >
                   </li>
               </ul>
           </div>
       </div>
   </form>
</div>

<!-- Tax Deduct form ends here -->


    <!-- total of above table comes here -->

    <div class="total">
        <!--h4>Total</h4-->
        <ul>
            <li>Total</li>
         <li class="text-right" *ngIf="beforeclick2">${{TotalAllR1}}</li>
        <li class="text-right" *ngIf="clickdef2">${{TotalAllR2}}</li>
        </ul>
    </div>
</div>

<!-- total of above table ends here -->

</div>
</div>
</div>
<div class="sidebar" *ngIf="SpouseRetirement1">
    <!-------------------Tax Deffered Sidebar2 ------------->
<div class="sidebarrow" *ngIf = "ShowSideDeff2">
    <ul>

        <li class = "fontSizetotal"><i class="fa fa-arrow-right" aria-hidden="true"></i><span *ngIf="this.showSide && !showRet">${{showSide}}</span> </li>
    </ul>
</div>
<div class="indicated-data indicated-data2" *ngIf = "showRet">
    <ul *ngFor="let RVal of loopFromNumber(RSA2taxdefControls);let i=index" class="form-row d-flex">
        <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
        <li>

           <input (blur)="RSAAmountCalComplete1(i)"  type="text" name="RSAgrowthPercntt1{{i}}" [(ngModel)]="RSAgrowthPercnt1[i]" placeholder="0" onfocus="this.select();" onmouseup="return false;" maxlength="3" >
           <span class="SizeDollar1">%</span>
       </li>
       <li>
           <span class="difSize">$</span>
           <div class="show">{{RetContTotCalAmt1[i]}} </div>
          
       </li>
   </ul>
</div>
<!-------------------Tax Free Sidebar2--------------- -->
<div  class="sidebarrow" *ngIf = "ShowSideTax2">
    <ul>

        <li class = "fontSizetotal"> <i class="fa fa-arrow-right" aria-hidden="true"></i><span *ngIf="this.showTax2 && !showTaxFree2">${{showTax2}}</span></li>
    </ul>
</div>
<div class="indicated-data indicated-data2" *ngIf = "showTaxFree2">
    <ul *ngFor="let RVal of loopFromNumber(RSA2taxFreeControls);let i=index" class="form-row d-flex">
        <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
        <li>

           <input (blur)="RSAAmountCalTax1(i)"  type="text" name="RSAgrowthPercn1{{i}}" [(ngModel)]="RSAgrowthPercntTex2[i]" placeholder="0" onfocus="this.select();" onmouseup="return false;" maxlength="3" >
           <span class="SizeDollar1">%</span>
       </li>
       <li>
           <span class="difSize">$</span>
           <div class="show">{{RetContTotTax2[i]}} </div>
          
       </li>
   </ul>
</div>

<!------------------Tax Deduct Sidebar2-------------------->
<div  class="sidebarrow" *ngIf = "ShowSided2">
    <ul>

        <li class = "fontSizetotal"> <i class="fa fa-arrow-right" aria-hidden="true"></i><span *ngIf="this.showDeduct2 && !showded2">${{showDeduct2}}</span></li>
    </ul>
</div>
<div class="indicated-data indicated-data2" *ngIf = "showded2">
    <ul *ngFor="let RVal of loopFromNumber(RSA2taxDeductControls);let i=index" class="form-row d-flex">
        <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
        <li>

           <input (blur)="RSAAmountCalDed1(i)"  type="text" name="RSAgrowthPerDed2{{i}}" [(ngModel)]="RSAgrowthPercntDed2[i]" placeholder="0" onfocus="this.select();" onmouseup="return false;" maxlength="3" >
           <span class="SizeDollar1">%</span>
       </li>
       <li>
           <span class="difSize">$</span>
           <div class="show">{{RetContTotDed2[i]}} </div>
          
       </li>
   </ul>
</div>

</div>



</div>

<!-- Fourth Section Starts Here --->

<!-- <div class="family-info retirement investment investtotal">
                <div class="family-info-inner">
                    <div class="total">
                        <ul>
                            <li>Total amount of client</li>
                            <li class="text-right">$310,000</li>
                        </ul>
                    </div>
                    <div class="box-header">
                        <ul>
                            <li>Total amount of spouse</li>
                            <li class="text-right">$310,000</li>
                        </ul>
                    </div>
                    

                </div>

            </div> -->
  


<div class="top-forms custom-row" id="invest-last">
    <div class="family-info retirement investment">
        <div class="family-info-inner">

            <h5>INVESTMENT INCOME</h5>
            <div class="top-form">

                <!-- Investment form begins here -->

                <div class="personForm">
                    <!-- <ol>
                        <li></li>
                        <li>Andrew</li>
                        <li></li>
                    </ol> -->
                     <div  *ngIf = "ShowtotalIncome">
                            <ul>
                                <li>Total</li>
                                <li  class="amountRight fontSizetotal"><span *ngIf="this.showAmountIncome && !showRetIncome">${{showAmountIncome}}</span></li>
                            </ul>
                        </div>
                    <form>
                        <div>
                            <div *ngIf="showRetIncome">

                                <ul *ngFor="let prfCount of loopFromNumber(InvestControls); let i=index" class="inputrow form-row li-4 d-flex">
                                    <li class="form-group d-flexstyle">
                                       <input type="text" placeholder="0" name="rateRetd{{i}}" maxlength="3" [(ngModel)]="rateInvst1[i]" (change)="funcCalInvest($event,i)" onfocus="this.select();" onmouseup="return false;">
                                       <span class="SizePercent">%</span>
                                    </li>
                                    <li class="form-group">
                                        <input name="valuesecpercent" type="text" placeholder="0%
                                        " required>

                                    </li>
                                    <li class="form-group">

                                        <input name="InvestControlsDeff{{i}}" [(ngModel)]="InvestControlsDeff[i]" type="text" placeholder="Gold" required>
                                    </li>
                                    <li class="form-group no-outline">
                                       <span class="SizeDollar">$</span>
                                            <input type="text" class="SizeAmount text-right "  [(ngModel)]="InvesmentIncome[i]"  name="txtDeffTe{{i}}" placeholder="0" readonly required >
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- Investment form ends here -->
            </div>
        </div>
    </div>
    <!-----------------Investment Middle--------- -->
    <div class="middle-section">
         <div  class=""  *ngIf = "ShowInvest">
                <ul class="total_amount form-row d-flex Turn new-amm">
                    <li><span *ngIf="this.showMidInvest && !ShowInvestment">${{showMidInvest}}</span></li>
                    <li class="rightSign"> <i class="fa fa-arrow-right" aria-hidden="true"></i></li>
                </ul>
            </div>

            <div class=" action-row" *ngIf="ShowInvestment">
                <ul *ngFor="let RVal of loopFromNumber(InvestControls);let i=index" class="line-c form-row d-flex Turn">                  
                    <li class="middleStyle">
                     <span class="middlee">$</span>
                     <input size="2" onkeyup="this.style.width = ((this.value.length + 1) * 8) + 'px';" (keyup)="Investmentt($event)" (blur)="InvestmentAmunt(i)"  [(ngModel)]="InvestAmount[i]" type="text" name="RSAgAmn{{i}}" placeholder="0" [style.width.px]="width" value="" class="custmwdth" *ngIf="mInv || showMiddleArrowInv == i">
                     <input type="{{i}}" [(ngModel)]="InvestAmount1[i]" *ngIf="!mInv" [hidden]="showMiddleArrowInv==i?true:false " (click)="investMiddle($event,i)" id="aftrclick2{{i}}"  value="" class="custmwdth 1" readonly>
                 </li>

                 <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
             </ul>
         </div>
</div>
     
    <div class="family-info retirement investment">
        <div class="family-info-inner">
          <!--   <div class="box-icon"><img alt="Investment" class="box-img" src="{{baseUrl}}assets/images/investment.png"></div> -->
            <div class="box-icon"><img alt="Investment" class="box-img" src="../assets/images/investment.png"></div>
            <div class="personForm finalformRed">
                        <ol>
                            <li>
                            <div class="icon_row">
                                <ul>
                                    <li><i (click)="InvestAndrewAdd()" class="fas fa-plus"></i></li>
                                    <li (click)="InvestAndrewCol()" style="cursor: pointer;">C</li>
                                </ul>
                                </div>
                            </li>
                            <li>INVESTMENTS</li>
                            <li class="equal-width">
                                <div class="icon_row">
                                <ul>
                                    <li (click)="InvestAndrewExp()" style="cursor: pointer;">E</li>
                                    <li><i (click)="InvestAndrewRem()" class="fas fa-minus"></i></li>
                                </ul>
                                </div>
                            </li>
                        </ol>
                    </div>
            <div class="top-form">
                <!-- Investment form begins here -->
                <div class="personForm">
                        <div *ngIf = "ShowtotalInvest">
                            <ul>
                                <li>Total</li>

                                <li class="text-right amountRight fontSizetotal"><span *ngIf="this.InvestTotal && !InvestAndrew">${{InvestTotal}}</span></li>
                            </ul>
                        </div>

                        <div>
                            <div *ngIf="InvestAndrew">

                                <ul *ngFor="let prfCount of loopFromNumber(InvestControls);let i= index" class="form-row">
                                    <li class="form-group w-50">
                                        <input name="company{{i}}" type="text" placeholder="Company Name" [(ngModel)]="InvestControlsDeff[i]"  required>
                                    </li>
                                    <li class="form-group w-50 no-outline">
                                        <span class="SizeDollar">$</span>
                                        <input name="amountt{{i}}" (keyup)="Investmentt($event)" (blur)="InvestmentAmunt(i)"type="text" placeholder="0"  [hidden]="!amntInvst"
                                         [(ngModel)]="InvestControlsAmt[i]" class ="SizeAmount" required>
                            
                                        <input name="amountt{{i}}" (keyup)="Investmentt($event)" (blur)="InvestmentAmunt(i)"type="text" placeholder="0" [hidden]="amntInvst"
                                         [(ngModel)]="InvestControlsA[i]"  class ="SizeAmount" required>
                                    </li> 
                                  </ul>
                                </div>
                            </div>
                        </div>
                        <!-- Investment form ends here -->
                    </div>
                </div>
            </div>
            <!----------------Investment Sidebar-------------- -->
         <div class="sidebar">
  <div class="sidebarrow" *ngIf = "ShowSideInvest">
    <ul class="arrow-size Turn">

        <li class = "fontSizetotal"><i class="fa fa-arrow-right" aria-hidden="true"></i><span *ngIf="this.showSideInvst && !shoeInvest2">${{showSideInvst}}</span> </li>
    </ul>
</div>
<div class="indicated-data indicated-data2" *ngIf = "shoeInvest2">
    <ul *ngFor="let RVal of loopFromNumber(InvestControls);let i=index" class="form-row d-flex Turn">
        <li><i class="fa fa-arrow-right" aria-hidden="true"></i></li>
        <li>

           <input (blur)="InvestmentAmunt(i)"  type="text" name="RSAgrowthInvst{{i}}" [(ngModel)]="RSAgrowthInvest[i]" placeholder="0" onfocus="this.select();" onmouseup="return false;" maxlength="3" >
           <span class="SizeDollar1">%</span>
       </li>
       <li>
           <span class="difSize">$</span>
           <div class="show">{{InvestAmt[i]}} </div>
          
       </li>
   </ul>
</div>
</div>
            <div class="family-info retirement investment investtotal">
                <div class="family-info-inner">
                    <div class="total">
                        <ul>
                            <li>ACCUMULATED INCOME FOR YEAR END</li>
                            <li class="text-right">$310,000</li>
                        </ul>
                    </div>
                    <div class="box-header">
                        <ul>
                            <li>ACCUMULATED INCOME FOR YEAR END</li>
                            <li class="text-right">$310,000</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="top-forms custom-row mrgn">
            <div class="family-info retirement dept_window">
                <div class="family-info-inner">
                    <div class="box-icon">
                       <!--  <img alt="dabt window" class="box-img" src="{{baseUrl}}assets/images/dab_window.png"> -->
                        <img alt="dabt window" class="box-img" src="../assets/images/dab_window.png">
                    </div>

                    <!-- <h5>debt window</h5> -->
                    <div class="personForm finalformRed">
                        <ol>
                            <li><i (click)="DebtWindowAdd()" class="fas fa-plus"></i></li>
                            <li>debt window</li>
                            <li><i (click)="DebtWindowRem()" class="fas fa-minus"></i></li>
                        </ol>
                    </div>
                    <div class="top-form">
                        <ul>
                            <li>
                                <p>Loan Type</p>
                            </li>
                            <li>
                                <p>5 Per mo (p+1)</p>
                            </li>
                            <li>
                                <p>Balance</p>
                            </li>
                            <li>
                                <p>Rate</p>
                            </li>
                            <li>
                                <p>Month</p>
                            </li>
                        </ul>
                        <!-- Investment form begins here -->
                        <div class="personForm">
                            <form>
                                <div>
                                    <div>
                                        <ul *ngFor="let prfCount of loopFromNumber(DebtWindowControls)" class="form-row">
                                            <li class="form-group">
                                                <input type="text" placeholder="Loan Type" required>
                                            </li>
                                            <li class="form-group">
                                                <input type="text" placeholder="5 Per mo (p+1)" required>
                                            </li>
                                            <li class="form-group">
                                                <input type="text" placeholder="Balance" required>
                                            </li>
                                            <li class="form-group">
                                                <input type="text" placeholder="Rate" required>
                                            </li>
                                            <li class="form-group">
                                                <input type="text" placeholder="Month" required>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- Investment form ends here -->
                    </div>
                </div>
            </div>
            <div class="middle-section">&nbsp;</div>
            <div class="family-info retirement investment">
                <div class="family-info-inner">
                    <div class="box-icon">
                        <!-- <img alt="Real Estate" class="box-img" src="{{baseUrl}}assets/images/real_easte.png"> -->
                        <img alt="Real Estate" class="box-img" src="../assets/images/real_easte.png">
                    </div>
                    <h5>Real Estate</h5>
                    <div class="top-form">
                        <!-- Investment form begins here -->
                        <div class="personForm">
                            <ol>
                                <li><i (click)="ResateHomeResAdd()" class="fas fa-plus"></i></li>
                                <li>Home Residence</li>
                                <li><i (click)="ResateHomeResRem()" class="fas fa-minus"></i></li>
                            </ol>
                            <form>
                                <div>
                                    <div>
                                        <ul *ngFor="let prfCount of loopFromNumber(RealEstateHomeControls)" class="form-row d-flex">
                                            <li class="form-group w-50">
                                                <input name="RealEstateHomeResName" type="text" placeholder="Home Residence" required>
                                            </li>
                                            <li class="form-group w-50 no-outline">
                                               <span class="SizeDollar">$</span>
                                               <input name="realEstatehomeAmt" type="text" class ="SizeAmount" placeholder="0" (keyup)="transformAmount($event)" required>
                                           </li>
                                       </ul>
                                   </div>
                               </div>
                           </form>
                       </div>
                       <!-- Investment form ends here -->

                       <!-- Investment form begins here -->
<!-- <div class="personForm">
<ol>
<li><i (click)="ResateCollageAdd()" class="fas fa-plus"></i></li>
<li>Collage</li>
<li><i (click)="ResateCollageRem()" class="fas fa-minus"></i></li>
</ol>
<form>
<div>
<div>
<ul *ngFor="let prfCount of loopFromNumber(RealEstateCollageControls)" class="form-row d-flex">
<li class="form-group w-50">
<input name="RealEstateColResName" type="text" placeholder="Collage" required>
</li>
<li class="form-group w-50">
<input name="RealEstateHomeAmt" type="text" placeholder="Amount" required>
</li>
</ul>
</div>
</div>
</form>
</div> -->
<!-- Investment form ends here -->
</div>
</div>
</div>
<div class="sidebar">&nbsp;</div>
</div>

</div>
</div>
</div>
</div>
</div>



</body>

</html>