import { Component, OnInit, Input,EventEmitter,Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router } from "@angular/router";
import { AmountService } from '../../services/amount.service';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-add-client',
	templateUrl: './add-client.component.html',
	styleUrls: ['./add-client.component.css']
})
export class AddClientComponent implements OnInit {
	@Output() updateNewClient: EventEmitter<any> = new EventEmitter();
	adminForm: FormGroup;
	showSpouseForm: boolean = false;
	loader:boolean= false;
	constructor(public activeModal: NgbActiveModal,
		private fb: FormBuilder,
		private router: Router,
		private _amunt: AmountService,) { }

	ngOnInit() {
		this.adminForm = this.fb.group({
			admin: [''],
			first: ['', [Validators.required]],
			last: ['', [Validators.required]],
			state:['', [Validators.required]],
			Spouse_First: [''],
			Spouse_Last: [''],
		}); 
	}

	CurrMarried(id){
		if(id == 'yes'){
			this.showSpouseForm = true
		}else{
			this.showSpouseForm = false
			}
	}

	get clientF() {
		return this.adminForm.controls;
	}

	submitClient(adminForm){
		console.log(adminForm.value)
		let param;
		if(this.showSpouseForm == true ){
			if ( this.adminForm.invalid ) {
				//console.log('aa');
				this.adminForm.controls['first'].markAsTouched();
				this.adminForm.controls['last'].markAsTouched();        
				this.adminForm.controls['state'].markAsTouched();
			}else{
				param = {
					'Admin_Name': adminForm.value.first.toLowerCase()+ " " +adminForm.value.last.toLowerCase(),
					'Firstname': adminForm.value.first.toLowerCase(),
					'Lastname': adminForm.value.last.toLowerCase(),
					'state': adminForm.value.state.toLowerCase(),
					'Spouse_First': adminForm.value.Spouse_First.toLowerCase(),
					'Spouse_Last': adminForm.value.Spouse_Last.toLowerCase(),
					}
				}
			}
			else{
				this.adminForm.removeControl('Spouse_First');
				this.adminForm.removeControl('Spouse_Last');
				if( this.adminForm.invalid ){
					this.adminForm.controls['first'].markAsTouched();
					this.adminForm.controls['last'].markAsTouched();        
					this.adminForm.controls['state'].markAsTouched();
				}
				else{
					param = {
						'Admin_Name': adminForm.value.first.toLowerCase()+ " " +adminForm.value.last.toLowerCase(),
						'Firstname': adminForm.value.first.toLowerCase(),
						'Lastname': adminForm.value.last.toLowerCase(),
						'state': adminForm.value.state.toLowerCase(),
						'Spouse_First': '',
						'Spouse_Last': '',
					}
				}
			}
			console.log(param)
			if(param)
				{
					this.loader = true;
					this._amunt.addClient(param).subscribe(res =>{
						let data:any = res;
						console.log(res)
						//console.log(data._id);
						Swal.fire({
							position: 'center',
							icon: 'success',
							title: 'Client has been added successfully.',
							showConfirmButton: false,
							timer: 1200
						})
						this.activeModal.close();
						this.updateNewClient.emit(res);
						this.adminForm.reset();
						/*if(data.Firstname!=null){
							//console.log('hello')
							localStorage.removeItem('dataC');
							this.router.navigate(['/index']);
						}*/
					});
					this.loader = false;
				}	
	}

	closeForm(){
		this.activeModal.close();     
	}

}
