import { Component, OnInit, ViewChild, ElementRef , TemplateRef } from '@angular/core';
import * as myGlob from '../../globals';
//import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth.service';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { AmountService } from '../services/amount.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    errorDisp: any;
    password: any;
    show: boolean = false;
  constructor(private auth: AuthService,
  	private fb: FormBuilder,
  	private _amunt: AmountService,
  	private router: Router) { }

  ngOnInit(): void {
    this.password = 'password';
  	this.loginForm = this.fb.group({
        passwd: ['',[Validators.required]],
        email: ['',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],

    });
  }

    onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

   get adminF() { 
    return this.loginForm.controls; 
  }


onSubmit(loginForm){
   if (this.loginForm.invalid) {
        this.loginForm.controls['passwd'].markAsTouched();
        this.loginForm.controls['email'].markAsTouched();

    }else{
      
            let email =  this.loginForm.value.email;
            let pass =  this.loginForm.value.passwd;
        

        if(email == 'ccampbell@gmail.com' && pass == 'chris@123'){
         let data = {
            "Email": this.loginForm.value.email,
            "Password": this.loginForm.value.passwd
         } 
          this.loginForm.reset();
          this.auth.addSAdmin(data);
          this.router.navigate(['/index'])
        }else{
           Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Invalid email and password',
            showConfirmButton: true,
          }) 
            this.loginForm.reset();
        }
   }
}


/*onSubmit(loginForm){
	 if(this.loginForm.value.email == ''){
        this.errorDisp = 'Please Enter Valid Email';
    }
	 if (this.loginForm.invalid) {
        this.loginForm.controls['passwd'].markAsTouched();
       // this.loginForm.controls['email'].markAsTouched();

    }else{
    	let param = {
            'Email': this.loginForm.value.email,
            'Password': this.loginForm.value.passwd,
        }
        this._amunt.signin(param).subscribe(res =>{
        	let data: any = res;
        	if(data != 0){
            this.loginForm.reset();
        	this.auth.addSAdmin(data);

            this.router.navigate(['/index'])
        	}else{
               Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Invalid email and password',
            showConfirmButton: true,
          }) 
            this.loginForm.reset();
       	 }
      });
   }
}
*/

/*signup(){
    this.router.navigate(['/sign-up'])

}*/
}