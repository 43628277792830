<!DOCTYPE html>
<html>

<body>


	<div class="center login-form">
		<h1>Sign In</h1>
		<form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)" name="loginForm">
			<div class="form-group">
				<input type="text" class="form-control" formControlName="email" required>
				<span></span>
				<label class="email">Email</label>        
			</div>
                  <span class="clrA" *ngIf="!loginForm.get('email').valid && loginForm.get('email').touched">Please Enter Valid Email</span>
         
			<div class="form-group">
				<input [type]="password" class="form-control" formControlName="passwd"required>
				<span></span>
				<label class="passwd">Password</label>     <p (click)="onClick()">
                   <i class="fa fa-eye" aria-hidden="true"></i>
                     </p>
			</div>  
			  <span class="clrA" *ngIf="!loginForm.get('passwd').valid && loginForm.get('passwd').touched">Please Enter Password</span>        

			<button type="submit" class="login">Login</button>
			<!-- <div class="signlink">
				Don't have an account?<a href="javascript:void(0)" (click)="signup()"> Sign up</a>
			</div> -->

		</form>
	</div>

</body>
</html>
