import { Component, OnInit, ViewChild, ElementRef,TemplateRef  } from '@angular/core';
import * as myGlob from '../../globals';
//import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth.service';
import { AmountService } from '../services/amount.service';
import { Retirement1Service } from '../services/retirement1.service';
import { Retirement2Service } from '../services/retirement2.service';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { SavingService } from '../services/saving.service';
import { AddClientComponent } from '../component/add-client/add-client.component';

import { observable, Observable } from 'rxjs';
import { Amountdetail } from '../schemas/amountdetail';
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
	selector: 'app-index',
	templateUrl: './index.component.html',
	styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
	@ViewChild('txtAndrew') txtAndrew : ElementRef;
	@ViewChild('txtDebbie') txtDebbie : ElementRef;
	@ViewChild('RSAgAmnt') RSAgAmnt : ElementRef;
	@ViewChild('RSAActAmnt') RSAActAmnt : ElementRef;
	@ViewChild('txtPrf') txtPrf : ElementRef;
	@ViewChild('txtName') txtName : ElementRef;
	@ViewChild('searchbar') searchbar : ElementRef;
	showSpouseForm: boolean = false;
	loader:boolean= false;
	showSearch: boolean = true;
	modalRef: BsModalRef;
	admin: any;
	adminN: any;
	searchName: any;
	dataP = [];
	dataN = [];
	data1: any;
	searchResult1: Array<any> = [];
	reqArray: Array<any> = []
	ShowAddClt: boolean = true;
	ShowCltName: boolean = false;
	SearchN: boolean = false;
	clickdef: boolean = false;
	beforeclick: boolean = true;
	clickdef2: boolean = false;
	beforeclick2: boolean = true;
	showYear: boolean = true;
	showInt: boolean = false;
	TotalAllR: any = 0;
	TotalAllR1: any = 0;
	TotalAllR2: any = 0;
	TotalAllR11: any = 0;
	TotalAll2: any = 0;
	startAge: any;
	indexValue: any;
	showPercent: any;
	stopAge: any;
	TotalAll1: any = 0;
	getPrev: any;
	showArr = [];
	showMiddleArrow: number = -1
	showMiddleArrowT: number = -1
	showMiddleArrowT1: number = -1
	showMiddleArrowD: number = -1
	showMiddleArrowD1: number = -1
	showMiddleArrowInv: number = -1

	showMiddleArrowDed1: number = -1
	showMiddleArrowDef2: number = -1
	showMiddleArrowSav: number = -1
	showMiddleArrowCol: number = -1

	showMiddleArrowD2: number = -1
	showName: any;
	showNamee: boolean = false;
	showAge: any;
	NamePension=[];
	NamePension1=[];
	NameControl=[];
	clientt=[];
	spousee=[];
	spouseName:any;
	clientName: any;
	accordianData: any;
	accordianData1: any;
	client: any;
	spouse: any
	start:any[]=[];
	stop:any[]=[];
	start1:any[]=[];
	stop1:any[]=[];
	iconShow :boolean = false;
	showdiff1: any;
	showdiff:any[]=[0] ;
	expandedIndex = -1 
	globalName: any;
	mDef1: boolean = true;
	mSav1: boolean = true;
	mCol: boolean =true;
	mDef11: boolean = false;
	mDef2: boolean = true;
	mDef22: boolean = false;
	mDef12: boolean = true;
	mTax: boolean = true;
	mDed: boolean = true;
	mTax1: boolean = true;
	mDed1: boolean = true;
	mInv: boolean = true;
	showdate: boolean = false;
	ShowtotalSaving: boolean = false;
	ShowtotalCollage:boolean = false;
	Showtotal: boolean = false;
	ShowMiddletotal:boolean = false;
	ShowMiddletotal1:boolean = false;
	ShowSideInvest: boolean = false;
	ShowMiddletotalTax:boolean = false;
	ShowMiddletotalDed:boolean = false;
	ShowMiddletotalTax1:boolean = false;
	ShowMiddletotalDed1:boolean = false;
	taxFMid1: boolean =true;
	shoeInvest2: boolean = true;
	ShowtotalIncome: boolean =false;
	showRetIncome: boolean = true;
	parms= {}
	parms1= {}

	parmsA= {}
	parmTax= {}
	parm2Tax={}
	parmT= {}
	parmT2= {}
	parmD2= {}
	
	ShowMidSaving: boolean = false;
	ShowMidCollage: boolean = false;
	ShowInvest: boolean = false;
	ShowInvestment: boolean =true;
	ShowSidetotal:boolean = false;
	ShowSideDeff2: boolean = false;
	ShowSideTax1: boolean = false;
	ShowSideDed1: boolean = false;
	ShowSideSav: boolean = false;
	ShowSideCol: boolean = false;
	showded2: boolean = true;
	ShowtotalRetired: boolean = false;
	ShowtotalRetTax: boolean = false;
	ShowtotalRetDed: boolean = false;
	ShowSided2: boolean = false;
	Showtotal1: boolean = false;
	Showtotal2: boolean = false;
	Showtotal3: boolean = false;
	Showtotal32: boolean = false;
	Showtotalt2: boolean = false;
	Showtotal4: boolean = false;
	Showtotal5: boolean = false;
	Showtotal6: boolean = false;
	Showtotal7: boolean = false;
	Showtotal8: boolean = false;
	ShowtotalInvest:boolean = false;
	ShowSideTax2: boolean = false;
	showTaxFree2: boolean = true;
	showRetTax2: boolean = true;
	fetchAmt: any;
	showSideInvst: any = 0;
	showAmountIncome: any = 0;
	baseUrl= myGlob.baseUrl;
	intervalControls=1;
	intervalControl=1;
	profileControls=1;
	profileControl1=1;
	name: any;

	savingControls=1
	savingControlscollage=1;
	//InvestIncmControls=1;
	RealEstateHomeControls=1;
	DebtWindowControls=1;
	//RealEstateCollageControls=1;
	RetirmentControls=1
	InvestControls=1;
	RetirmentControlsDeff=[];
	RetirmentIncome=[];
	Retirment2ndControlsDeff=[];
	RetirmentTaxIncome=[];
	RetirmentTaxFreeDeff=[];
	RetirmentTaxFreeDeff1=['WD 1'];

	RetirmentTaxFree2ndDeff=[];
	RetirmentDeductDeff=[];
	Ded_Income=[];
	RetirmentTaxDeduct2ndDeff=[];
	SavingControlsDeff=['SAVING 1'];
	CollageControlsDeff=['SAVING 1'];
	first: any;
	last: any;



	Retirment2ndControlsAmt:any[]=[0];
	Retirment2ndControlsA:any[]=[0];

	RetirmentTaxFree2ndAmt:any=[0];
	RetirmentTaxFree2ndA:any=[0];
	RetirmentTaxDeduct2ndA:any[]=[0];
	
	RetirmentTaxDeduct2ndAmt:any[]=[0];
	SavingControlsAmt:any[]=[0];
	SavingControlsA:any[]=[0];

	CollageControlsAmt:any[]=[0];
	CollageControlsA:any[]=[0];

	RetContTotCalAmt:any[]=[0];
	RetContTotCalAmt1:any[]=[0];

	RetContTotTax1:any[]=[0];
	RetContTotDed1:any[]=[0];
	RetContTotTax2:any[]=[0];
	RetContTotDed2:any[]=[0];

	SavAmount:any[]=[0];
	SavAmo:any[]=[0];

	ColAmount:any[]=[0];
	ColAmount1:any[]=[0];
	TotSav:any[]=[0];
	TotCol:any[]=[0];
	InvestAmount: any[]=[0];
	InvestAmount1: any[]=[0];
	rateInvst1: any[]=[0];

	RetirmentPension1: any[]=[0];
	RetirmentPension2: any[]=[0];
	RetPension1: any[]=[0];
	RetPension2: any[]=[0];

	InvesmentIncome:any[]=[0];
	RetirmentTaxFreeAmt:any[]=[0];
	RetirmentTaxFreeA:any[]=[0];
	RetirmentControlsDeffTax:any[]=[0];
	RetirmentControlsDeffTax1:any[]=[0];

	RetirmentControlsDeff1:any[]=[0];

	RetirmentControlsTaxFree:any[]=[0];
	RetirmentControlsDed:any[]=[0];
	RetirmentControlsDed1:any[]=[0];

	RetirmentControlsTaxFree1:any[]=[0];
	
	rateRet:any[]=[0];
	rateRet1:any[]=[0];
	rateTaxF1:any[]=[0];
	rateTaxF: any[]=[0];
	rateDed: any[]=[0];
	rateDed1: any[]=[0];

	InvestControlsDeff=['COMPANY NAME 1'];
	RetirmentControlsAmt:any[]=[0];
	RetirmentControlsA: any[]=[0];
	RetirmentDeductAmt:any[]=[0];
	RetirmentDeductA:any[]=[0];

	InvestControlsA:any[]=[0];    
	InvestControlsAmt:any[]=[0];
	RSAgrowthPercnt:any[]=[0];
	RSAgrowthPercnt1:any[]=[0];

	RSAgrowthPercntTex1:any[]=[0];
	RSAgrowthPercntDed1:any[]=[0];
	RSAgrowthPercntTex2:any[]=[0];
	RSAgrowthPercntDed2:any[]=[0];
	RSAgrowthInvest:any[]=[0];
	InvestAmt:any[]=[0];


	SavingSidbar:any[]=[0];
	CollageSidebar:any[]=[0];
	showMidInvest: any=0;
	
	RetireTotal: any=0;
	ShowMiddle: any=0;
	ShowMiddle1: any=0;
	showDeduct2: any=0;
	ShowMiddleTax1: any=0;
	ShowMiddleDed1: any=0;
	ShowMiddleTax2: any=0;
	ShowMiddleDed2: any=0;
	ShowMiddleSav: any=0;
	ShowMiddleCol: any=0;

	showSide: any=0;
	showSide1: any=0;
	showSide2: any=0;
	showSide3: any=0;
	showTax2: any= 0;
	showADed: any = 0;
	showAmountRetired: any =0;
	showAmountRet : any =0;

	showAmountTax: any =0;
	showAmountDed: any =0;
	showATax: any =0;
	Retire1: any=0;
	RetireTax: any=0;
	Deduct:any=0;
	Retiretaxfree2:any=0;
	RetireDeduct2:any=0;
	RetireSaving:any=0;

	InvestTotal:any=0;
	RSATaxfreeControls=1;
	RSATaxDeductionControls=1;
	RSAPensionControls=1;
	RSA2taxdefControls=1;
	RSA2taxFreeControls=1;
	RSA2taxDeductControls=1;
	RSA2PensionControls=1;


	RSAgrouwthAmount:any[]=[0];
	RSAgrouwthAmo:any[]=[0];

	RSAgrouwthAmount1:any[]=[0];
	RSAgrouwthAmo1:any[]=[0];

	RSAgrouwthAm2:any[]=[0];

	RSAgrouwthTax: any[]=[0];
	RSAgrouwthT: any[]=[0];

	RSAgrouwthDed: any[]=[0];
	RSAgrouwthD: any[]=[0];

	RSAgrouwthTax1: any[]=[0];
	RSAgrouwthDed1: any[]=[0];
	RSAgrouwthTax2: any[]=[0];
	RSAgrouwthDed2: any[]=[0];
	showRet:boolean = true;
	showRetire:boolean=true;
	showRetire2:boolean=true;
	showRetired:boolean=true;
	Showtotded: boolean =false;
	showRetDed2: boolean=true;
	IncreasePersnt:boolean = true;
	showRetire1:boolean= true;
	showRetireTax1: boolean = true;
	showRetireDed1: boolean = true;
	Additionalform: boolean = true;
	ClientRetirement1: boolean = true;
	SpouseRetirement1: boolean =true;
	showDivSaving:boolean=true;
	showDivSaving1:boolean=true;
	showDivCollage:boolean=true;
	showDivCollage1:boolean=true;
	showRetdef:boolean = true;
	showRsaTaxFree:boolean=true;
	showRsaTaxFree1:boolean=true;
	showRetd: boolean = true;
	showRsaDedFree:boolean=true;
	showRsapensionFree:boolean=true;
	showRetSavAcc:boolean=true;
	showRetSavAcc1:boolean=true;
	showRetSavAcc2:boolean=true;
	showmidded2: boolean = true;
	showRetSavAcc3:boolean=true;
	InvestAndrew:boolean=true;
	amuntSav: boolean = true;
	amuntSav1: boolean = false;
	amuntCol: boolean =true;
	amuntCol1: boolean=false;
	amntTest: boolean = true;
	amntTest2: boolean = true;
	pensionTst: boolean = true;
	amntTax: boolean = true;
	Name1:{};
	amntTest1: boolean = false;
	amuntTax: boolean = true;
	amuntTax1: boolean = false;
	amuntded: boolean = true;
	amuntded1: boolean = false;
	amntdeff: boolean = true;
	amntdeff1: boolean = false;
	amnttaxF: boolean = true;
	amnttaxF1: boolean = false;
	amuntdeduct: boolean = true;
	amuntdeduct1: boolean = false;
	amntInvst: boolean = true;
	amntInvst1: boolean = false;
	aa:any='';
	parm = {};
	parmA = {};
	parm2A = {};
	parmD = {};
	parmDed = {};
	parmDed2 = {};
	parm2Ded = {};
	parmSav = {};
	parmCol={};
	parmInv={};
	parmP1 = {};
	parmInt = {};

	User_id : any
	idd: any
	dataa: any;
	CollageTotal:any;
	data11:number;
	data: any;
	formattedDate : any;
	message:string;
	width: number = 64;
	totalCount: number = 0;
	searchResult: any;
	searchInput: any;
	dataA: any;
	viewAdmin: any;
	items: string[]
	stepp: any;
	stepp1: any;
	stepp2: any;
	SpouseN: any;
	ClName: any;
	SpName: any;
	showSpouse: boolean = false;
	showClient: boolean = false;
	spList: boolean = false;
	RSgrwth: any;
	RSgrwthTx: any;
	RSgrwthD: any;
	RSgrwth1: any;
	RSgrwthTx1: any;
	RSgrwthD1: any;
	SavGrowth: any;
	ColGrowth: any;
	IntGrowth: any;
	constructor(private auth: AuthService,
		private _amunt: AmountService,
		private _ret: Retirement1Service,
		private _retire: Retirement2Service,
		private _Sav : SavingService,
		private elem: ElementRef,
		private fb: FormBuilder,
		private router: Router,
		private modalService: BsModalService,
		private ngbService: NgbModal,
		 //private formArray: FormArray
		 ) { 
		
		const user = this.auth.getUser();
		if(user._id){
			let res = user;
			this.adminN = user.Admin_Name;
			this.clientt[0] = user.Firstname
			this.spousee[0] = user.Spouse_First;
			this.ClName = user.Firstname;
			this.showClient = true
			this.ShowCltName = true;
			if(user.Spouse_First == ''){
				this.spList = false
			}else{
				this.spList = true

				this.SpName = user.Spouse_First;

			}

			//this.getListClient(res)
		}
		this._amunt.defaultValuee().subscribe(res=>{
				console.log(res);
			})

	}

	ngOnInit() {
		/*var localData=JSON.parse(localStorage.getItem('dataA'));
		this._Sav.getData(localData._id).subscribe(res=>{
			console.log(res);
		})*/
		if(localStorage.getItem('dataC')!=null)
			{
				this.router.navigate(['/edit-client']);
			}
	}

	funcAppendProfile(){
		this.profileControls++;
	}
	funcRemoveProfile(){
		if(this.profileControls>1)
			this.profileControls--;  
	}





	/*------------Collapse & Expand Addtional and Saving----------------*/

	collapseAdditional(){
		this.Additionalform = false;
		$("#step110").addClass("active");
	}

	expandAdditional(){
		this.Additionalform = true;
		$("#step110").removeClass("active");

	}

	/*-----------Collapse & Expand Retirement1--------------*/
	collapseClientRet(){
		this.ClientRetirement1 = false
		$("#step1").addClass("active");

	}

	expandClientRet(){
		this.ClientRetirement1 = true 
		$("#step1").removeClass("active");

	}

	/*-----------Collapse & Expand Retirement2--------------*/
	collapseSpouseRet(){
		this.SpouseRetirement1 = false
		$("#step2").addClass("active");

	}

	expandSpouseRet(){
		this.SpouseRetirement1 = true  
		$("#step2").removeClass("active");

	}
	/*-----------------Saving Start Here---------------------*/

	savingAddRow(){
		++this.totalCount;
		this.savingControls++;
		this.SavingControlsDeff.push('SAVING '+this.savingControls);
		this.SavingControlsAmt.push(0);
		this.SavingControlsA.push(0);

		this.SavAmount.push(0);
		this.SavAmo.push(0);
		this.SavingSidbar.push(0);
		this.TotSav.push(0);


	}
	savingRemRow(){
		if(this.savingControls>1)
			this.savingControls--;
		this.SavingControlsDeff.splice(this.savingControls,1);
		this.SavingControlsAmt.splice(this.savingControls,1);
		this.SavingControlsA.splice(this.savingControls,1);
		this.SavAmo.splice(this.savingControls,1);

		this.SavAmount.splice(this.savingControls,1);
		this.SavingSidbar.splice(this.savingControls,1);
		this.TotSav.splice(this.savingControls,1);



	}

	collapseSaving(){
		this.showDivSaving=false;
		this.showDivSaving1=false;

		this.ShowtotalSaving = true;
		let total:number=0;
		for(var i in this.SavingControlsAmt) {  

			let fetchAmt1:any='';
			fetchAmt1=this.SavingControlsAmt[i];
			if(fetchAmt1!=0)

				total += parseInt(fetchAmt1.replace(/,/g, ''));


		}
		this.RetireSaving = new Intl.NumberFormat().format(total);

		let total1 :number=0;
		for(var i in this.SavAmount) {
			let fetch12: any = '';
			fetch12 = this.SavAmount[i];

			if(fetch12!=0)
				total1 += parseInt(fetch12.replace(/,/g, ''));
		}
		this.ShowMiddleSav = new Intl.NumberFormat().format(total1);
		this.ShowMidSaving = true;

		let total2 :number = 0;
		for(var i in this.TotSav){
			let fetch13:any = '';
			fetch13 = this.TotSav[i];
			if(fetch13!=0)
				total2  += parseInt(fetch13.replace(/,/g, ''));
		}
		this.showSide1 = new Intl.NumberFormat().format(total2);
		this.ShowSideSav = true;
	}



	expandSaving(){
		this.showDivSaving=true;
		this.showDivSaving1=true;

		this.ShowtotalSaving = false;
		for(var i in this.SavingControlsAmt) {
			if(this.SavingControlsAmt[i]!=0)
				this.SavingControlsAmt[i]= new Intl.NumberFormat().format(parseInt(this.SavingControlsAmt[i].replace(/,/g, '')));
			else
				this.SavingControlsAmt[i]= 0;
		}

		this.ShowMidSaving = false;


		for(var i in this.SavAmount) {
			if(this.SavAmount[i]!=0)
				this.SavAmount[i]= new Intl.NumberFormat().format(parseInt(this.SavAmount[i].replace(/,/g, '')));
			else
				this.SavAmount[i]= 0;

		}

		this.ShowSideSav = false;

		for(var i in this.TotSav) {
			if(this.TotSav[i]!=0)
				this.TotSav[i]= new Intl.NumberFormat().format(parseInt(this.TotSav[i].replace(/,/g, '')));
			else
				this.TotSav[i]= 0;

		}
	}


	RSaving(element){
		if(element.target.value != ''){
			let savingA = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
			element.target.value = savingA; 
			this.data = savingA;
		}
	}
	SavingAmnut(i){

		if(this.SavingControlsAmt[i] && this.SavingSidbar[i]){
			if(this.SavAmount[i] == 0){
				this.SavGrowth = 0;
				let savingA = this.SavGrowth;
			}else{
				this.SavGrowth = parseInt(this.SavAmount[i].replace(/,/g, ''));
				let savingA = this.SavGrowth;
			}
			let savingA = this.SavGrowth;

			let SavingControlsAmt:any =0;

			if(this.SavingControlsAmt[i]!=0)
				SavingControlsAmt= parseInt(this.SavingControlsAmt[i].replace(/,/g, '')); 

			let enteredRate=this.SavingSidbar[i];
  //let diff = this.stop[i] - this.start[i];
  let diff = this.showAge;
	//let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
	if(SavingControlsAmt>=0 || savingA>=0 || enteredRate>=0){
		if(diff){
			for(var l=0;l<diff;l++){
				SavingControlsAmt+=savingA;
				SavingControlsAmt+=(SavingControlsAmt*enteredRate)/100;
				
			}}else{
				SavingControlsAmt+=savingA;
				SavingControlsAmt+=(SavingControlsAmt*enteredRate)/100;
			}     
			this.TotSav[i]=new Intl.NumberFormat().format(parseInt(SavingControlsAmt));
		}    
	}
}
//}



/*---------------Saving End Here------------*/
/*-----------------Collage Start Here---------------------*/
savingCollageAddRow(){
	++this.totalCount;
	this.savingControlscollage++;
	this.CollageControlsDeff.push('SAVING '+this.savingControlscollage);
	this.CollageControlsAmt.push(0);
	this.CollageControlsA.push(0);

	this.ColAmount.push(0);
	this.ColAmount1.push(0);
	this.CollageSidebar.push(0);
	this.TotCol.push(0);



}
savingCollageRemRow(){
	if(this.savingControlscollage>1)
		this.savingControlscollage--;
	this.CollageControlsDeff.splice(this.savingControlscollage,1);
	this.CollageControlsAmt.splice(this.savingControlscollage,1);
	this.CollageControlsA.splice(this.savingControlscollage,1);

	this.ColAmount.splice(this.savingControlscollage,1);
	this.ColAmount1.splice(this.savingControlscollage,1);
	this.CollageSidebar.splice(this.savingControlscollage,1);
	this.TotCol.splice(this.savingControlscollage,1);



}

collapseCollage(){
	this.showDivCollage=false;
	this.showDivCollage1=false;

	this.ShowtotalCollage = true;
	let total:number=0;
	for(var i in this.CollageControlsAmt) {
		let fetchAmt:any='';
		fetchAmt=this.CollageControlsAmt[i];
		if(fetchAmt!=0)
			total += parseInt(fetchAmt.replace(/,/g, ''));
	}
	
	this.CollageTotal = new Intl.NumberFormat().format(total);

	let total1 :number=0;
	for(var i in this.ColAmount) {
		let fetch12: any = '';
		fetch12 = this.ColAmount[i];

		if(fetch12!=0)
			total1 += parseInt(fetch12.replace(/,/g, ''));
	}
	this.ShowMiddleCol = new Intl.NumberFormat().format(total1);
	this.ShowMidCollage = true;

	let total2 :number=0;
	for(var i in this.TotCol) {
		let fetch12: any = '';
		fetch12 = this.TotCol[i];

		if(fetch12!=0)
			total2 += parseInt(fetch12.replace(/,/g, ''));
	}
	this.showSide2 = new Intl.NumberFormat().format(total2);
	this.ShowSideCol = true;

}
expandCollage(){
	this.showDivCollage=true;
	this.showDivCollage1=true;

	this.ShowtotalCollage = false;
	for(var i in this.CollageControlsAmt) {
		if(this.CollageControlsAmt[i]!=0)
			this.CollageControlsAmt[i]= new Intl.NumberFormat().format(parseInt(this.CollageControlsAmt[i].replace(/,/g, '')));
		else
			this.CollageControlsAmt[i]= 0;
	}
	

	this.ShowMidCollage = false;

	for(var i in this.ColAmount) {
		if(this.ColAmount[i]!=0)
			this.ColAmount[i]= new Intl.NumberFormat().format(parseInt(this.ColAmount[i].replace(/,/g, '')));
		else
			this.ColAmount[i]= 0;

	}

	this.ShowSideCol = false;

	for(var i in this.TotCol) {
		if(this.TotCol[i]!=0)
			this.TotCol[i]= new Intl.NumberFormat().format(parseInt(this.TotCol[i].replace(/,/g, '')));
		else
			this.TotCol[i]= 0;
		
	}
}
RCollage(element){
	if(element.target.value != ''){
		let collageA = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
		element.target.value = collageA; 
	}
	
}
CollageAmnut(i){

	if(this.CollageControlsAmt[i] && this.CollageSidebar[i]){
		if(this.ColAmount[i] == 0){
			this.ColGrowth = 0;
			let collageA = this.ColGrowth;
		}else{
			this.ColGrowth = parseInt(this.ColAmount[i].replace(/,/g, ''));
			let collageA = this.ColGrowth;
		}
		let collageA = this.ColGrowth;
		let CollageControlsAmt:any =0;

		if(this.CollageControlsAmt[i]!=0)
			CollageControlsAmt= parseInt(this.CollageControlsAmt[i].replace(/,/g, '')); 

		let enteredRate=this.CollageSidebar[i];
  //let diff = this.stop[i] - this.start[i];
  let diff = this.showAge;
	//let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
	
	if(CollageControlsAmt>=0 || collageA>=0 || enteredRate>=0){
		if(diff){
			for(var l=0;l<diff;l++){
				CollageControlsAmt+=collageA;
				CollageControlsAmt+=(CollageControlsAmt*enteredRate)/100;

			}}else{
				CollageControlsAmt+=collageA;
				CollageControlsAmt+=(CollageControlsAmt*enteredRate)/100;
			}     
			this.TotCol[i]=new Intl.NumberFormat().format(parseInt(CollageControlsAmt));
		}    
	}
}
/*-----------------Collage End Here---------------------*/


	/*InvestIncAndrewAdd(){
		this.InvestIncmControls++;
	}
	InvestIncAndrewRem(){
		if(this.InvestIncmControls>1)
			this.InvestIncmControls--;
	}*/
	

	ResateHomeResAdd(){
		this.RealEstateHomeControls++;
		this.DebtWindowControls++;
	}
	ResateHomeResRem(){
		if(this.RealEstateHomeControls>1)
			this.RealEstateHomeControls--;
		if(this.DebtWindowControls>1)
			this.DebtWindowControls--;
	}


	DebtWindowAdd(){
		this.DebtWindowControls++;
	}
	DebtWindowRem(){
		if(this.DebtWindowControls>1)
			this.DebtWindowControls--;
	}
	/*ResateCollageAdd(){
		this.RealEstateCollageControls++;
	}
	ResateCollageRem(){
		if(this.RealEstateCollageControls>1)
		this.RealEstateCollageControls--;
}*/






RSAPensionAdd(){
	this.RSAPensionControls++;
	this.RetirmentPension1.push(0);
	this.RetirmentPension2.push(0);
	this.NamePension.push();

}
RSAPensionRem(){
	if(this.RSAPensionControls>1)
		this.RSAPensionControls--;
	this.RetirmentPension1.splice(this.RSAPensionControls,1);
	this.RetirmentPension2.splice(this.RSAPensionControls,1);
	this.NamePension.splice(this.RSAPensionControls,1);
}

RSAPension(element){
	if(element.target.value != ''){ 
		let RSAgrouwthP = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
		element.target.value = RSAgrouwthP; 
	}
}

RSA2PensionAdd(){
	this.RSA2PensionControls++;
	this.RetPension1.push(0);
	this.RetPension2.push(0);
	this.NamePension1.push();

}
RSA2PensionRem(){
	if(this.RSA2PensionControls>1)
		this.RSA2PensionControls--;
	this.RetPension1.splice(this.RSA2PensionControls,1);
	this.RetPension2.splice(this.RSA2PensionControls,1);
	this.NamePension1.splice(this.RSA2PensionControls,1);
	
}

RSAPension1(element){
	if(element.target.value != ''){
		let RSAgrouwthP1 = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
		element.target.value = RSAgrouwthP1; 
	}
}
/******** Collapse/Expand Functions Starts **********/




/*-----Tax Free 1 Start Here-----------*/
RSATaxfreeAdd(){
	++this.totalCount;
	this.RSATaxfreeControls++;
	this.RetirmentTaxFreeDeff.push();
	this.RetirmentTaxIncome.push();
	this.RetirmentTaxFreeAmt.push(0);
	this.RetirmentTaxFreeA.push(0);
	this.RSAgrouwthTax.push(0);
	this.RSAgrouwthT.push(0);

	this.RetContTotTax1.push(0);
	this.RSAgrowthPercntTex1.push(0);
	this.rateTaxF.push(0);
	this.RetirmentControlsTaxFree.push(0);




}
RSATaxfreeRem(){
	if(this.RSATaxfreeControls>1)
		this.RSATaxfreeControls--;
	this.RetirmentTaxFreeDeff.splice(this.RSATaxfreeControls,1);
	this.RetirmentTaxIncome.splice(this.RSATaxfreeControls,1);

	this.RetirmentTaxFreeAmt.splice(this.RSATaxfreeControls,1);
	this.RetirmentTaxFreeA.splice(this.RSATaxfreeControls,1);

	this.RSAgrouwthTax.splice(this.RSATaxfreeControls,1);
	this.RSAgrouwthT.splice(this.RSATaxfreeControls,1);

	this.RetContTotTax1.splice(this.RSATaxfreeControls,1);
	this.RSAgrowthPercntTex1.splice(this.RSATaxfreeControls,1);
	this.rateTaxF.splice(this.RSATaxfreeControls,1);
	this.RetirmentControlsTaxFree.splice(this.RSATaxfreeControls,1);




}

RSATaxFreeCol(){

	this.showRsaTaxFree=false;
	this.Showtotal1= true;
	let total:number=0;
	for(var i in this.RetirmentTaxFreeAmt) {
		let fetchfree:any='';
		fetchfree=this.RetirmentTaxFreeAmt[i];
		if(fetchfree!=0)
			total += parseInt(fetchfree.replace(/,/g, ''));
	}

	this.RetireTax = new Intl.NumberFormat().format(total);
	this.showRsaTaxFree1 = false;
	this.ShowMiddletotalTax = true
	let total1 :number=0;
	for(var i in this.RSAgrouwthTax) {
		let fetch12: any = '';
		fetch12 = this.RSAgrouwthTax[i];
		if(fetch12!=0)
			total1 += parseInt(fetch12.replace(/,/g, ''));
	}
	this.ShowMiddleTax1 = new Intl.NumberFormat().format(total1);

	let total2 :number = 0;
	for(var i in this.RetContTotTax1){
		let fetch13:any = '';
		fetch13 = this.RetContTotTax1[i];
		if(fetch13!=0)
			total2  += parseInt(fetch13.replace(/,/g, ''));
	}
	this.showSide = new Intl.NumberFormat().format(total2);
	this.ShowSideTax1 = true;

	let total2Ret :number = 0;
	for(var i in this.RetirmentControlsTaxFree){
		let fetch13:any = '';
		fetch13 = this.RetirmentControlsTaxFree[i];
		if(fetch13!=0)
			total2Ret  += parseInt(fetch13.replace(/,/g, ''));
	}
	this.showAmountTax = new Intl.NumberFormat().format(total2Ret);

	this.ShowtotalRetTax = true;


}
RSATaxFreeExp(){
	this.showRsaTaxFree= true;
	this.Showtotal1= false;
	this.amntTax = true;
	for(var i in this.RetirmentTaxFreeAmt) {
		if(this.RetirmentTaxFreeAmt[i]!=0)
			this.RetirmentTaxFreeAmt[i]= new Intl.NumberFormat().format(parseInt(this.RetirmentTaxFreeAmt[i].replace(/,/g, '')));
		else
			this.RetirmentTaxFreeAmt[i]= 0;

	}

	for(var i in this.RetirmentTaxFreeA) {
		if(this.RetirmentTaxFreeA[i]!=0)
			this.RetirmentTaxFreeA[i]= new Intl.NumberFormat().format(parseInt(this.RetirmentTaxFreeA[i].replace(/,/g, '')));
		else
			this.RetirmentTaxFreeA[i]= 0;

	}


	this.showRsaTaxFree1= true;

	this.ShowMiddletotalTax = false;
	for(var i in this.RSAgrouwthTax) {
		if(this.RSAgrouwthTax[i]!=0)
			this.RSAgrouwthTax[i]= new Intl.NumberFormat().format(parseInt(this.RSAgrouwthTax[i].replace(/,/g, '')));
		else
			this.RSAgrouwthTax[i]= 0;

	}
	this.ShowSideTax1 = false;
	for(var i in this.RetContTotTax1) {
		if(this.RetContTotTax1[i]!=0)
			this.RetContTotTax1[i]= new Intl.NumberFormat().format(parseInt(this.RetContTotTax1[i].replace(/,/g, '')));
		else
			this.RetContTotTax1[i]= 0;

	}
	this.ShowtotalRetTax = false;

	for(var i in this.RetirmentControlsTaxFree) {
		if(this.RetirmentControlsTaxFree[i]!=0)
			this.RetirmentControlsTaxFree[i]= new Intl.NumberFormat().format(parseInt(this.RetirmentControlsTaxFree[i].replace(/,/g, '')));
		else
			this.RetirmentControlsTaxFree[i]= 0;

	}
}


RSAAmountTax1(element){
	if(element.target.value != ''){ 
		let RSAgrouwthTax = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
		element.target.value = RSAgrouwthTax; 
	}
}
RSAAmountCalTax(i){

	if(this.RetirmentTaxFreeAmt[i] && this.RSAgrowthPercntTex1[i]){
		if(this.RSAgrouwthTax[i] == 0){
			this.RSgrwthTx = 0;
			let RSAgrouwthTax = this.RSgrwthTx;
		}else{
			this.RSgrwthTx = parseInt(this.RSAgrouwthTax[i].replace(/,/g, ''));
			let RSAgrouwthTax = this.RSgrwthTx;
		}
		let RSAgrouwthTax = this.RSgrwthTx;

		let RetirmentTaxFreeAmt:any =0;

		if(this.RetirmentTaxFreeAmt[i]!=0)
			RetirmentTaxFreeAmt= parseInt(this.RetirmentTaxFreeAmt[i].replace(/,/g, '')); 

		let enteredRate=this.RSAgrowthPercntTex1[i];
		let diff = this.showAge;
		if(RetirmentTaxFreeAmt>=0 || RSAgrouwthTax>=0 || enteredRate>=0){
			if(diff){
				for(var l=0;l<diff;l++){
					RetirmentTaxFreeAmt+=RSAgrouwthTax;
					RetirmentTaxFreeAmt+=(RetirmentTaxFreeAmt*enteredRate)/100;

				}}else{
					RetirmentTaxFreeAmt+=RSAgrouwthTax;
					RetirmentTaxFreeAmt+=(RetirmentTaxFreeAmt*enteredRate)/100;
				}     
				this.RetContTotTax1[i]=new Intl.NumberFormat().format(parseInt(RetirmentTaxFreeAmt));
			}    
		}
	}

	/*------Tax Free 1 End Here------*/

	/*------------Tax Deduct 1 Start Here------*/   

	RSATaxDeductionAdd(){
		++this.totalCount;
		this.RSATaxDeductionControls++;
		this.RetirmentDeductDeff.push();
		this.Ded_Income.push();
		this.RetirmentDeductAmt.push(0);
		this.RetirmentDeductA.push(0);

		this.RSAgrouwthDed.push(0);
		this.RSAgrouwthD.push(0);

		this.RetContTotDed1.push(0);
		this.RSAgrowthPercntDed1.push(0);
		this.rateDed.push(0);
		this.RetirmentControlsDed.push(0);
	}
	RSATaxDeductionRem(){
		if( this.RSATaxDeductionControls>1)
			this.RSATaxDeductionControls--;
		this.RetirmentDeductDeff.splice(this.RSATaxDeductionControls,1);
		this.Ded_Income.splice(this.RSATaxDeductionControls,1); 
		this.RetirmentDeductAmt.splice(this.RSATaxDeductionControls,1);
		this.RetirmentDeductA.splice(this.RSATaxDeductionControls,1);

		this.RSAgrouwthDed.splice(this.RSATaxDeductionControls,1);
		this.RSAgrouwthD.splice(this.RSATaxDeductionControls,1);
		this.RetContTotDed1.splice(this.RSATaxDeductionControls,1);
		this.RSAgrowthPercntDed1.splice(this.RSATaxDeductionControls,1);
		this.rateDed.splice(this.RSATaxDeductionControls,1);
		this.RetirmentControlsDed.splice(this.RSATaxDeductionControls,1);
	}

	RSAAmountDed1(element){
		if(element.target.value != ''){ 
			let RSAgrouwthDed = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
			element.target.value = RSAgrouwthDed; 
		}
	}

	RSATaxDedCol(){
		this.showRsaDedFree= false;
		this.Showtotal2= true;
		let total:number=0;
		for(var i in this.RetirmentDeductAmt) {
			let fetchAmt:any='';
			fetchAmt=this.RetirmentDeductAmt[i];
			if(fetchAmt!=0)

				total += parseInt(fetchAmt.replace(/,/g, ''));

		}
		this.Deduct = new Intl.NumberFormat().format(total);

		this.ShowMiddletotalDed = true;

		let total1 :number=0;
		for(var i in this.RSAgrouwthDed) {
			let fetch12: any = '';
			fetch12 = this.RSAgrouwthDed[i];

			if(fetch12!=0)
				total1 += parseInt(fetch12.replace(/,/g, ''));
		}
		this.ShowMiddleDed1 = new Intl.NumberFormat().format(total1);

		let total2 :number = 0;
		for(var i in this.RetContTotDed1){
			let fetch13:any = '';
			fetch13 = this.RetContTotDed1[i];
			if(fetch13!=0)
				total2  += parseInt(fetch13.replace(/,/g, ''));
		}
		this.showSide = new Intl.NumberFormat().format(total2);
		this.ShowSideDed1 = true;

		let total2Ret :number = 0;
		for(var i in this.RetirmentControlsDed){
			let fetch13:any = '';
			fetch13 = this.RetirmentControlsDed[i];
			if(fetch13!=0)
				total2Ret  += parseInt(fetch13.replace(/,/g, ''));
		}
		this.showAmountDed = new Intl.NumberFormat().format(total2Ret);

		this.ShowtotalRetDed = true;

	}
	RSATaxDedExp(){
		this.showRsaDedFree= true;
		this.Showtotal2= false;
		for(var i in this.RetirmentDeductAmt) {
			if(this.RetirmentDeductAmt[i]!=0)
				this.RetirmentDeductAmt[i]= new Intl.NumberFormat().format(parseInt(this.RetirmentDeductAmt[i].replace(/,/g, '')));
			else
				this.RetirmentDeductAmt[i]= 0;

		}

		this.ShowMiddletotalDed = false;

		for(var i in this.RSAgrouwthDed) {
			if(this.RSAgrouwthDed[i]!=0)
				this.RSAgrouwthDed[i]= new Intl.NumberFormat().format(parseInt(this.RSAgrouwthDed[i].replace(/,/g, '')));
			else
				this.RSAgrouwthDed[i]= 0;

		}

		this.ShowSideDed1 = false;
		for(var i in this.RetContTotDed1) {
			if(this.RetContTotDed1[i]!=0)
				this.RetContTotDed1[i]= new Intl.NumberFormat().format(parseInt(this.RetContTotDed1[i].replace(/,/g, '')));
			else
				this.RetContTotDed1[i]= 0;

		}
		this.ShowtotalRetDed = false;
		for(var i in this.RetirmentControlsDed) {
			if(this.RetirmentControlsDed[i]!=0)
				this.RetirmentControlsDed[i]= new Intl.NumberFormat().format(parseInt(this.RetirmentControlsDed[i].replace(/,/g, '')));
			else
				this.RetirmentControlsDed[i]= 0;

		}
	}

	RSAAmountCalDed(i){

		if(this.RetirmentDeductAmt[i] && this.RSAgrowthPercntDed1[i]){
			console.log(this.RetirmentDeductAmt[i]);
			console.log(this.RSAgrowthPercntDed1[i]);
			if(this.RSAgrouwthDed[i] == 0){
				this.RSgrwthD = 0;
				let RSAgrouwthDed = this.RSgrwthD;
			}else{
				this.RSgrwthD = parseInt(this.RSAgrouwthDed[i].replace(/,/g, ''));
				let RSAgrouwthDed = this.RSgrwthD;
			}
			let RSAgrouwthDed = this.RSgrwthD;

			let RetirmentDeductAmt:any =0;

			if(this.RetirmentDeductAmt[i]!=0)
				RetirmentDeductAmt= parseInt(this.RetirmentDeductAmt[i].replace(/,/g, '')); 

			let enteredRate=this.RSAgrowthPercntDed1[i];
			let diff = this.showAge;
			console.log(diff);
  //let diff = this.stop[i] - this.start[i];
	//let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
	
	if(RetirmentDeductAmt>=0 || RSAgrouwthDed>=0 || enteredRate>=0){
		if(diff){
			for(var l=0;l<diff;l++){
				RetirmentDeductAmt+=RSAgrouwthDed;
				RetirmentDeductAmt+=(RetirmentDeductAmt*enteredRate)/100;
				console.log(RetirmentDeductAmt);
			}}else{
				RetirmentDeductAmt+=RSAgrouwthDed;
				RetirmentDeductAmt+=(RetirmentDeductAmt*enteredRate)/100;
				console.log(RetirmentDeductAmt);
			}     
			this.RetContTotDed1[i]=new Intl.NumberFormat().format(parseInt(RetirmentDeductAmt));
		}    
	}
}

/*---------------Tax Deduct 1 End Here---------*/

/*-----------Tax Deffered 2 Start Here-----------*/
RSA2taxDefAdd(){
	++this.totalCount;
	this.RSA2taxdefControls++;
	this.Retirment2ndControlsDeff.push();
	this.Retirment2ndControlsAmt.push(0);
	this.Retirment2ndControlsA.push(0);

	this.RSAgrouwthAmount1.push(0);
	this.RSAgrouwthAmo1.push(0);

	this.RSAgrowthPercnt1.push(0);
	this.rateRet1.push(0);
	this.RetirmentControlsDeff1.push(0);
	this.RetContTotCalAmt1.push(0);
	this.RSAgrouwthAm2.push(0);
}

RSA2taxDefRem(){
	if(this.RSA2taxdefControls>1)
		this.RSA2taxdefControls--;
	this.Retirment2ndControlsDeff.splice(this.RSA2taxdefControls,1);
	this.Retirment2ndControlsAmt.splice(this.RSA2taxdefControls,1);
	this.Retirment2ndControlsA.splice(this.RSA2taxdefControls,1);

	this.RSAgrouwthAmount1.splice(this.RSA2taxdefControls,1);
	this.RSAgrouwthAmo1.splice(this.RSA2taxdefControls,1);

	this.RSAgrowthPercnt1.splice(this.RSA2taxdefControls,1);
	this.rateRet1.splice(this.RSA2taxdefControls,1);
	this.RetirmentControlsDeff1.splice(this.RSA2taxdefControls,1);  
	this.RetContTotCalAmt1.splice(this.RSA2taxdefControls,1);   
	this.RSAgrouwthAm2.splice(this.RSA2taxdefControls,1);
}

RetSavAccCol(){
	this.showRetSavAcc= false;
	let total:number=0;
	for(var i in this.Retirment2ndControlsAmt) {
		let fetchAmt:any='';
		fetchAmt=this.Retirment2ndControlsAmt[i];
		if(fetchAmt!=0)
			total += parseInt(fetchAmt.replace(/,/g, ''));
	}

	this.Retire1 = new Intl.NumberFormat().format(total);
	this.Showtotal3 = true;
	this.showRetdef= false;

	let total1 :number=0;
	for(var i in this.RSAgrouwthAmount1) {
		let fetch12: any = '';
		fetch12 = this.RSAgrouwthAmount1[i];

		if(fetch12!=0)
			total1 += parseInt(fetch12.replace(/,/g, ''));
	}
	this.ShowMiddle1 = new Intl.NumberFormat().format(total1);
	this.ShowMiddletotal1 = true;

	let total2 :number=0;
	for(var i in this.RetContTotCalAmt1){
		let fetch13:any = '';
		fetch13 = this.RetContTotCalAmt1[i];
		if(fetch13!=0)
			total2  += parseInt(fetch13.replace(/,/g, ''));
	}
	this.showSide = new Intl.NumberFormat().format(total2);
	this.ShowSideDeff2 = true;
	this.showRet = false;



	let total3Ret :number=0;
	for(var i in this.RetirmentControlsDeffTax1){
		let fetch12:any = '';
		fetch12 = this.RetirmentControlsDeffTax1[i];
		if(fetch12!=0)
			total3Ret  += parseInt(fetch12.replace(/,/g, ''));
	}
	this.showAmountRet = new Intl.NumberFormat().format(total3Ret);

	this.Showtotal32 = true;
	this.showRetd =false;


}

RetSavAccExp(){
	this.showRetSavAcc = true;
	this.Showtotal3 = false;
	for(var i in this.Retirment2ndControlsAmt) {
		if(this.Retirment2ndControlsAmt[i]!=0)
			this.Retirment2ndControlsAmt[i]= new Intl.NumberFormat().format(parseInt(this.Retirment2ndControlsAmt[i].replace(/,/g, '')));
		else
			this.Retirment2ndControlsAmt[i]= 0;
	}
	this.showRetdef = true;
	this.ShowMiddletotal1 = false;
	for(var i in this.RSAgrouwthAmount1) {
		if(this.RSAgrouwthAmount1[i]!=0)
			this.RSAgrouwthAmount1[i]= new Intl.NumberFormat().format(parseInt(this.RSAgrouwthAmount1[i].replace(/,/g, '')));
		else
			this.RSAgrouwthAmount1[i]= 0;

	}
	this.ShowSideDeff2 = false;
	this.showRet = true;

	for(var i in this.RetContTotCalAmt1) {
		if(this.RetContTotCalAmt1[i]!=0)
			this.RetContTotCalAmt1[i]= new Intl.NumberFormat().format(parseInt(this.RetContTotCalAmt1[i].replace(/,/g, '')));
		else
			this.RetContTotCalAmt1[i]= 0;

	}



	this.Showtotal32 = false;
	this.showRetd = true;

	for(var i in this.RetirmentControlsDeffTax1) {
		if(this.RetirmentControlsDeffTax1[i]!=0)
			this.RetirmentControlsDeffTax1[i]= new Intl.NumberFormat().format(parseInt(this.RetirmentControlsDeffTax1[i].replace(/,/g, '')));
		else
			this.RetirmentControlsDeffTax1[i]= 0;

	}
	
}

RSAAmount1(element){
	if(element.target.value != ''){
		let RSAgrowthAmout1 = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
		element.target.value = RSAgrowthAmout1; 
	}
}

RSAAmountCalComplete1(i){
	console.log(this.Retirment2ndControlsAmt[i]);
	console.log(this.RSAgrowthPercnt1[i]);
	if(this.Retirment2ndControlsAmt[i] && this.RSAgrowthPercnt1[i]){
		if(this.RSAgrouwthAmount1[i] == 0){
			this.RSgrwth1 = 0;
			let RSAgrowthAmout1 = this.RSgrwth1;
		}else{
			this.RSgrwth1 = parseInt(this.RSAgrouwthAmount1[i].replace(/,/g, ''));
			let RSAgrowthAmout1 = this.RSgrwth1;
		}
		let RSAgrowthAmout1 = this.RSgrwth1;

		let Retirment2ndControlsAmt:any =0;

		if(this.Retirment2ndControlsAmt[i]!=0)
			Retirment2ndControlsAmt= parseInt(this.Retirment2ndControlsAmt[i].replace(/,/g, '')); 

		let enteredRate=this.RSAgrowthPercnt1[i];
		let diff = this.showAge; 
		console.log(diff);  
		if(Retirment2ndControlsAmt>=0 || RSAgrowthAmout1>=0 || enteredRate>=0){
			if(diff){
				for(var l=0;l<diff;l++){
					Retirment2ndControlsAmt+=RSAgrowthAmout1;
					Retirment2ndControlsAmt+=(Retirment2ndControlsAmt*enteredRate)/100;
					console.log(Retirment2ndControlsAmt);
				}}
				else{
					Retirment2ndControlsAmt+=RSAgrowthAmout1;
					Retirment2ndControlsAmt+=(Retirment2ndControlsAmt*enteredRate)/100;
				}     
				this.RetContTotCalAmt1[i]=new Intl.NumberFormat().format(parseInt(Retirment2ndControlsAmt));
				console.log(this.RetContTotCalAmt1[i]);
			}    
		}
	}


	/*-----------Tax Deffered 2 End Here-----------*/
	/*-----------Tax Free 2 Start Here-----------*/
	RSA2TaxFreeAdd(){
		++this.totalCount;
		this.RSA2taxFreeControls++;
		this.RetirmentTaxFree2ndDeff.push();
		this.RetirmentTaxFree2ndAmt.push(0);
		//this.RetirmentTaxFree2ndDeff.splice(this.RSA2taxFreeControls,1);
		this.RSAgrouwthTax1.push(0);
		this.RetContTotTax2.push(0);
		this.rateTaxF1.push(0);
		this.RetirmentControlsTaxFree1.push(0);
		this.RSAgrouwthTax2.push(0);




	}
	RSA2TaxFreeRem(){
		if(this.RSA2taxFreeControls>1)
			this.RSA2taxFreeControls--;
		this.RetirmentTaxFree2ndDeff.splice(this.RSA2taxFreeControls,1);
		this.RSAgrouwthTax1.splice(this.RSA2taxFreeControls,1);
		this.RSAgrouwthTax2.splice(this.RSA2taxFreeControls,1);
		this.RetContTotTax2.splice(this.RSA2taxFreeControls,1);
		this.rateTaxF1.splice(this.RSA2taxFreeControls,1);
		this.RetirmentControlsTaxFree1.splice(this.RSA2taxFreeControls,1);



	}

	RetSavAcc1Col(){
		this.showRetSavAcc1= false;
		this.Showtotal4 = true;
		let total:number=0;
		for(var i in this.RetirmentTaxFree2ndAmt) {
			let fetchAmt:any='';
			fetchAmt=this.RetirmentTaxFree2ndAmt[i];
			if(fetchAmt!=0)
				total += parseInt(fetchAmt.replace(/,/g, ''));

		}

		this.Retiretaxfree2 = new Intl.NumberFormat().format(total);

		this.ShowMiddletotalTax1 = true
		this.taxFMid1 = false;
		let total1 :number=0;
		for(var i in this.RSAgrouwthTax1) {
			let fetch12: any = '';
			fetch12 = this.RSAgrouwthTax1[i];
			if(fetch12!=0)
				total1 += parseInt(fetch12.replace(/,/g, ''));
		}
		this.ShowMiddleTax2 = new Intl.NumberFormat().format(total1);

		let total2 :number = 0;
		for(var i in this.RetContTotTax2){
			let fetch13:any = '';
			fetch13 = this.RetContTotTax2[i];
			if(fetch13!=0)
				total2  += parseInt(fetch13.replace(/,/g, ''));
		}
		this.showTax2 = new Intl.NumberFormat().format(total2);
		this.ShowSideTax2 = true;
		this.showTaxFree2 = false;

		let total3 :number = 0;
		for(var i in this.RetirmentControlsTaxFree1){
			let fetch4:any = '';
			fetch4 = this.RetirmentControlsTaxFree1[i];
			if(fetch4!=0)
				total3  += parseInt(fetch4.replace(/,/g, ''));
		}
		this.showATax = new Intl.NumberFormat().format(total3);
		this.Showtotalt2 = true;
		this.showRetTax2 = false;

	}
	RetSavAcc1Exp(){
		this.showRetSavAcc1 = true;
		this.Showtotal4 = false;
		for(var i in this.RetirmentTaxFree2ndAmt) {
			if(this.RetirmentTaxFree2ndAmt[i]!=0)
				this.RetirmentTaxFree2ndAmt[i]= new Intl.NumberFormat().format(parseInt(this.RetirmentTaxFree2ndAmt[i].replace(/,/g, '')));
			else
				this.RetirmentTaxFree2ndAmt[i]= 0;
		}

		this.ShowMiddletotalTax1 = false;
		this.taxFMid1 = true;
		for(var i in this.RSAgrouwthTax1) {
			if(this.RSAgrouwthTax1[i]!=0)
				this.RSAgrouwthTax1[i]= new Intl.NumberFormat().format(parseInt(this.RSAgrouwthTax1[i].replace(/,/g, '')));
			else
				this.RSAgrouwthTax1[i]= 0;

		}

		this.ShowSideTax2 = false;
		this.showTaxFree2 = true;
		for(var i in this.RetContTotTax2) {
			if(this.RetContTotTax2[i]!=0)
				this.RetContTotTax2[i]= new Intl.NumberFormat().format(parseInt(this.RetContTotTax2[i].replace(/,/g, '')));
			else
				this.RetContTotTax2[i]= 0;

		}

		this.Showtotalt2 = false;
		this.showRetTax2 = true;
		for(var i in this.RetirmentControlsTaxFree1) {
			if(this.RetirmentControlsTaxFree1[i]!=0)
				this.RetirmentControlsTaxFree1[i]= new Intl.NumberFormat().format(parseInt(this.RetirmentControlsTaxFree1[i].replace(/,/g, '')));
			else
				this.RetirmentControlsTaxFree1[i]= 0;

		}

	}

	RSAAmountTax2(element){
		if(element.target.value != ''){
			let RSAgrouwthTax1 = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
			element.target.value = RSAgrouwthTax1; 
		}
	}

	RSAAmountCalTax1(i){

		if(this.RetirmentTaxFree2ndAmt[i] && this.RSAgrowthPercntTex2[i]){
			console.log(this.RetirmentTaxFree2ndAmt[i]);
			console.log(this.RSAgrowthPercntTex2[i]);
			if(this.RSAgrouwthTax1[i] == 0){
				this.RSgrwthTx1 = 0;
				let RSAgrouwthTax1 = this.RSgrwthTx1;
			}else{
				this.RSgrwthTx1 = parseInt(this.RSAgrouwthTax1[i].replace(/,/g, ''));
				let RSAgrouwthTax1 = this.RSgrwthTx1;
			}
			let RSAgrouwthTax1 = this.RSgrwthTx1;
			console.log(RSAgrouwthTax1);

			let RetirmentTaxFree2ndAmt:any =0;

			if(this.RetirmentTaxFree2ndAmt[i]!=0)
				RetirmentTaxFree2ndAmt= parseInt(this.RetirmentTaxFree2ndAmt[i].replace(/,/g, '')); 

			let enteredRate=this.RSAgrowthPercntTex2[i];
			let diff = this.showAge;
 // let diff = this.stop[i] - this.start[i];
	//let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
	
	if(RetirmentTaxFree2ndAmt>=0 || RSAgrouwthTax1>=0 || enteredRate>=0){
		if(diff){
			for(var l=0;l<diff;l++){
				RetirmentTaxFree2ndAmt+=RSAgrouwthTax1;
				RetirmentTaxFree2ndAmt+=(RetirmentTaxFree2ndAmt*enteredRate)/100;

			}}else{
				RetirmentTaxFree2ndAmt+=RSAgrouwthTax1;
				RetirmentTaxFree2ndAmt+=(RetirmentTaxFree2ndAmt*enteredRate)/100;
			}     
			this.RetContTotTax2[i]=new Intl.NumberFormat().format(parseInt(RetirmentTaxFree2ndAmt));
			console.log(this.RetContTotTax2[i]);
		}    
	}

}


/*-----------Tax Free 2 End Here-----------*/
/*-----------Tax Deductible 2 Start Here-----------*/
RSA2TaxeductAdd(){
	++this.totalCount;
	this.RSA2taxDeductControls++;
	this.RetirmentTaxDeduct2ndDeff.push();
	this.RetirmentTaxDeduct2ndAmt.push(0);
	this.RetirmentTaxDeduct2ndA.push(0);

	this.RSAgrouwthDed1.push(0);
	this.RSAgrouwthDed2.push(0);
	this.RSAgrowthPercntDed2.push(0);
	this.RetContTotDed2.push(0);
	this.rateDed1.push(0);
	this.RetirmentControlsDed1.push(0);
}

RSA2TaxeductRem(){
	if(this.RSA2taxDeductControls>1)
		this.RSA2taxDeductControls--;
	this.RetirmentTaxDeduct2ndDeff.splice(this.RSA2taxDeductControls,1);
	this.RetirmentTaxDeduct2ndAmt.splice(this.RSA2taxDeductControls,1);
	this.RetirmentTaxDeduct2ndA.splice(this.RSA2taxDeductControls,1);

	this.RSAgrouwthDed1.splice(this.RSA2taxDeductControls,1);
	this.RSAgrouwthDed2.splice(this.RSA2taxDeductControls,1);
	this.RSAgrowthPercntDed2.splice(this.RSA2taxDeductControls,1);
	this.RetContTotDed2.splice(this.RSA2taxDeductControls,1);
	this.rateDed1.splice(this.RSA2taxDeductControls,1);
	this.RetirmentControlsDed1.splice(this.RSA2taxDeductControls,1);
}

RetSavAcc2Col(){
	this.showRetSavAcc2= false;
	this.Showtotal5 = true;
	let total:number=0;
	for(var i in this.RetirmentTaxDeduct2ndAmt) {
		let fetchAmt:any='';
		fetchAmt=this.RetirmentTaxDeduct2ndAmt[i];
		if(fetchAmt!=0)

			total += parseInt(fetchAmt.replace(/,/g, ''));
		
	}

	this.RetireDeduct2 = new Intl.NumberFormat().format(total);

	this.ShowMiddletotalDed1 = true;
	this.showmidded2 = false;
	let total1 :number=0;
	for(var i in this.RSAgrouwthDed1) {
		let fetch12: any = '';
		fetch12 = this.RSAgrouwthDed1[i];

		if(fetch12!=0)
			total1 += parseInt(fetch12.replace(/,/g, ''));
	}
	this.ShowMiddleDed2 = new Intl.NumberFormat().format(total1);

	this.showded2 = false;
	this.ShowSided2 = true;
	let total2 :number=0;
	for(var i in this.RetContTotDed2) {
		let fetch1: any = '';
		fetch1 = this.RetContTotDed2[i];

		if(fetch1!=0)
			total2 += parseInt(fetch1.replace(/,/g, ''));
	}
	this.showDeduct2 = new Intl.NumberFormat().format(total2);

	this.showRetDed2 = false;
	this.Showtotded = true;
	let total22 :number=0;
	for(var i in this.RetirmentControlsDed1) {
		let fetch12: any = '';
		fetch12 = this.RetirmentControlsDed1[i];

		if(fetch12!=0)
			total22 += parseInt(fetch12.replace(/,/g, ''));
	}
	this.showADed = new Intl.NumberFormat().format(total22);

}
RetSavAcc2Exp(){
	this.showRetSavAcc2 = true;
	this.Showtotal5 = false;
	for(var i in this.RetirmentTaxDeduct2ndAmt) {
		if(this.RetirmentTaxDeduct2ndAmt[i]!=0)
			this.RetirmentTaxDeduct2ndAmt[i]= new Intl.NumberFormat().format(parseInt(this.RetirmentTaxDeduct2ndAmt[i].replace(/,/g, '')));
		else
			this.RetirmentTaxDeduct2ndAmt[i]= 0;
	}

	this.ShowMiddletotalDed1 = false;
	this.showmidded2 = true;
	for(var i in this.RSAgrouwthDed1) {
		if(this.RSAgrouwthDed1[i]!=0)
			this.RSAgrouwthDed1[i]= new Intl.NumberFormat().format(parseInt(this.RSAgrouwthDed1[i].replace(/,/g, '')));
		else
			this.RSAgrouwthDed1[i]= 0;
		
	}


	this.showded2 = true;
	this.ShowSided2 = false;
	for(var i in this.RetContTotDed2) {
		if(this.RetContTotDed2[i]!=0)
			this.RetContTotDed2[i]= new Intl.NumberFormat().format(parseInt(this.RetContTotDed2[i].replace(/,/g, '')));
		else
			this.RetContTotDed2[i]= 0;

	}

	this.showRetDed2 = true;
	this.Showtotded = false;
	for(var i in this.RetirmentControlsDed1) {
		if(this.RetirmentControlsDed1[i]!=0)
			this.RetirmentControlsDed1[i]= new Intl.NumberFormat().format(parseInt(this.RetirmentControlsDed1[i].replace(/,/g, '')));
		else
			this.RetirmentControlsDed1[i]= 0;
		
	}
}

RSAAmountDed2(element){
	if(element.target.value != ''){
		let RSAgrouwthDed1 = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
		element.target.value = 	RSAgrouwthDed1; 
	}
}

RSAAmountCalDed1(i){
		console.log(this.RetirmentTaxDeduct2ndAmt[i]);
		console.log(this.RSAgrowthPercntDed1[i]);
		
	if(this.RetirmentTaxDeduct2ndAmt[i] && this.RSAgrowthPercntDed2[i]){
		if(this.RSAgrouwthDed1[i] == 0){
			this.RSgrwthD1 = 0;
			let RSAgrouwthDed1 = this.RSgrwthD1;
		}else{
			this.RSgrwthD1 = parseInt(this.RSAgrouwthDed1[i].replace(/,/g, ''));
			let RSAgrouwthDed1 = this.RSgrwthD1;
		}
		let RSAgrouwthDed1 = this.RSgrwthD1;

		let RetirmentTaxDeduct2ndAmt:any =0;

		if(this.RetirmentTaxDeduct2ndAmt[i]!=0)
			RetirmentTaxDeduct2ndAmt= parseInt(this.RetirmentTaxDeduct2ndAmt[i].replace(/,/g, '')); 

		let enteredRate=this.RSAgrowthPercntDed2[i];
		let diff = this.showAge;
		console.log(diff);
  //let diff = this.stop[i] - this.start[i];
	//let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
	
	if(RetirmentTaxDeduct2ndAmt>=0 || RSAgrouwthDed1>=0 || enteredRate>=0){
		if(diff){
			for(var l=0;l<diff;l++){
				RetirmentTaxDeduct2ndAmt+=RSAgrouwthDed1;
				RetirmentTaxDeduct2ndAmt+=(RetirmentTaxDeduct2ndAmt*enteredRate)/100;
				console.log(RetirmentTaxDeduct2ndAmt)
			}}else{  
				RetirmentTaxDeduct2ndAmt+=RSAgrouwthDed1;
				RetirmentTaxDeduct2ndAmt+=(RetirmentTaxDeduct2ndAmt*enteredRate)/100;
				console.log(RetirmentTaxDeduct2ndAmt)
			} 
			this.RetContTotDed2[i]=new Intl.NumberFormat().format(parseInt(RetirmentTaxDeduct2ndAmt));
			console.log(this.RetContTotDed2[i]);
		}    
	}

}


/*-----------Tax Deductible 2 End Here-----------*/
/*----------------Investment Start here-----------*/
InvestAndrewAdd(){
	++this.totalCount;
	this.InvestControls++;
	this.InvestControlsDeff.push('COMPANY NAME '+this.InvestControls);
	this.InvestControlsAmt.push(0);
	this.InvestControlsA.push(0);

	this.InvestAmount.push(0);
	this.InvestAmount1.push(0);
	this.RSAgrowthInvest.push(0);
	this.rateInvst1.push(0);
	this.InvesmentIncome.push(0);
	this.InvestAmt.push(0);
	


}
InvestAndrewRem(){
	if(this.InvestControls>1){
		this.InvestControls--;
		this.InvestControlsDeff.splice(this.InvestControls,1);
		this.InvestControlsAmt.splice(this.InvestControls,1);
		this.InvestControlsA.splice(this.InvestControls,1);

		this.InvestAmount.splice(this.InvestControls,1);
		this.RSAgrowthInvest.splice(this.InvestControls,1);
		this.rateInvst1.splice(this.InvestControls,1);
		this.InvesmentIncome.splice(this.InvestControls,1);
		this.InvestAmt.splice(this.InvestControls,1);
		

	}
}
InvestAndrewCol(){
	this.InvestAndrew= false;
	this.ShowtotalInvest = true;
	let total:number=0;
	for(var i in this.InvestControlsAmt) {

		let fetchVal:any=''; 
		fetchVal=this.InvestControlsAmt[i];
		if(fetchVal!=0)

			total += parseInt(fetchVal.replace(/,/g, ''));
		
	}
	this.InvestTotal = new Intl.NumberFormat().format(total);
	let total1:number=0;

	for(var i in this.InvestAmount) {
		let fetch12: any = '';
		fetch12 = this.InvestAmount[i];

		if(fetch12!=0)
			total1 += parseInt(fetch12.replace(/,/g, ''));
	}
	this.showMidInvest = new Intl.NumberFormat().format(total1);
	this.ShowInvest = true;
	this.ShowInvestment = false;

	let total2 :number = 0;
	for(var i in this.InvestAmt){
		let fetch13:any = '';
		fetch13 = this.InvestAmt[i];
		if(fetch13!=0)
			total2  += parseInt(fetch13.replace(/,/g, ''));
	}
	this.showSideInvst = new Intl.NumberFormat().format(total2);
	this.ShowSideInvest = true;
	this.shoeInvest2 = false;

	let total3 :number = 0;
	for(var i in this.InvesmentIncome){
		let fetch14:any = '';
		fetch14 = this.InvesmentIncome[i];
		if(fetch14!=0)
			total3  += parseInt(fetch14.replace(/,/g, ''));
	}
	this.showAmountIncome = new Intl.NumberFormat().format(total3);
	this.ShowtotalIncome = true;
	this.showRetIncome = false;
}

InvestAndrewExp(){
	this.InvestAndrew = true;
	this.ShowtotalInvest = false;
	for(var i in this.InvestControlsAmt) {
		if(this.InvestControlsAmt[i]!=0)
			this.InvestControlsAmt[i]= new Intl.NumberFormat().format(parseInt(this.InvestControlsAmt[i].replace(/,/g, '')));
		else
			this.InvestControlsAmt[i]= 0;
		
	}

	this.ShowInvestment = true;
	this.ShowInvest = false;
	for(var i in this.InvestAmount) {
		if(this.InvestAmount[i]!=0)
			this.InvestAmount[i]= new Intl.NumberFormat().format(parseInt(this.InvestAmount[i].replace(/,/g, '')));
		else
			this.InvestAmount[i]= 0;
		
	}

	this.shoeInvest2 = true;
	this.ShowSideInvest = false;
	for(var i in this.InvestAmt) {
		if(this.InvestAmt[i]!=0)
			this.InvestAmt[i]= new Intl.NumberFormat().format(parseInt(this.InvestAmt[i].replace(/,/g, '')));
		else
			this.InvestAmt[i]= 0;
		
	}

	this.showRetIncome = true;
	this.ShowtotalIncome = false;
	for(var i in this.InvesmentIncome) {
		if(this.InvesmentIncome[i]!=0)
			this.InvesmentIncome[i]= new Intl.NumberFormat().format(parseInt(this.InvesmentIncome[i].replace(/,/g, '')));
		else
			this.InvesmentIncome[i]= 0;
		
	}
}

Investmentt(element){
	if(element.target.value != ''){
		let InvestAmount = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
		element.target.value = InvestAmount; 
	}
}

InvestmentAmunt(i){
	if(this.InvestControlsAmt[i] && this.RSAgrowthInvest[i]){
		if(this.InvestAmount[i] == 0){
			this.IntGrowth = 0;
			let RSAgrowthAmout1 = this.IntGrowth;
		}else{
			this.IntGrowth = parseInt(this.InvestAmount[i].replace(/,/g, ''));
			let RSAgrowthAmout1 = this.IntGrowth;
		}
		let RSAgrowthAmout1 = this.IntGrowth;
		let InvestControlsAmt:any =0;

		if(this.InvestControlsAmt[i]!=0)
			InvestControlsAmt= parseInt(this.InvestControlsAmt[i].replace(/,/g, '')); 

		let enteredRate=this.RSAgrowthInvest[i];
		let diff = this.showAge;
  //let diff = this.stop[i] - this.start[i];
	//let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
	
	if(InvestControlsAmt>=0 || RSAgrowthAmout1>=0 || enteredRate>=0){
		if(diff){
			for(var l=0;l<diff;l++){
				InvestControlsAmt+=RSAgrowthAmout1;
				InvestControlsAmt+=(InvestControlsAmt*enteredRate)/100;
				
			}}else{
				InvestControlsAmt+=RSAgrowthAmout1;
				InvestControlsAmt+=(InvestControlsAmt*enteredRate)/100;
			}     
			this.InvestAmt[i]=new Intl.NumberFormat().format(parseInt(InvestControlsAmt));
		}    
	}

}

/*---------------Investment End Here---------------*/



RSAPensionCol(){
	this.showRsapensionFree= false;
}
RSAPensionExp(){
	this.showRsapensionFree = true;
}



RetSavAcc3Col(){
	this.showRetSavAcc3= false;
	this.Showtotal6 = true;

}
RetSavAcc3Exp(){
	this.showRetSavAcc3 = true;
	this.Showtotal6 = false;

}




transformAmount(element){
	if(element.target.value != ''){ 
		let RSAtaxDefAmount = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
		element.target.value = RSAtaxDefAmount; 
	}
}

RSAAmount(element){
	if(element.target.value != ''){ 
		let RSAgrowthAmout = new Intl.NumberFormat().format(parseInt(element.target.value.replace(/,/g, '')));  
		element.target.value = RSAgrowthAmout; 
	}
}

RSAAmountCalComplete(i){
	if(this.RetirmentControlsAmt[i] && this.RSAgrowthPercnt[i]){
		if(this.RSAgrouwthAmount[i] == 0){
			this.RSgrwth = 0;
			let RSAgrowthAmout = this.RSgrwth;
		}else{
			this.RSgrwth = parseInt(this.RSAgrouwthAmount[i].replace(/,/g, ''));
			let RSAgrowthAmout = this.RSgrwth;
		}
		let RSAgrowthAmout = this.RSgrwth;

		let RetirmentControlsAmt:any =0;

		if(this.RetirmentControlsAmt[i]!=0)
			RetirmentControlsAmt= parseInt(this.RetirmentControlsAmt[i].replace(/,/g, '')); 

		let enteredRate=this.RSAgrowthPercnt[i];
		let diff = this.showAge;         

		if(RetirmentControlsAmt>=0 || RSAgrowthAmout>=0 || enteredRate>=0){
			if(diff){
				for(var l=0;l<diff;l++){
					RetirmentControlsAmt+=RSAgrowthAmout;
					RetirmentControlsAmt+=(RetirmentControlsAmt*enteredRate)/100;

				}}else{
					RetirmentControlsAmt+=RSAgrowthAmout;
					RetirmentControlsAmt+=(RetirmentControlsAmt*enteredRate)/100;
				}       
				this.RetContTotCalAmt[i]=new Intl.NumberFormat().format(parseInt(RetirmentControlsAmt));
			}
		}
	}


	funcColRetirement(){
		this.showRetire=false;

		let total:number=0;
		for(var i in this.RetirmentControlsAmt) {
			let fetch11:any = '';
			fetch11=this.RetirmentControlsAmt[i];
			if(fetch11!=0)
				total += parseInt(fetch11.replace(/,/g, ''));               
		}

		for(var i in this.RetirmentControlsA) {
			let fetch1:any = '';
			fetch1=this.RetirmentControlsA[i];
			if(fetch1!=0)
				total += parseInt(fetch1.replace(/,/g, ''));                
		}
		this.RetireTotal = new Intl.NumberFormat().format(total);
		this.Showtotal = true;
		this.showRetire1=false;

		let total1 :number=0;
		for(var i in this.RSAgrouwthAmount) {
			let fetch12: any = '';
			fetch12 = this.RSAgrouwthAmount[i];

			if(fetch12!=0)
				total1 += parseInt(fetch12.replace(/,/g, ''));
		}
		this.ShowMiddle = new Intl.NumberFormat().format(total1);
		this.ShowMiddletotal = true;

		this.showRetire2 = false;

		let total2 :number = 0;
		for(var i in this.RetContTotCalAmt){
			let fetch13:any = '';
			fetch13 = this.RetContTotCalAmt[i];
			if(fetch13!=0)
				total2  += parseInt(fetch13.replace(/,/g, ''));
		}
		this.showSide = new Intl.NumberFormat().format(total2);
		this.ShowSidetotal = true;

		let total2Ret :number = 0;
		for(var i in this.RetirmentControlsDeffTax){
			let fetch13:any = '';
			fetch13 = this.RetirmentControlsDeffTax[i];
			if(fetch13!=0)
				total2Ret  += parseInt(fetch13.replace(/,/g, ''));
		}
		this.showAmountRetired = new Intl.NumberFormat().format(total2Ret);

		this.ShowtotalRetired = true;
		this.showRetired =false;
	}
	funcExpRetirement(){

		this.showRetired =true;
		this.showRetire=true;
		this.showRetire2=true;
	//this.showRetire1=true;
	this.showRetire1=true;

	this.Showtotal = false;
	this.ShowMiddletotal = false;
	this.ShowSidetotal = false;
	this.ShowtotalRetired = false
	this.amntTest = true;
	for(var i in this.RetirmentControlsAmt) {
		if(this.RetirmentControlsAmt[i]!=0){
			this.RetirmentControlsAmt[i]= new Intl.NumberFormat().format(parseInt(this.RetirmentControlsAmt[i].replace(/,/g, '')));
		}else{
			this.RetirmentControlsAmt[i]= 0;
		}}

		for(var i in this.RetirmentControlsA) {
			if(this.RetirmentControlsA[i]!=0)
				this.RetirmentControlsA[i]= new Intl.NumberFormat().format(parseInt(this.RetirmentControlsA[i].replace(/,/g, '')));
			else
				this.RetirmentControlsA[i]= 0;
			
		}

		for(var i in this.RSAgrouwthAmount) {
			if(this.RSAgrouwthAmount[i]!=0){
				this.RSAgrouwthAmount[i]= new Intl.NumberFormat().format(parseInt(this.RSAgrouwthAmount[i].replace(/,/g, '')));
				
			} else{
				this.RSAgrouwthAmount[i]= 0;
			}

		}

		for(var i in this.RetContTotCalAmt) {
			if(this.RetContTotCalAmt[i]!=0)
				this.RetContTotCalAmt[i]= new Intl.NumberFormat().format(parseInt(this.RetContTotCalAmt[i].replace(/,/g, '')));
			else
				this.RetContTotCalAmt[i]= 0;
			
		}

		for(var i in this.RetirmentControlsDeffTax) {
			if(this.RetirmentControlsDeffTax[i]!=0)
				this.RetirmentControlsDeffTax[i]= new Intl.NumberFormat().format(parseInt(this.RetirmentControlsDeffTax[i].replace(/,/g, '')));
			else
				this.RetirmentControlsDeffTax[i]= 0;
			
		}


	}

	funcAddRetirement(){
		++this.totalCount;
		this.RetirmentControls++;
		this.rateRet.push(0);
		this.RetirmentIncome.push(); 
		this.RetirmentControlsDeff.push();
		this.RetirmentControlsAmt.push(0);
		this.RetirmentControlsA.push(0);
		this.RetirmentControlsDeffTax.push(0);
		this.RSAgrowthPercnt.push(0);
		this.RetContTotCalAmt.push(0); 
		this.RSAgrouwthAmount.push(0); 
		this.RSAgrouwthAmo.push(0); 
		
	}
	funcRemoveRetirement(){
		if(this.RetirmentControls>1){
			this.RetirmentControls--;  
			this.rateRet.splice(this.RetirmentControls,1);
			this.RetirmentControlsDeff.splice(this.RetirmentControls,1);
			this.RetirmentControlsDeffTax.splice(this.RetirmentControls,1);
			this.RetirmentControlsAmt.splice(this.RetirmentControls,1);
			this.RetirmentControlsA.splice(this.RetirmentControls,1);

			this.RSAgrowthPercnt.splice(this.RetirmentControls,1)
			this.RetContTotCalAmt.splice(this.RetirmentControls,1);
			this.RSAgrouwthAmount.splice(this.RetirmentControls,1);
			this.RSAgrouwthAmo.splice(this.RetirmentControls,1);
			this.RetirmentIncome.splice(this.RetirmentControls,1);
			

		}
	}
	/*********************************/

	loopFromNumber(number){
		var items: number[] = [];
		for(var i = 1; i <= number; i++){
			items.push(i);
		}
		return items;
	}
	
	getyear($evt,index){
	//let IntName = this.txtName[i].nativeElement.value;
	this.first = this.start[index]
	this.last = this.stop[index] || this.start[index+1]

	this.showAge = this.stop[index] || this.start[index+1] - this.start[0];
	this.showdiff1 = this.stop[index] || this.start[index+1] - this.start[index];
	this.showdiff[index] = this.stop1[index] || this.start1[index+1]- this.start1[index];
	 //this.showdiff[index] = this.stop[index] - this.start[index];
   /*  let sName = this.searchName;
	if(sName){
		this.showdiff[index] = this.stop[index] - this.start[index];
	}else{
		*/
		this.showdiff[index] = this.stop[index] || this.start[index+1] - this.start[index];
   // }
   
}

retireLeft(){
	for(var index=0;index<=this.totalCount;index++){
		if(this.RetirmentControlsA[index]){
			let RetirmentControlsA = this.RetirmentControlsA[index].replace(/,/g, '');
			let rateRet = this.rateRet[index];
			let lastTax:any= (RetirmentControlsA*rateRet)/100;
			
			this.RetirmentControlsDeffTax[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 
		}
		
		if(this.RetirmentTaxFreeA[index]){
			let RetirmentTaxFreeA = this.RetirmentTaxFreeA[index].replace(/,/g, '');
			let rateTaxF = this.rateTaxF[index];
			let lastTax:any= (RetirmentTaxFreeA*rateTaxF)/100;

			
			this.RetirmentControlsTaxFree[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 
		} 

		if(this.RetirmentDeductA[index]){
			let RetirmentDeductA = this.RetirmentDeductA[index].replace(/,/g, '');
			let rateDed = this.rateDed[index];
			let lastTax:any= (RetirmentDeductA*rateDed)/100;
			

			this.RetirmentControlsDed[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 
		}
		if(this.RetContTotCalAmt1[index]){
			let RetContTotCalAmt1 = this.RetContTotCalAmt1[index].replace(/,/g, '');
			let rateRet1 = this.rateRet1[index];
			let lastTax:any= (RetContTotCalAmt1*rateRet1)/100;
			
			
			this.RetirmentControlsDeffTax1[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 
		}
		if(this.RetContTotTax2[index]){
			let RetContTotTax2 = this.RetContTotTax2[index].replace(/,/g, '');
			let rateTaxF1 = this.rateTaxF1[index];
			let lastTax:any= (RetContTotTax2*rateTaxF1)/100;
			this.RetirmentControlsTaxFree1[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 
		}
		if(this.RetContTotDed2[index]){
			let RetContTotDed2 = this.RetContTotDed2[index].replace(/,/g, '');
			//console.log(this.RetContTotDed2[index]);
			let rateDed1 = this.rateDed1[index];
			let lastTax:any= (RetContTotDed2*rateDed1)/100;
			//console.log(lastTax);
			
			this.RetirmentControlsDed1[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 
		}
		if(this.InvestAmt[index]){
			let InvestAmt = this.InvestAmt[index].replace(/,/g, '');
			let rateInvst1 = this.rateInvst1[index];
			let lastTax:any= (InvestAmt*rateInvst1)/100;
			
			this.InvesmentIncome[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 
		}
	}
}
funcCalPrjAmt($evt,index){
	let enteredRate=$evt.target.value;

	if(this.RetirmentControlsA[index] == 0){
		let RetirmentControlsAmt = this.RetirmentControlsAmt[index].replace(/,/g, '');
		let lastTax:any= (RetirmentControlsAmt*enteredRate)/100;
		
		this.RetirmentControlsDeffTax[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 
		
	}else{
		let RetirmentControlsA = this.RetirmentControlsA[index].replace(/,/g, '');
		let lastTax:any= (RetirmentControlsA*enteredRate)/100;
		
		this.RetirmentControlsDeffTax[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 
	}
}

funcCalPrjAmt1($evt,index){
	let enteredRate=$evt.target.value;
	if(this.RetirmentTaxFreeA[index] == 0){
		let RetirmentTaxFreeAmt = this.RetirmentTaxFreeAmt[index].replace(/,/g, '');
		let lastTax:any= (RetirmentTaxFreeAmt*enteredRate)/100;
		
		this.RetirmentControlsTaxFree[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 

	}else{
		let RetirmentTaxFreeA = this.RetirmentTaxFreeA[index].replace(/,/g, '');
		let lastTax:any= (RetirmentTaxFreeA*enteredRate)/100;
		
		this.RetirmentControlsTaxFree[index]=new Intl.NumberFormat().format(parseInt(lastTax));
	}
}

funcCalPrjAmt2($evt,index){
	let enteredRate=$evt.target.value;
	if(this.RetirmentDeductA[index] == 0){
		let RetirmentDeductAmt = this.RetirmentDeductAmt[index].replace(/,/g, '');
		let lastTax:any= (RetirmentDeductAmt*enteredRate)/100;
		
		this.RetirmentControlsDed[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 
	}else{
		let RetirmentDeductA = this.RetirmentDeductA[index].replace(/,/g, '');
		let lastTax:any= (RetirmentDeductA*enteredRate)/100;
		
		this.RetirmentControlsDed[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 
	}    
}

funcCalPrj($evt,index){
	let enteredRate=$evt.target.value;

	if(this.RetContTotCalAmt1[index]){
		let RetContTotCalAmt1 = this.RetContTotCalAmt1[index].replace(/,/g, '');
		let lastTax:any= (RetContTotCalAmt1*enteredRate)/100;
		
		
		this.RetirmentControlsDeffTax1[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 

	} 

}

funcCalPrj1($evt,index){
	let enteredRate=$evt.target.value;
	if(this.RetContTotTax2[index]){
		let RetContTotTax2 = this.RetContTotTax2[index].replace(/,/g, '');
		let lastTax:any= (RetContTotTax2*enteredRate)/100;
		
		this.RetirmentControlsTaxFree1[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 
	} 
}

funcCalPrj2($evt,index){
	let enteredRate=$evt.target.value;

	if(this.RetContTotDed2[index]){
		let RetContTotDed2 = this.RetContTotDed2[index].replace(/,/g, '');
		let lastTax:any= (RetContTotDed2*enteredRate)/100;
		

		this.RetirmentControlsDed1[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 

	} 

}

funcCalInvest($evt,index){
	let enteredRate=$evt.target.value;
	
	if(this.InvestAmt[index]){
		let InvestAmt = this.InvestAmt[index].replace(/,/g, '');
		let lastTax:any= (InvestAmt*enteredRate)/100;

		this.InvesmentIncome[index]=new Intl.NumberFormat().format(parseInt(lastTax)); 
		
	}
}


ShowName(i){
	this.showNamee = true;
	this.showName = this.NameControl[i];
}

AddAmount(i){
	const user = this.auth.getUser();
	this.globalName = user.Admin_Name;
	if(!this.globalName){
		Swal.fire({
			position: 'center',
			icon: 'error',
			title: 'Client Name Is Mendatory',
			showConfirmButton: true,
		 // timer: 2000
		})   
	}else{

		let adminId = user._id;
		let value = i;

		this.indexValue = value;
		this.name =  this.NameControl[i];
		this.startAge = this.start[i];
		this.stopAge = this.stop[i] || this.start[i+1];
		this.showPercent = this.showdiff[i];
		this.clientName = this.clientt[0];
		this.spouseName = this.spousee[0];

		let data = 50;
		if(this.name && this.startAge && this.stopAge){

			if(this.SavingControlsAmt[0]){
				this.formattedDate= new Date()
				this.parmSav['Date'] = this.formattedDate
				this.parmSav['Admin_Name'] = this.globalName;
				this.parmSav['Admin_Id'] = adminId;
				this.parmSav['Index_value'] = this.indexValue;
				this.parmSav['Name'] = this.name;
				this.parmSav['Start_age'] = this.startAge;
				this.parmSav['Stop_age'] = this.stopAge;
				this.parmSav['Client_Name']= this.clientName;
				this.parmSav['Spouse_Name']= this.spouseName;
				let SavingArray = []
				for(var j=0;j<data;j++){
					if(this.SavingControlsAmt[j]){
						if(this.SavAmount[j] == 0  || this.SavAmount[j] == ''){
							this.SavGrowth = 0;
							let savingA = this.SavGrowth;
						}else{
							this.SavGrowth = parseInt(this.SavAmount[j].replace(/,/g, ''));
							let savingA = this.SavGrowth;
						}
						let savingA = this.SavGrowth;
						let SavingControlsAmt:any =0;

						if(this.SavingControlsAmt[j]!=0)
							SavingControlsAmt= parseInt(this.SavingControlsAmt[j].replace(/,/g, '')); 
						let enteredRate= this.SavingSidbar[j];

						let id = this.User_id;
						this.parmSav['User_id'] = this.User_id;

						SavingArray.push({
							'input1_saving':this.SavingControlsDeff[j],
							'Years' : this.showPercent,
							'MidAmount' : this.SavAmount[j],
							'Amountt' : this.SavingControlsAmt[j],
							'FinalAmount' : this.TotSav[j],
							'FinalPercnt': this.SavingSidbar[j],
						})
						//console.log(SavingArray);
						this.parmSav['Sav_Data'] = JSON.stringify(SavingArray) ;
					}}
					let parms1 = {
						'Index_value': this.indexValue,
						'Name' : this.name,
						'Admin_Id': adminId,
					}

					this.loader = true;
					this._Sav.getCurrentA(parms1).subscribe(res =>{
						if(!res){
							this._Sav.addAmount(this.parmSav).subscribe(res =>{
								console.log(res);
								let data:any = res;
								if(data.saving == "saving has been added successfully")
								{
									this.auth.getRes(data.saving);
								}
								this.loader = false;

								Swal.fire({
									position: 'center',
									icon: 'success',
									title: 'Data has been added successfully.',
									showConfirmButton: false,
									timer: 1200
								})
								/*if(sessionStorage.getItem('dataC')!=null)
                                    {
                                    	this.router.navigate(['/edit-client']);
                                    }*/
							}); 

						}else{

							let Name1 = {
								'Name' : this.name,
								'Admin_Id': adminId,
							}
							if(this.parmSav['Sav_Data'] != undefined){
								this._Sav.updateA(this.parmSav,Name1).subscribe(res =>{
									this.loader = false;

									Swal.fire({
										position: 'center',
										icon: 'success',
										title: 'Data has been updated successfully.',
										showConfirmButton: false,
										timer: 1200
									})
								});
							}else{
								this.loader = false;
								Swal.fire({
									position: 'center',
									icon: 'error',
									title: 'Data has been not updated',
									showConfirmButton: true,
									timer: 1200
								})
							}
						}
					});
				}
				
				if(this.CollageControlsAmt[0]){
					this.formattedDate= new Date()
					this.parmCol['Date'] = this.formattedDate
					this.parmCol['Admin_Name'] = this.globalName;
					this.parmCol['Admin_Id'] = adminId;
					this.parmCol['Index_value'] = this.indexValue;
					this.parmCol['Name'] = this.name;
					this.parmCol['Start_age'] = this.startAge;
					this.parmCol['Stop_age'] = this.stopAge;
					this.parmCol['Client_Name']= this.clientName;
					this.parmCol['Spouse_Name']= this.spouseName;
					let CollageArray = []
					for(var j=0;j<data;j++){
						if(this.CollageControlsAmt[j]){
							if(this.ColAmount[j] == 0  || this.ColAmount[j] == ''){
								this.ColGrowth = 0;
								let collageA = this.ColGrowth;
							}else{
								this.ColGrowth = parseInt(this.ColAmount[j].replace(/,/g, ''));
								let collageA = this.ColGrowth;
							}
							let collageA = this.ColGrowth;
							let CollageControlsAmt:any =0;
							if(this.CollageControlsAmt[j]!=0)
								CollageControlsAmt= parseInt(this.CollageControlsAmt[j].replace(/,/g, ''));
							let id = this.User_id;
							this.parmCol['User_id'] = this.User_id;
							CollageArray.push({
								'Collage_name':this.CollageControlsDeff[j],
								'Years' : this.showPercent,
								'FinalPercnt': this.CollageSidebar[j],
								'MidAmount' : this.ColAmount[j],
								'Amountt' : this.CollageControlsAmt[j],
								'FinalAmount' : this.TotCol[j]
							})
                                //console.log(param);
                                this.parmCol['col_Data'] = JSON.stringify(CollageArray) ;
                            }}
                            let parms1 = {
                            	'Index_value': this.indexValue,
                            	'Name' : this.name,
                            	'Admin_Id': adminId,
                            }
                            this.loader = true;
                            this._Sav.getCurrentC(parms1).subscribe(res =>{
                            	//console.log(res);
                                    //this.loader= false;
                                    if(!res){
                                    	this._amunt.colAmount(this.parmCol).subscribe(res =>{
                                    		this.loader = false;
                                    		//console.log(res);
                                    		let data:any = res;
                                    		if(data.Collage == "Collage has been added successfully")
											{
												this.auth.getRes(data.Collage);
											}
                                    		Swal.fire({
                                    			position: 'center',
                                    			icon: 'success',
                                    			title: 'Data has been added successfully.',
                                    			showConfirmButton: false,
                                    			timer: 1200
                                    		})
                                    		/*if(sessionStorage.getItem('dataC')!=null)
                                    		{
                                    			this.router.navigate(['/edit-client']);
                                    		}*/
                                    		
                                    	});
                                    }
                                    else{
                                    	let Name1 = {
                                    		'Name' : this.name,
                                    		'Admin_Id': adminId,
                                    	}
                                    	if(this.parmCol['col_Data'] != undefined){
                                    		this._Sav.updateC(this.parmCol,Name1).subscribe(res =>{
                                    			this.loader = false;
                                    			Swal.fire({
                                    				position: 'center',
                                    				icon: 'success',
                                    				title: 'Data has been updated successfully',
                                    				showConfirmButton: false,
                                    				timer: 1200
                                    			})
                                    		});
                                    	}else{
                                    		this.loader = false;
                                    		Swal.fire({
                                    			position: 'center',
                                    			icon: 'error',
                                    			title: 'Data has been not updated',
                                    			showConfirmButton: true,
                                    			timer: 1200
                                    		})
                                    	}
                                    }
                                /*this._amunt.getcolamount().subscribe(res =>{
                                }); */
                            });
                        }

                        if(this.RetirmentControlsAmt[0]){
                        	this.formattedDate= new Date()
                        	this.parmA['Date'] = this.formattedDate
                        	this.parmA['Admin_Name'] = this.globalName;
                        	this.parmA['Admin_Id'] = adminId;
                        	this.parmA['Index_value'] = this.indexValue;
                        	this.parmA['Name'] = this.name;
                        	this.parmA['Start_age'] = this.startAge;
                        	this.parmA['Stop_age'] = this.stopAge;
                        	this.parmA['Client_Name']= this.clientName;
                        	this.parmA['Spouse_Name']= this.spouseName;
                        	let defferedArray = []
                        	/*for(var j=0;j<totalCount;j++){*/
                        		for(var j=0;j<data;j++){
                        			if(this.RetirmentControlsAmt[j]){
                        				if(this.RSAgrouwthAmount[j] == 0 || this.RSAgrouwthAmount[j] == ''){
                        					this.RSAgrouwthAmount[j] = 0;
                        					this.RSgrwth = this.RSAgrouwthAmount[j];
                        					let RSAgrowthAmout = this.RSgrwth;
                        				}else{
                        					this.RSgrwth = parseInt(this.RSAgrouwthAmount[j].replace(/,/g, ''));
                        					let RSAgrowthAmout = this.RSgrwth;

                        				}
                        				let RSAgrowthAmout = this.RSgrwth;

                        				let RetirmentControlsAmt:any =0;

                        				if(this.RetirmentControlsAmt[j]!=0)
                        					RetirmentControlsAmt= parseInt(this.RetirmentControlsAmt[j].replace(/,/g, '')); 
                        				let enteredRate= this.RSAgrowthPercnt[j];
							//console.log(RetirmentControlsDeff[j]);
							let id = this.User_id;
							this.parmA['User_id'] = this.User_id;
							var RetirmentControlsDeff2:string;
							(this.RetirmentControlsDeff[j]!=null)?RetirmentControlsDeff2=this.RetirmentTaxFreeDeff[j]:RetirmentControlsDeff2="wd"+" "+[j];
							defferedArray.push({
								'Years' : this.showPercent,
								'Saving_Defferd': this.RetirmentControlsDeff[j],
								//'Income_Deffered': this.RetirmentIncome[j],
								'Amountt' : this.RetirmentControlsAmt[j],
								'MidAmount' : this.RSAgrouwthAmount[j],
								'FinalAmount' : this.RetContTotCalAmt[j],
								'Wd': this.rateRet[j],
								'Amount1': this.RetirmentControlsDeffTax[j],
								'FinalPercnt': this.RSAgrowthPercnt[j],
							});
							//console.log(defferedArray)
							this.parmA['Deff_Data'] = JSON.stringify(defferedArray) ;
						}}
						let parms = {
							'Index_value': this.indexValue,
							'Name' : this.name,
							'Admin_Id': adminId,
						}

						this.loader = true;
						this._ret.getCurrentA(parms).subscribe(res =>{
							if(!res){
								this._ret.addAmount(this.parmA).subscribe(res =>{
									console.log(res);
									let data:any=res;
									this.loader = false;
									if(data.Deffered == "Deffered has been added successfully")
											{
												this.auth.getRes(data.Deffered);
											}
									Swal.fire({
										position: 'center',
										icon: 'success',
										title: 'Data has been added successfully.',
										showConfirmButton: false,
										timer: 1200
									})
									/*if(sessionStorage.getItem('dataC')!=null)
                                    {
                                    	this.router.navigate(['/edit-client']);
                                    }*/
								}); 

							}else{

								let Name1 = {
									'Name' : this.name,
									'Admin_Id': adminId,
								}
								if(this.parmA['Deff_Data'] != undefined){
									this._ret.updateA(this.parmA,Name1).subscribe(res =>{
										this.loader = false;

										Swal.fire({
											position: 'center',
											icon: 'success',
											title: 'Data has been updated successfully.',
											showConfirmButton: false,
											timer: 1200
										})
									});
								}else{
									this.loader = false;
									Swal.fire({
										position: 'center',
										icon: 'error',
										title: 'Data has been not updated',
										showConfirmButton: true,
										timer: 1200
									})
								}
							}
						});
					}
					
					if(this.RetirmentTaxFreeAmt[0]){
						this.parmTax['Date'] = this.formattedDate;
						this.parmTax['Admin_Name'] = this.globalName;
						this.parmTax['Admin_Id'] = adminId;                        
						this.parmTax['Index_value'] = this.indexValue;
						this.parmTax['Name'] = this.name;
						this.parmTax['Start_age'] = this.startAge;
						this.parmTax['Stop_age'] = this.stopAge;
						this.parmTax['Client_Name']= this.clientName;
						this.parmTax['Spouse_Name']= this.spouseName;
						let defferedArray = []
						for(var j=0;j<data;j++){
							if(this.RetirmentTaxFreeAmt[j]){
								if(this.RSAgrouwthTax[j] == 0 || this.RSAgrouwthTax[j] == ''){
									this.RSAgrouwthTax[j] = 0;
									this.RSgrwthTx = this.RSAgrouwthTax[j];
									let RSAgrouwthTax = this.RSgrwthTx;
								}else{
									this.RSgrwthTx = parseInt(this.RSAgrouwthTax[j].replace(/,/g, ''));
									let RSAgrouwthTax = this.RSgrwthTx;

								}
								let RSAgrouwthTax = this.RSgrwthTx;
								let RetirmentTaxFreeAmt:any =0;
								if(this.RetirmentTaxFreeAmt[j]!=0)
									RetirmentTaxFreeAmt= parseInt(this.RetirmentTaxFreeAmt[j].replace(/,/g, '')); 
								let enteredRate= this.RSAgrowthPercntTex1[j];
								let id = this.User_id;
								this.parmTax['User_id'] = this.User_id;
								// console.log(this.RetirmentTaxFreeDeff[j]);
								var RetirmentTaxFreeDeff2:string;
								(this.RetirmentTaxFreeDeff[j]!=null)?RetirmentTaxFreeDeff2=this.RetirmentTaxFreeDeff[j]:RetirmentTaxFreeDeff2="wd"+" "+[j];
								defferedArray.push({
									'Years_Tax' : this.showPercent,
									//'Tax_Income' : this.RetirmentTaxIncome[j],
									'Tax_Saving' : RetirmentTaxFreeDeff2,
									'MidAmount_Tax' : this.RSAgrouwthTax[j],
									'Amountt_Tax' : this.RetirmentTaxFreeAmt[j],
									'FinalAmount_Tax' : this.RetContTotTax1[j],
									'Wd_Tax': this.rateTaxF[j],
									'Amount1_Tax': this.RetirmentControlsTaxFree[j],
									'FinalPercnt_Tax': this.RSAgrowthPercntTex1[j],
								});
								console.log(defferedArray);
								this.parmTax['Deff_DataTax'] = JSON.stringify(defferedArray) ;

							}}
							let parmT = {
								'Index_value': this.indexValue,
								'Name' : this.name,
								'Admin_Id': adminId,
							}

							this.loader = true;

							this._ret.getCurrentTax(parmT).subscribe(res =>{
								if(!res){
									this._ret.addTax1(this.parmTax).subscribe(data =>{
										console.log(data);
										let res:any=data;
										//console.log(res);
										this.loader = false;
										if(res.Amount == "Amount has been added successfully")
											{
												this.auth.getRes(res.Amount);
											}

										Swal.fire({
											position: 'center',
											icon: 'success',
											title: 'Data has been added successfully.',
											showConfirmButton: false,
											timer: 1200
										})
										/*if(sessionStorage.getItem('dataC')!=null)
                                    	{
                                    		this.router.navigate(['/edit-client']);
                                    	}*/
									}); 

								}else{
									let Name1 = {
										'Name' : this.name,
										'Admin_Id': adminId,

									}
									if(this.parmTax['Deff_DataTax'] != undefined){
										this._ret.updateTax(this.parmTax,Name1).subscribe(res =>{
											this.loader = false;

											Swal.fire({
												position: 'center',
												icon: 'success',
												title: 'Data has been updated successfully.',
												showConfirmButton: false,
												timer: 1200
											})
										});
									}else{
										this.loader = false;

										Swal.fire({
											position: 'center',
											icon: 'error',
											title: 'Data has been not updated',
											showConfirmButton: true,
											timer: 1200
										})
									}
								}
							});
						}
						if(this.RetirmentDeductAmt[0]){               
							this.parmDed['Date'] = this.formattedDate;
							this.parmDed['Admin_Name'] = this.globalName;
							this.parmDed['Admin_Id'] = adminId;                        
							this.parmDed['Index_value'] = this.indexValue;
							this.parmDed['Name'] = this.name;
							this.parmDed['Start_age'] = this.startAge;
							this.parmDed['Stop_age'] = this.stopAge;
							this.parmDed['Client_Name']= this.clientName;
							this.parmDed['Spouse_Name']= this.spouseName;
							let defferedArray = []
							for(var j=0;j<data;j++){
								if(this.RetirmentDeductAmt[j]){
									if(this.RSAgrouwthDed[j] == 0 || this.RSAgrouwthDed[j] == ''){
										this.RSAgrouwthDed[j] = 0;
										this.RSgrwthD = this.RSAgrouwthDed[j];
										let RSAgrouwthDed = this.RSgrwthD;
									}else{
										this.RSgrwthD = parseInt(this.RSAgrouwthDed[j].replace(/,/g, ''));
										let RSAgrouwthDed = this.RSgrwthD;

									}
									let RSAgrouwthDed = this.RSgrwthD;

									let RetirmentDeductAmt:any =0;

									if(this.RetirmentDeductAmt[j]!=0)
										RetirmentDeductAmt= parseInt(this.RetirmentDeductAmt[j].replace(/,/g, '')); 
									let enteredRate= this.RetirmentDeductAmt[j];

									let id = this.User_id;
									this.parmDed['User_id'] = this.User_id;
									//console.log(this.RetirmentDeductDeff[j]);
									if(this.RetirmentDeductDeff[j]!=null)
										var RetirmentDeductDeff1:string=this.RetirmentDeductDeff[j];
									else
										var RetirmentDeductDeff1:string="wd"+""+[j];
									defferedArray.push({
										//'Ded_Income' : this.Ded_Income[j],
										'Inc_Income' : RetirmentDeductDeff1,
										'Years_Ded' : this.showPercent,
										'MidAmount_Ded' : this.RSAgrouwthDed[j],
										'Amountt_Ded' : this.RetirmentDeductAmt[j],
										'FinalAmount_Ded' : this.RetContTotDed1[j],
										'Wd_Ded': this.rateDed[j],
										'Amount1_Ded': this.RetirmentControlsDed[j],
										'FinalPercnt_Ded': this.RSAgrowthPercntDed1[j],
									});
									this.parmDed['Deff_DataDed'] = JSON.stringify(defferedArray) ;
									//console.log(this.parmDed);
								}}
								let parmD = {
									'Index_value': this.indexValue,
									'Name' : this.name,
									'Admin_Id': adminId,

								}
								this.loader = true;

								this._ret.getCurrentDed(parmD).subscribe(res =>{
									if(!res){
										this._ret.addDed1(this.parmDed).subscribe(res =>{
											console.log(res);
											this.loader = false;
											let data:any = res;
											if(data.Amount == "Amount has been added successfully")
											{
												this.auth.getRes(data.Amount);
											}
											Swal.fire({
												position: 'center',
												icon: 'success',
												title: 'Data has been added successfully.',
												showConfirmButton: false,
												timer: 1200
											})
											/*if(sessionStorage.getItem('dataC')!=null)
	                                    	{
	                                    		this.router.navigate(['/edit-client']);
	                                    	}*/
										}); 

									}else{
										let Name1 = {
											'Name' : this.name,
											'Admin_Id': adminId,

										}
										if(this.parmDed['Deff_DataDed'] != undefined){
											this._ret.updateDed(this.parmDed, Name1).subscribe(res =>{
												this.loader = false;

												Swal.fire({
													position: 'center',
													icon: 'success',
													title: 'Data has been updated successfully.',
													showConfirmButton: false,
													timer: 1200
												})
											});
										}else{
											this.loader = false;

											Swal.fire({
												position: 'center',
												icon: 'error',
												title: 'Data has been not updated',
												showConfirmButton: true,
												timer: 1200
											})
										}
									}
								});
							}

							if(this.RetirmentPension1[0]){
								this.formattedDate= new Date()
								this.parm['Date'] = this.formattedDate
								this.parm['Admin_Name'] = this.globalName; 
								this.parm['Admin_Id'] = adminId;
								this.parm['Index_value'] = this.indexValue;
								this.parm['Name'] = this.name;
								this.parm['Start_age'] = this.startAge;
								this.parm['Stop_age'] = this.stopAge;
								this.parm['Client_Name']= this.clientName;
								this.parm['Spouse_Name']= this.spouseName;
								let pensionArray = []
								for(var j=0;j<data;j++){
									if(this.RetirmentPension1[j]){
										let RetirmentPension1:any =0;
										if(this.RetirmentPension1[j]!=0)
											RetirmentPension1= parseInt(this.RetirmentPension1[j].replace(/,/g, ''));

										pensionArray.push({
											'Index' : j,
											'P_Name' : this.NamePension[j],
											'Amount' : this.RetirmentPension1[j],
										})

									}}
									console.log(pensionArray);
									this.parm['Pension_Data'] = JSON.stringify(pensionArray) ;
									let parmsA = {
										'Index_value': this.indexValue,
										'Name' : this.name,
										'Admin_Id': adminId,
									}
									this._amunt.getCurramount(parmsA).subscribe(res =>{
										if(!res){
											this._amunt.addpension(this.parm).subscribe(res =>{
												this.User_id = res[0]._id;
												let id = this.User_id;
												Swal.fire({
													position: 'center',
													icon: 'success',
													title: 'Data has been added successfully.',
													showConfirmButton: false,
													timer: 1200
												})
												/*if(sessionStorage.getItem('dataC')!=null)
		                                    	{
		                                    		this.router.navigate(['/edit-client']);
		                                    	}*/

											})

										}
										else{
											if(this.parm['Pension_Data'] != 0){
												let Name1 = {
													'Name' : this.name
												}
												this._amunt.updateAmt(this.parm,Name1).subscribe(res =>{
													Swal.fire({
														position: 'center',
														icon: 'success',
														title: 'Data has been updated successfully.',
														showConfirmButton: false,
														timer: 1200
													})
												});
											}else{
												Swal.fire({
													position: 'center',
													icon: 'error',
													title: 'Data has been not updated',
													showConfirmButton: true,
													timer: 1200
												})
											}
										}
									})
								}

								/*------------------------2nd Form --------------------------------*/
								if(this.RetPension1[0]){
									this.parmP1['Date'] = this.formattedDate
									this.parmP1['Admin_Name'] = this.globalName; 
									this.parmP1['Admin_Id'] = adminId;
									this.parmP1['Index_value'] = this.indexValue;
									this.parmP1['Name'] = this.name;
									this.parmP1['Start_age'] = this.startAge;
									this.parmP1['Stop_age'] = this.stopAge;
									this.parmP1['Client_Name']= this.clientName;
									this.parmP1['Spouse_Name']= this.spouseName;
									let pensionArray1 = []
									for(var j=0;j<data;j++){
										if(this.RetPension1[j]){
											let RetPension1:any =0;
											if(this.RetPension1[j]!=0)
												RetPension1= parseInt(this.RetPension1[j].replace(/,/g, ''));

											pensionArray1.push({
												'Index' : j,
												'P_Name' : this.NamePension1[j],
												'Amount' : this.RetPension1[j],
											})

										}}
										this.parmP1['Pension_Data1'] = JSON.stringify(pensionArray1);
										let parmsA = {
											'Index_value': this.indexValue,
											'Name' : this.name,
											'Admin_Id': adminId,
										}
										this._amunt.getCurramount2(parmsA).subscribe(res =>{
											if(!res){
												this._amunt.addpension2(this.parmP1).subscribe(res =>{
													this.User_id = res[0]._id;
													let id = this.User_id;
													Swal.fire({
														position: 'center',
														icon: 'success',
														title: 'Data has been added successfully.',
														showConfirmButton: false,
														timer: 1200
													})
													/*if(sessionStorage.getItem('dataC')!=null)
			                                    	{
			                                    		this.router.navigate(['/edit-client']);
			                                    	}*/

												})

											}
											else{
												if(this.parmP1['Pension_Data1'] != 0){
													let Name1 = {
														'Name' : this.name
													}
													this._amunt.updateAmt2(this.parmP1,Name1).subscribe(res =>{
														Swal.fire({
															position: 'center',
															icon: 'success',
															title: 'Data has been updated successfully.',
															showConfirmButton: false,
															timer: 1200
														})
													});
												}else{
													Swal.fire({
														position: 'center',
														icon: 'error',
														title: 'Data has been not updated',
														showConfirmButton: true,
														timer: 1200
													})
												}
											}
										})
									}
									/*------------------Deffered 2nd save data ----------------*/
									if(this.Retirment2ndControlsAmt[0]){
										this.formattedDate= new Date()
										this.parm2A['Date'] = this.formattedDate
										this.parm2A['Admin_Name'] = this.globalName;
										this.parm2A['Admin_Id'] = adminId;
										this.parm2A['Index_value'] = this.indexValue;
										this.parm2A['Name'] = this.name;
										this.parm2A['Start_age'] = this.startAge;
										this.parm2A['Stop_age'] = this.stopAge;
										this.parm2A['Client_Name']= this.clientName;
										this.parm2A['Spouse_Name']= this.spouseName;
										let deffered2Array = []
										for(var j=0;j<data;j++){
											if(this.Retirment2ndControlsAmt[j]){
												if(this.RSAgrouwthAmount1[j] == 0 || this.RSAgrouwthAmount1[j] == ''){
													this.RSAgrouwthAmount1[j] = 0;
													this.RSgrwth1 = this.RSAgrouwthAmount1[j];
													let RSAgrowthAmout = this.RSgrwth1;
												}else{
													this.RSgrwth = parseInt(this.RSAgrouwthAmount1[j].replace(/,/g, ''));
													let RSAgrowthAmout = this.RSgrwth1;

												}
												let RSAgrowthAmout = this.RSgrwth1;
												let Retirment2ndControlsAmt:any =0;

												if(this.Retirment2ndControlsAmt[j]!=0)
													Retirment2ndControlsAmt= parseInt(this.Retirment2ndControlsAmt[j].replace(/,/g, '')); 
												let enteredRate= this.RSAgrowthPercnt1[j];

												let id = this.User_id;
												this.parm2A['User_id'] = this.User_id;

												var Retirment2ndControlsDeff2:string;
												if(this.Retirment2ndControlsDeff[j]!=null?Retirment2ndControlsDeff2=this.Retirment2ndControlsDeff[j]:Retirment2ndControlsDeff2="tax deferred"+" "+[j])

													deffered2Array.push({
														'Tax2_Income' :Retirment2ndControlsDeff2,
														'Years' : this.showPercent,
														'MidAmount2' : this.RSAgrouwthAmount1[j],
														'Amountt2' : this.Retirment2ndControlsAmt[j],
														'FinalAmount2' : this.RetContTotCalAmt1[j],
														'Wd_Tax2': this.rateRet1[j],
														'Amount2': this.RetirmentControlsDeffTax1[j],
														'FinalPercnt2': this.RSAgrowthPercnt1[j],
													});
												console.log(deffered2Array)
												this.parm2A['Deffered2_Data'] = JSON.stringify(deffered2Array) ;
											}}
											let parmD2 = {
												'Index_value': this.indexValue,
												'Name' : this.name,
												'Admin_Id': adminId,
											}

											this.loader = true;
											this._retire.getCurrentDeffered2(parmD2).subscribe(res =>{
												if(!res){
													this._retire.addDeffered2(this.parm2A).subscribe(res =>{
														console.log(res);
														this.loader = false;
														let data:any = res;
														if(data.Deffered == "Deffered has been added successfully")
														{
															this.auth.getRes(data.Deffered);
														}
														Swal.fire({
															position: 'center',
															icon: 'success',
															title: 'Data has been added successfully.',
															showConfirmButton: false,
															timer: 1200
														})
														/*if(sessionStorage.getItem('dataC')!=null)
				                                    	{
				                                    		this.router.navigate(['/edit-client']);
				                                    	}*/
													}); 

												}else{

													let Name1 = {
														'Name' : this.name,
														'Admin_Id': adminId,
													}
													if(this.parm2A['Deffered2_Data'] != undefined){
														this._retire.updateDeffered2(this.parm2A,Name1).subscribe(res =>{
															this.loader = false;

															Swal.fire({
																position: 'center',
																icon: 'success',
																title: 'Data has been updated successfully.',
																showConfirmButton: false,
																timer: 1200
															})
														});
													}else{
														this.loader = false;
														Swal.fire({
															position: 'center',
															icon: 'error',
															title: 'Data has been not updated',
															showConfirmButton: true,
															timer: 1200
														})
													}
												}
											});
										}

										/*------------------Tax Free 2nd save data ----------------*/

										if(this.RetirmentTaxFree2ndAmt[0]){
											this.parm2Tax['Date'] = this.formattedDate;
											this.parm2Tax['Admin_Name'] = this.globalName;
											this.parm2Tax['Admin_Id'] = adminId;                        
											this.parm2Tax['Index_value'] = this.indexValue;
											this.parm2Tax['Name'] = this.name;
											this.parm2Tax['Start_age'] = this.startAge;
											this.parm2Tax['Stop_age'] = this.stopAge;
											this.parm2Tax['Client_Name']= this.clientName;
											this.parm2Tax['Spouse_Name']= this.spouseName;
											let tax2Array = []
											for(var j=0;j<data;j++){
												if(this.RetirmentTaxFree2ndAmt[j]){
													if(this.RSAgrouwthTax1[j] == 0 || this.RSAgrouwthTax1[j] == ''){
														this.RSAgrouwthTax1[j] = 0;
														this.RSgrwthTx1 = this.RSAgrouwthTax1[j];
														let RSAgrouwthTax1 = this.RSgrwthTx1;
													}else{
														this.RSgrwthTx1 = parseInt(this.RSAgrouwthTax1[j].replace(/,/g, ''));
														let RSAgrouwthTax1 = this.RSgrwthTx1;

													}
													let RSAgrouwthTax1 = this.RSgrwthTx1;
													let RetirmentTaxFree2ndAmt:any =0;
													if(this.RetirmentTaxFree2ndAmt[j]!=0)
														RetirmentTaxFree2ndAmt= parseInt(this.RetirmentTaxFree2ndAmt[j].replace(/,/g, '')); 
													let enteredRate= this.RSAgrowthPercntTex2[j];
													let id = this.User_id;
													this.parmTax['User_id'] = this.User_id;

													var RetirmentTaxFree2ndDeff2:string;
													(this.RetirmentTaxFree2ndDeff[j]!=null)?RetirmentTaxFree2ndDeff2=this.RetirmentTaxFree2ndDeff[j]:RetirmentTaxFree2ndDeff2="wd"+" "+[j];

													tax2Array.push({
														'TaxF2_In' : RetirmentTaxFree2ndDeff2,
														'Years' : this.showPercent,
														'MidAmount_Tax2' : this.RSAgrouwthTax1[j],
														'Amountt_Tax2' : this.RetirmentTaxFree2ndAmt[j],
														'FinalAmount_Tax2' : this.RetContTotTax2[j],
														'Wd_Tax2': this.rateTaxF1[j],
														'Amount_Tax2': this.RetirmentControlsTaxFree1[j],
														'FinalPercnt_Tax2': this.RSAgrowthPercntTex2[j],
													});
													//console.log(tax2Array);
													this.parm2Tax['TaxFree2_Data'] = JSON.stringify(tax2Array) ;

												}}
												let parmT2 = {
													'Index_value': this.indexValue,
													'Name' : this.name,
													'Admin_Id': adminId,
												}

												this.loader = true;             
												this._retire.getCurrentTax2(parmT2).subscribe(res =>{
													if(!res){
														this._retire.addTax2(this.parm2Tax).subscribe(data =>{
															console.log(data);
															this.loader = false;
															let res:any = data;
															if(res.Amount == "Amount has been added successfully")
															{
																this.auth.getRes(res.Amount);
															}
															Swal.fire({
																position: 'center',
																icon: 'success',
																title: 'Data has been added successfully.',
																showConfirmButton: false,
																timer: 1200
															})
															/*if(sessionStorage.getItem('dataC')!=null)
					                                    	{
					                                    		this.router.navigate(['/edit-client']);
					                                    	}*/
														}); 

													}else{
														let Name1 = {
															'Name' : this.name,
															'Admin_Id': adminId,

														}
														if(this.parm2Tax['TaxFree2_Data'] != undefined){
															this._retire.updateTax2(this.parm2Tax,Name1).subscribe(res =>{
																this.loader = false;

																Swal.fire({
																	position: 'center',
																	icon: 'success',
																	title: 'Data has been updated successfully.',
																	showConfirmButton: false,
																	timer: 1200
																})
															});



														}else{
															this.loader = false;

															Swal.fire({
																position: 'center',
																icon: 'error',
																title: 'Data has been not updated',
																showConfirmButton: true,
																timer: 1200
															})
														}
													}
												});
											}

											/*------------------Tax Deductible 2nd save data ----------------*/


											if(this.RetirmentTaxDeduct2ndAmt[0]){               
												this.parm2Ded['Date'] = this.formattedDate;
												this.parm2Ded['Admin_Name'] = this.globalName;
												this.parm2Ded['Admin_Id'] = adminId;                        
												this.parm2Ded['Index_value'] = this.indexValue;
												this.parm2Ded['Name'] = this.name;
												this.parm2Ded['Start_age'] = this.startAge;
												this.parm2Ded['Stop_age'] = this.stopAge;
												this.parm2Ded['Client_Name']= this.clientName;
												this.parm2Ded['Spouse_Name']= this.spouseName;
												let deductible2Array = []
												for(var j=0;j<data;j++){
													if(this.RetirmentTaxDeduct2ndAmt[j]){
														if(this.RSAgrouwthDed1[j] == 0 || this.RSAgrouwthDed1[j] == ''){
															this.RSAgrouwthDed1[j] = 0;
															this.RSgrwthD1 = this.RSAgrouwthDed1[j];
															let RSAgrouwthDed1 = this.RSgrwthD1;
														}else{
															this.RSgrwthD1 = parseInt(this.RSAgrouwthDed1[j].replace(/,/g, ''));
															let RSAgrouwthDed1 = this.RSgrwthD1;

														}
														let RSAgrouwthDed1 = this.RSgrwthD1;
														let RetirmentTaxDeduct2ndAmt:any =0;

														if(this.RetirmentTaxDeduct2ndAmt[j]!=0)
															RetirmentTaxDeduct2ndAmt= parseInt(this.RetirmentTaxDeduct2ndAmt[j].replace(/,/g, '')); 
														let enteredRate= this.RetirmentTaxDeduct2ndAmt[j];

														let id = this.User_id;
														this.parm2Ded['User_id'] = this.User_id;
														var RetirmentTaxDeduct2ndDeff2:string;
														(this.RetirmentTaxDeduct2ndDeff[j]!=null)?RetirmentTaxDeduct2ndDeff2=this.RetirmentTaxDeduct2ndDeff[j]:RetirmentTaxDeduct2ndDeff2="wd"+" "+[j];

														deductible2Array.push({
															'Ded2_In' : RetirmentTaxDeduct2ndDeff2,
															'Years' : this.showPercent,
															'MidAmount_Ded2' : this.RSAgrouwthDed1[j],
															'Amountt_Ded2' : this.RetirmentTaxDeduct2ndAmt[j],
															'FinalAmount_Ded2' : this.RetContTotDed2[j],
															'Wd_Ded2': this.rateDed1[j],
															'Amount_Ded2': this.RetirmentControlsDed1[j],
															'FinalPercnt_Ded2': this.RSAgrowthPercntDed2[j],
														});
														console.log(deductible2Array);
														this.parm2Ded['Deductible2_Data'] = JSON.stringify(deductible2Array) ;
													}}
													let parmDed2 = {
														'Index_value': this.indexValue,
														'Name' : this.name,
														'Admin_Id': adminId,

													}
													this.loader = true;

													this._retire.getCurrentDed2(parmDed2).subscribe(res =>{
														if(!res){
															this._retire.addDed2(this.parm2Ded).subscribe(res =>{
																console.log(res);
																this.loader = false;
																let data:any = res;
																if(data.Amount == "Amount has been added successfully")
																{
																	this.auth.getRes(data.Amount);
																}
																Swal.fire({
																	position: 'center',
																	icon: 'success',
																	title: 'Data has been added successfully.',
																	showConfirmButton: false,
																	timer: 1200
																})
																/*if(sessionStorage.getItem('dataC')!=null)
						                                    	{
						                                    		this.router.navigate(['/edit-client']);
						                                    	}*/
															}); 

														}else{
															let Name1 = {
																'Name' : this.name,
																'Admin_Id': adminId,

															}
															if(this.parm2Ded['Deductible2_Data'] != undefined){
																this._retire.updateDed2(this.parm2Ded, Name1).subscribe(res =>{
																	this.loader = false;

																	Swal.fire({
																		position: 'center',
																		icon: 'success',
																		title: 'Data has been updated successfully.',
																		showConfirmButton: false,
																		timer: 1200
																	})
																});
															}else{
																this.loader = false;

																Swal.fire({
																	position: 'center',
																	icon: 'error',
																	title: 'Data has been not updated',
																	showConfirmButton: true,
																	timer: 1200
																})
															}
														}
													});
												}

											}else{
												Swal.fire({
													position: 'center',
													icon: 'error',
													title: 'Interval Name,Start And Stop Age Is Mendatory',
													showConfirmButton: true,
												})

											}
											

											/*------------Investment Save Data------------------*/

											if(this.InvestControlsAmt[0]){
												this.formattedDate= new Date();
												this.parmInv['Date'] = this.formattedDate
												this.parmInv['Admin_Name'] = this.globalName;
												this.parmInv['Admin_Id'] = adminId;
												this.parmInv['Index_value'] = this.indexValue;
												this.parmInv['Name'] = this.name;
												this.parmInv['Start_age'] = this.startAge;
												this.parmInv['Stop_age'] = this.stopAge;
												this.parmInv['Client_Name']= this.clientName;
												this.parmInv['Spouse_Name']= this.spouseName;
												let InvestmentArray = []
												for(var j=0;j<data;j++){
													if(this.InvestControlsAmt[j]){
														if(this.InvestAmount[j] == 0 || this.InvestAmount[j] == ''){
																this.InvestAmount[j] = 0;
																this.IntGrowth = this.InvestAmount[j];
																let InvestAmount = this.IntGrowth;
															}else{
																this.IntGrowth = parseInt(this.InvestAmount[j].replace(/,/g, ''));
																let InvestAmount = this.IntGrowth;

															}
															let InvestAmount = this.IntGrowth;

														//let investmentA = parseInt(this.InvestAmount[j].replace(/,/g, ''));
														let InvestControlsAmt:any =0;
														if(this.InvestControlsAmt[j]!=0)
															InvestControlsAmt= parseInt(this.InvestControlsAmt[j].replace(/,/g, ''));
														let id = this.User_id;
														this.parmInv['User_id'] = this.User_id;
														InvestmentArray.push({
															'invest_name':this.InvestControlsDeff[j],
															'Years' : this.showPercent,
															'FinalPercnt': this.RSAgrowthInvest[j],
															'MidAmount' : this.InvestAmount[j],
															'Amountt' : this.InvestControlsAmt[j],
															'FinalAmount' : this.InvestAmt[j],
															'wd': this.rateInvst1[j],
                                    						'Amount1': this.InvesmentIncome[j]
																							})
									                                //console.log(param);
									                                this.parmInv['inv_Data'] = JSON.stringify(InvestmentArray) ;
									                            }}
									                            console.log(this.parmInv);
									                            let parms1 = {
									                            	'Index_value': this.indexValue,
									                            	'Name' : this.name,
									                            	'Admin_Id': adminId,
									                            }
									                            console.log(InvestmentArray);
									                            this.loader = true;
									                            this._Sav.getCurrentI(parms1).subscribe(res =>{
									                            	console.log(res);
									                                    //this.loader= false;
									                                    if(!res){
									                                    	this._amunt.invAmount(this.parmInv).subscribe(res =>{
									                                    		console.log(res);
									                                    		this.loader = false;
									                                    		let data:any = res;
									                                    		if(data.Investment == "Investment has been added successfully")
																				{
																					this.auth.getRes(data.Investment);
																				}
									                                    		Swal.fire({
									                                    			position: 'center',
									                                    			icon: 'success',
									                                    			title: 'Data has been added successfully.',
									                                    			showConfirmButton: false,
									                                    			timer: 1200
									                                    		})
									                                    		/*if(sessionStorage.getItem('dataC')!=null)
										                                    	{
										                                    		this.router.navigate(['/edit-client']);
										                                    	}*/
									                                    	});
									                                    }
									                                    else{
									                                    	let Name1 = {
									                                    		'Name' : this.name,
									                                    		'Admin_Id': adminId,
									                                    	}
									                                    	if(this.parmInv['inv_Data'] != undefined){
									                                    		this._Sav.updateI(this.parmInv,Name1).subscribe(res =>{
									                                    			this.loader = false;
									                                    			Swal.fire({
									                                    				position: 'center',
									                                    				icon: 'success',
									                                    				title: 'Data has been updated successfully',
									                                    				showConfirmButton: false,
									                                    				timer: 1200
									                                    			})
									                                    		});
									                                    	}else{
									                                    		this.loader = false;
									                                    		Swal.fire({
									                                    			position: 'center',
									                                    			icon: 'error',
									                                    			title: 'Data has been not updated',
									                                    			showConfirmButton: true,
									                                    			timer: 1200
									                                    		})
									                                    	}	
									                                    }
									                                });
										                        }
									                    }
                									}

                

                /*-----------------Client First---------------*/
                taxdefferd(event, i){
                	if(event.target.value == 0 || event.target.value == null){
                		this.amntTest = !this.amntTest;
                	}
                }
                Sav(event, i){
                    if(event.target.value == 0 || event.target.value == null){
                        this.amuntSav = !this.amuntSav;
                    }
                }

                SavCol(event, i ){
                    if(event.target.value == 0 || event.target.value == null){
                        this.amuntCol = !this.amuntCol;
                    }
                }

                Taxfree(event, i){
                	if(event.target.value == 0 || event.target.value == null){
                		this.amntTax = !this.amntTax;
                	}
                }

                TaxDeduct(event, i){
                	if(event.target.value == 0 || event.target.value == null){
                		this.amuntded = !this.amuntded;
                	}
                }
                /*-------------------Spouse First-----------------------*/
                taxdefferd2(event, i){
                	if(event.target.value == 0 || event.target.value == null){
                		this.amntTest2 = !this.amntTest2;
                	}
                }
                Taxfree2(event, i){
                	if(event.target.value == 0 || event.target.value == null){
                		this.amnttaxF1 = !this.amnttaxF1;
                	}
                }

                TaxDeduct2(event, i){
                	if(event.target.value == 0 || event.target.value == null){
                		this.amuntdeduct = !this.amuntdeduct;
                	}
                }

                tstDef(event, i){
                	this.mDef12 = false
                	if(event.target.value == 0 || event.target.value == null){
                		this.showMiddleArrowDef2 = i;
                	}

                }

                tsting(event, i){   
                	if(this.RSAgrouwthAmount[i] == 0){
                		this.mDef1 = false
                		if(event.target.value == 0 || event.target.value == null){
                			this.showMiddleArrow = i;
                		}
                	}
                }

                tstDef2(event, i){   
                	if(this.RSAgrouwthAmount1[i] == 0){
                		this.mDef2 = false
                		if(event.target.value == 0 || event.target.value == null){
                			this.showMiddleArrowD2 = i;
                		}
                	}
                }

                tstSav(event, i){   
                	if(this.SavAmount[i] == 0){
                		this.mSav1 = false
                		if(event.target.value == 0 || event.target.value == null){
                			this.showMiddleArrowSav = i;
                		}
                	}
                }

                tstCol(event, i){   
                	if(this.ColAmount[i] == 0){
                		this.mSav1 = false
                		if(event.target.value == 0 || event.target.value == null){
                			this.showMiddleArrowCol = i;
                		}
                	}
                }


                taxMiddle(event, i){    
                	if(this.RSAgrouwthTax[i] == 0){
                		this.mTax = false
                		if(event.target.value == 0 || event.target.value == null){
                			this.showMiddleArrowT = i;
                		}
                	}
                }

                taxMiddle1(event, i){    
                	if(this.RSAgrouwthTax1[i] == 0){
                		this.mTax1 = false
                		if(event.target.value == 0 || event.target.value == null){
                			this.showMiddleArrowT1 = i;
                		}
                	}
                }

                deductMiddle(event, i){ 
                	if(this.RSAgrouwthDed[i] == 0){
                		this.mDed = false
                		if(event.target.value == 0 || event.target.value == null){
                			this.showMiddleArrowD = i;
                		}
                	}
                }

                deductMiddle1(event, i){ 
                	if(this.RSAgrouwthDed1[i] == 0){
                		this.mDed1 = false
                		if(event.target.value == 0 || event.target.value == null){
                			this.showMiddleArrowD1 = i;
                		}
                	}
                }
                investMiddle(event, i){ 
		             if(this.InvestAmount[i] == 0){
		                this.mInv = false
		                if(event.target.value == 0 || event.target.value == null){
		                    this.showMiddleArrowInv = i;
		                }
		             }
		        }

                PensionData(i){
                	if(this.NameControl[0] == this.NameControl[i] && this.start[0] == this.start[i]){
                		this.mDef1 = true;
                		if(this.RSAgrouwthAmount[i]){
                			let RSAgrowthAmout:any = parseInt(this.RSAgrouwthAmount[i].replace(/,/g, '')); 
                			this.RSAgrouwthAmount[i] = new Intl.NumberFormat().format(parseInt(RSAgrowthAmout));
                		}
                		this.mTax = true;
                		if(this.RSAgrouwthTax[i]){
                			let RSAgrowthAmout1:any = parseInt(this.RSAgrouwthTax[i].replace(/,/g, '')); 
                			this.RSAgrouwthTax[i] = new Intl.NumberFormat().format(parseInt(RSAgrowthAmout1));
                		}
                		this.mDed = true;
                		if(this.RSAgrouwthDed[i]){
                			let RSAgrowthAmout2:any = parseInt(this.RSAgrouwthDed[i].replace(/,/g, '')); 
                			this.RSAgrouwthDed[i] = new Intl.NumberFormat().format(parseInt(RSAgrowthAmout2));
                		}
                		this.mSav1 = true;
                		if(this.SavAmount[i]){
                			let RSAgrowthSav:any = parseInt(this.SavAmount[i].replace(/,/g, '')); 
                			this.SavAmount[i] = new Intl.NumberFormat().format(parseInt(RSAgrowthSav));
                		}
                		this.mCol = true;
                		if(this.ColAmount[i]){
                			let RSAgrowthCol:any = parseInt(this.ColAmount[i].replace(/,/g, '')); 
                			this.ColAmount[i] = new Intl.NumberFormat().format(parseInt(RSAgrowthCol));
                		}
                		this.mDef2 = true;
                		if(this.RSAgrouwthAmount1[i]){
                			let RSAgrowthA1:any = parseInt(this.RSAgrouwthAmount1[i].replace(/,/g, '')); 
                			this.RSAgrouwthAmount1[i] = new Intl.NumberFormat().format(parseInt(RSAgrowthA1));
                		}
                		this.mTax1 = true;
                		if(this.RSAgrouwthTax1[i]){
                			let RSAgrowthT1:any = parseInt(this.RSAgrouwthTax1[i].replace(/,/g, '')); 
                			this.RSAgrouwthTax1[i] = new Intl.NumberFormat().format(parseInt(RSAgrowthT1));
                		}
                		this.mDed1 = true;
                		if(this.RSAgrouwthDed1[i]){
                			let RSAgrowthd2:any = parseInt(this.RSAgrouwthDed1[i].replace(/,/g, '')); 
                			this.RSAgrouwthDed1[i] = new Intl.NumberFormat().format(parseInt(RSAgrowthd2));
                		}
                		this.mInv = true;
                        if(this.InvestAmount[i]){
                            let RSAgrowthInv:any = parseInt(this.InvestAmount[i].replace(/,/g, '')); 
                            this.InvestAmount[i] = new Intl.NumberFormat().format(parseInt(RSAgrowthInv));
                        }


                	}else{
                		this.mDef1 = false;
                		this.showMiddleArrow= -1
                		this.mTax = false;
                		this.showMiddleArrowT= -1
                		this.mDed = false;
                		this.showMiddleArrowD= -1
                		this.mSav1 = false;
                		this.showMiddleArrowSav= -1
                		this.mCol = false;
                		this.showMiddleArrowCol= -1
                		this.mDef2 = false;
                		this.showMiddleArrowD2= -1
                		this.mTax1 = false;
                		this.showMiddleArrowT1= -1
                		this.mDed1 = false;
                		this.showMiddleArrowD1= -1
                		this.mInv = false;
                        this.showMiddleArrowInv= -1

                	}
                	let value = i;
                	this.indexValue = value;
                	this.name =  this.NameControl[i];
                	this.startAge = this.start[i];


                	let parms = {
                		'Index_value': this.indexValue,
                		'Name' : this.name
                	}
                	const user = this.auth.getUser();    
                	let adminId = user._id;
                	let parmsA = {
                		'Index_value': this.indexValue,
                		'Name' : this.name,
                		'Admin_Id': adminId
                	}
                	this._amunt.getCurramount(parmsA).subscribe(res =>{

   //this._amunt.getpensionamount().subscribe(res =>{

   	let data: any = res;
   	if(data == null){
			//this.RetirmentPension1[k] = 0;
		}
		else{

			this.dataP.length = 0
			this.dataN.length = 0

			let amountt = JSON.parse(data.Pension_Data[0]);
			if(amountt.length == 0){
				for(var i=0;i<=this.totalCount + 1;i++){
					this.RetirmentPension1[i] = 0;
					this.NamePension[i] = ''
				}
			}else{
				for(var x= 0; x<amountt.length; x++){

					let dataP = [];
					let dataN = [];
					let amt = amountt[x].Amount;
					let nameP = amountt[x].P_Name;

					this.dataN.push(nameP)

					let totalamt = amt.replace(/,/g, '');
					let pensionA = new Intl.NumberFormat().format(parseInt(totalamt));
					this.dataP.push(pensionA)
					for(var j=0;j<this.dataP.length;j++){
						this.RetirmentPension1[j] = this.dataP[j]; 

					}
					for(var k=0;k<this.dataN.length;k++){
						this.NamePension[k] = this.dataN[k]; 

					}
				}
			}
		}
		this.SumofAll();
	});

                	this._amunt.getCurramount2(parmsA).subscribe(res =>{
                		let data: any = res;
                		if(data == null){
			//this.RetirmentPension1[k] = 0;
		}else{

			this.dataP.length = 0
			this.dataN.length = 0

			let amountt = JSON.parse(data.Pension_Data1[0]);
			if(amountt.length == 0){
				for(var i=0;i<=this.totalCount + 1;i++){
					this.RetPension1[i] = 0;
					this.NamePension1[i] = ''
				}
			}else{
				for(var x= 0; x<amountt.length; x++){

					let dataP = [];
					let dataN = [];
					let amt = amountt[x].Amount;
					let nameP = amountt[x].P_Name;

					this.dataN.push(nameP)

					let totalamt = amt.replace(/,/g, '');
					let pensionA = new Intl.NumberFormat().format(parseInt(totalamt));
					this.dataP.push(pensionA)
					for(var j=0;j<this.dataP.length;j++){
						this.RetPension1[j] = this.dataP[j]; 

					}
					for(var k=0;k<this.dataN.length;k++){
						this.NamePension1[k] = this.dataN[k]; 

					}
				}
			}
		}
		this.SumofAll2();
	});





                	/* this._ret.getData(parms).subscribe(res =>{*/
                		this._ret.getamount().subscribe(res =>{
                			console.log(res);
                			let data: any = res;
                			if(data.length == 0 || data == null ){
                				//console.log('if1')
                				this.rateRet[0] = 0;
                				this.RSAPensionRem();

                			}
                			else{
                				//console.log('else1');
                				this.dataP.length = 0
                				//console.log(data.length);
                				for(var k=0;k<data.length;k++){
                					let adminId = user._id;
                					//console.log(this.name+ '  '+ this.indexValue+ '  ' + adminId )
                					if(data[k].Name == this.name && data[k].Index_value == this.indexValue && data[k].Admin_Id == adminId){
                						if(data[k].Deff_Data != 0){
                							let wd = JSON.parse(data[k].Deff_Data[0]);
                							//console.log(wd);
                							for(var x= 0; x<wd.length; x++){
                								let dataP = [];
                								let amt = wd[x].Wd;
                								//console.log(amt);
                								this.dataP.push(amt);
                								//console.log(this.dataP);

                								for(var j=0;j<this.dataP.length;j++){

                									this.rateRet[j] = this.dataP[j];
                								}
                								//console.log(this.rateRet);
                							}
                						}else{
                							return false;
                						}
                					}else{
                						this.rateRet[k] = 0;

                					}
                				}
                			}
                			this.retireLeft(); 
                			this.SumofAll();
                		});



                		/*this._ret.getTaxData(parms).subscribe(res =>{*/
                			this._ret.getTax1().subscribe(res =>{
                				console.log(res);
                				let data: any = res;
                				if(data.length == 0 || data == null ){
                					this.rateTaxF[0] = 0;
                				}
                				else{
                					this.dataP.length = 0
                					for(var k=0;k<data.length;k++){
                						let adminId = user._id;
                						if(data[k].Name == this.name && data[k].Index_value == this.indexValue && data[k].Admin_Id == adminId){
                							if(data[k].Deff_DataTax != 0){
                								let wd = JSON.parse(data[k].Deff_DataTax[0]);
                								for(var x= 0; x<wd.length; x++){

                									let dataP = [];
                									let amt = wd[x].Wd_Tax;
                									let amt1 = 
                									this.dataP.push(amt) 

                									for(var j=0;j<this.dataP.length;j++){

                										this.rateTaxF[j] = this.dataP[j];

                									}
                								}
                							}else{
                							}
                						}else{
                							this.rateTaxF[k] = 0;
                						}

                					}
                				}
                				this.retireLeft(); 
                				this.SumofAll();
                			});

                			/*this._ret.getDedData(parms).subscribe(res =>{*/
                				this._ret.getDed1().subscribe(res =>{
                					console.log(res);
                					let data: any = res;
                					if(data.length == 0){
                						this.rateDed[0] = 0;
                					}
                					else{
                						this.dataP.length = 0
                						for(var k=0;k<data.length;k++){
												//let adminId = this.admin;
												let adminId = user._id; 
												if(data[k].Name == this.name && data[k].Index_value == this.indexValue && data[k].Admin_Id == adminId){
													if(data[k].Deff_DataDed != 0){
														let wd = JSON.parse(data[k].Deff_DataDed[0]);
														for(var x= 0; x<wd.length; x++){

															let dataP = [];
															let amt = wd[x].Wd_Ded;
															this.dataP.push(amt)

															for(var j=0;j<this.dataP.length;j++){

																this.rateDed[j] = this.dataP[j]; 
															}
														}
													}else{
														return false;
													}
												}else{
													this.rateDed[k] = 0;
												}

											}
										}
										this.retireLeft(); 
										this.SumofAll();
									});


                				/*-----For second form---------------*/

                				this._retire.getDeffered2().subscribe(res =>{
                					console.log(res);
                					let data: any = res;
                					if(data.length == 0){
                						this.rateRet1[0] = 0;

                					}
                					else{
                						this.dataP.length = 0
                						for(var k=0;k<data.length;k++){
                							let adminId = user._id;

                							if(data[k].Name == this.name && data[k].Index_value == this.indexValue && data[k].Admin_Id == adminId){
                								if(data[k].Deffered2_Data != 0){
                									let wd = JSON.parse(data[k].Deffered2_Data[0]);
                									for(var x= 0; x<wd.length; x++){
                										let dataP = [];
                										let amt = wd[x].Wd_Tax2;
                										this.dataP.push(amt)

                										for(var j=0;j<this.dataP.length;j++){

                											this.rateRet1[j] = this.dataP[j];
                										}
                									}
                								}else{
                									return false;
                								}
                							}else{
                								this.rateRet[k] = 0;

                							}
                						}
                					}
                					this.retireLeft(); 
                					this.SumofAll2();
                				});
													this._retire.getTax2().subscribe(res =>{
														console.log(res);
														let data: any = res;
														if(data.length == 0){
															console.log('iffTax');
															this.rateTaxF1[0] = 0;
														}
														else{
															console.log('elseTax');
															this.dataP.length = 0
															for(var k=0;k<data.length;k++){
																let adminId = user._id;
																if(data[k].Name == this.name && data[k].Index_value == this.indexValue && data[k].Admin_Id == adminId){
																	if(data[k].TaxFree2_Data != 0){
																		let wd = JSON.parse(data[k].TaxFree2_Data[0]);
																		for(var x= 0; x<wd.length; x++){

																			let dataP = [];
																			let amt = wd[x].Wd_Tax2;
																			let amt1 = 
																			this.dataP.push(amt) 

																			for(var j=0;j<this.dataP.length;j++){

																				this.rateTaxF1[j] = this.dataP[j];

																			}
																		}
																	}else{
																	}
																}else{
																	this.rateTaxF1[k] = 0;
																}

															}
														}
														this.retireLeft(); 
														this.SumofAll2();
													});
													
													this._retire.getDed2().subscribe(res =>{
														console.log(res);
														let data: any = res;
														if(data.length == 0){
															//console.log('hello');
															this.rateDed1[0] = 0;
														}
														else{
															//console.log('else');
															this.dataP.length = 0
															for(var k=0;k<data.length;k++){
				//let adminId = this.admin; 
																let adminId = user._id; 
																if(data[k].Name == this.name && data[k].Index_value == this.indexValue && data[k].Admin_Id == adminId){
																	//console.log('ifff');
																	if(data[k].Deductible2_Data != 0){
																		let wd = JSON.parse(data[k].Deductible2_Data[0]);
																		for(var x= 0; x<wd.length; x++){

																			let dataP = [];
																			let amt = wd[x].Wd_Ded2;
																			//console.log(amt);
																			this.dataP.push(amt)

																			for(var j=0;j<this.dataP.length;j++){

																				this.rateDed1[j] = this.dataP[j]; 
																			}
																		}
																	}else{
																		return false;
																	}
																}else{
																	//console.log('elseee');
																	this.rateDed1[k] = 0;
																}
															}
														}
														this.retireLeft(); 
														this.SumofAll2();
													});


													this._amunt.getInvest().subscribe(res =>{
														let data: any = res;
														if(data == null){
															this.rateInvst1[0] = 0;
														}
														else{
															this.dataP.length = 0
															for(var k=0;k<data.length;k++){
				//let adminId = this.admin;
																let adminId = user._id; 
																if(data[k].Name == this.name && data[k].Index_value == this.indexValue && data[k].Admin_Id == adminId){
																	if(data[k].inv_Data != 0){
																		let wd = JSON.parse(data[k].inv_Data[0]);
																		console.log(wd);
																		for(var x= 0; x<wd.length; x++){

																			let dataP = [];
																			let amt = wd[x].wd;
																			this.dataP.push(amt);

																			for(var j=0;j<this.dataP.length;j++){

																				this.rateInvst1[j] = this.dataP[j]; 
																			}
																		}
																	}else{
																		return false;
																	}
																}else{
																	this.rateInvst1[k] = 0;
																}
															}
														}
														this.retireLeft(); 
													});

												}



												dataSavingPlus(ind=0){

													for(var i=0;i<=this.totalCount;i++){

														/*----saving----*/

														if(this.SavingControlsAmt[i]){
															if(this.SavAmount[i] == 0){
																this.SavGrowth = 0;
																let savingA = this.SavGrowth;
															}else{
																this.SavGrowth = parseInt(this.SavAmount[i].replace(/,/g, ''));
																let savingA = this.SavGrowth;
															}
															let savingA = this.SavGrowth;
															let SavingControlsAmt:any =0;
															let SavingControlsAmtP:any =0;
															if(this.SavingControlsAmt[i]!=0){
																SavingControlsAmt= parseInt(this.SavingControlsAmt[i].replace(/,/g, ''));
																SavingControlsAmtP= SavingControlsAmt;
															}
															let enteredRate= this.SavingSidbar[i];
															let diff=0;
															let pDiff=0;
															for(var j=0;j<=ind;j++){
																diff += this.showdiff[j];
																if(j<ind)
																	pDiff += this.showdiff[j];
															}
															if(SavingControlsAmt>=0 || savingA>=0 || enteredRate>=0){
																for(var l=0;l<diff;l++){
																	SavingControlsAmt+=savingA;
																	SavingControlsAmt+=(SavingControlsAmt*enteredRate)/100;             
																}
																for(var l=0;l<pDiff;l++){
																	SavingControlsAmtP+=savingA;
																	SavingControlsAmtP+=(SavingControlsAmtP*enteredRate)/100;               
																}     
																this.amuntSav= true;
																//this.amuntSav1=true;
																this.TotSav[i]=new Intl.NumberFormat().format(parseInt(SavingControlsAmt));
																this.SavingControlsA[i]= new Intl.NumberFormat().format(parseInt(SavingControlsAmtP));
															} 
															this.totalCount++;  
														}

														/*----Collage-----*/
														if(this.CollageControlsAmt[i]){
															if(this.ColAmount[i] == 0){
																this.ColGrowth = 0;
																let collageA = this.ColGrowth;
															}else{
																this.ColGrowth = parseInt(this.ColAmount[i].replace(/,/g, ''));
																let collageA = this.ColGrowth;
															}
															let collageA = this.ColGrowth;
															let CollageControlsAmt:any =0;
															let CollageControlsAmtP:any =0;

															if(this.CollageControlsAmt[i]!=0){
																CollageControlsAmt= parseInt(this.CollageControlsAmt[i].replace(/,/g, '')); 
																CollageControlsAmtP = CollageControlsAmt;
															}
															let enteredRate=this.CollageSidebar[i];
															let diff=0;
															let pDiff=0;
															for(var j=0;j<=ind;j++){
																diff += this.showdiff[j];
																if(j<ind)
																	pDiff += this.showdiff[j];
															}

															if(CollageControlsAmt>=0 || collageA>=0 || enteredRate>=0){

																for(var l=0;l<diff;l++){
																	CollageControlsAmt+=collageA;
																	CollageControlsAmt+=(CollageControlsAmt*enteredRate)/100;               
																}
																for(var l=0;l<pDiff;l++){
																	CollageControlsAmtP+=collageA;
																	CollageControlsAmtP+=(CollageControlsAmtP*enteredRate)/100;             
																}
																this.amuntCol= false;
																this.amuntCol1=true;      
																this.TotCol[i]=new Intl.NumberFormat().format(parseInt(CollageControlsAmt));
																this.CollageControlsA[i]= new Intl.NumberFormat().format(parseInt(CollageControlsAmtP));

															} 
															this.totalCount++;   
														}
														/*-----Tax Deffered 1---------*/
														if(this.RetirmentControlsAmt[i]){
															console.log(this.RetirmentControlsAmt)
															if(this.RSAgrouwthAmount[i] == 0){
																this.RSgrwth = 0;
																let RSAgrowthAmout = this.RSgrwth;
															}else{
																this.RSgrwth = parseInt(this.RSAgrouwthAmount[i].replace(/,/g, ''));
																let RSAgrowthAmout = this.RSgrwth;
															}
															let RSAgrowthAmout = this.RSgrwth;

															let RetirmentControlsAmt:any =0;
															let RetirmentControlsAmtP:any =0;
															if(this.RetirmentControlsAmt[i]!=0){
																RetirmentControlsAmt= parseInt(this.RetirmentControlsAmt[i].replace(/,/g, ''));
																RetirmentControlsAmtP= RetirmentControlsAmt;
															}
															let enteredRate=this.RSAgrowthPercnt[i];
															console.log(enteredRate);
															let diff=0;
															let pDiff=0;
															for(var j=0;j<=ind;j++){
																diff += this.showdiff[j];
																if(j<ind)
																	pDiff += this.showdiff[j];
															}
															if(RetirmentControlsAmt>=0 || RSAgrowthAmout>=0 || enteredRate>=0){
																for(var l=0;l<diff;l++){
																	RetirmentControlsAmt+=RSAgrowthAmout;
																	RetirmentControlsAmt+=(RetirmentControlsAmt*enteredRate)/100;               
																}
																for(var l=0;l<pDiff;l++){
																	RetirmentControlsAmtP+=RSAgrowthAmout;
																	RetirmentControlsAmtP+=(RetirmentControlsAmtP*enteredRate)/100;             
																}
																this.amntTest = false;
																this.amntTest1 = true;
																this.RetirmentControlsA[i]= new Intl.NumberFormat().format(parseInt(RetirmentControlsAmtP));
																this.RetContTotCalAmt[i]=new Intl.NumberFormat().format(parseInt(RetirmentControlsAmt));
															}
															this.totalCount++;
														}

														/*---Tax free1----*/

														if(this.RetirmentTaxFreeAmt[i]){
															if(this.RSAgrouwthTax[i] == 0){
																this.RSgrwthTx = 0;
																let RSAgrouwthTax = this.RSgrwthTx;
															}else{
																this.RSgrwthTx = parseInt(this.RSAgrouwthTax[i].replace(/,/g, ''));
																let RSAgrouwthTax = this.RSgrwthTx;
															}
															let RSAgrouwthTax = this.RSgrwthTx;
															let RetirmentTaxFreeAmt:any =0;
															let RetirmentTaxFreeAmtP:any =0;

															if(this.RetirmentTaxFreeAmt[i]!=0){
																RetirmentTaxFreeAmt= parseInt(this.RetirmentTaxFreeAmt[i].replace(/,/g, '')); 
																RetirmentTaxFreeAmtP= RetirmentTaxFreeAmt;
															}
															let enteredRate=this.RSAgrowthPercntTex1[i];
															let diff=0;
															let pDiff=0;
															for(var j=0;j<=ind;j++){
																diff += this.showdiff[j];
																if(j<ind)
																	pDiff += this.showdiff[j];
															}
															if(RetirmentTaxFreeAmt>=0 || RSAgrouwthTax>=0 || enteredRate>=0){

																for(var l=0;l<diff;l++){
																	RetirmentTaxFreeAmt+=RSAgrouwthTax;
																	RetirmentTaxFreeAmt+=(RetirmentTaxFreeAmt*enteredRate)/100;

																}
																for(var l=0;l<pDiff;l++){
																	RetirmentTaxFreeAmtP+=RSAgrouwthTax;
																	RetirmentTaxFreeAmtP+=(RetirmentTaxFreeAmtP*enteredRate)/100;               
																}

																this.amntTax = false;
																this.amuntTax1 = true;
																this.RetirmentTaxFreeA[i]= new Intl.NumberFormat().format(parseInt(RetirmentTaxFreeAmtP));
																this.RetContTotTax1[i]=new Intl.NumberFormat().format(parseInt(RetirmentTaxFreeAmt));
															}
															this.totalCount++;
														}
														/*---Tax Deductible----*/

														if(this.RetirmentDeductAmt[i]){
															if(this.RSAgrouwthDed[i] == 0){
																this.RSgrwthD = 0;
																let RSAgrouwthDed = this.RSgrwthD;
															}else{
																this.RSgrwthD = parseInt(this.RSAgrouwthDed[i].replace(/,/g, ''));
																let RSAgrouwthDed = this.RSgrwthD;
															}
															let RSAgrouwthDed = this.RSgrwthD;

															let RetirmentDeductAmt:any =0;
															let RetirmentDeductAmtP:any =0;

															if(this.RetirmentDeductAmt[i]!=0){
																RetirmentDeductAmt= parseInt(this.RetirmentDeductAmt[i].replace(/,/g, ''));
																RetirmentDeductAmtP= RetirmentDeductAmt; 
															}
															let enteredRate=this.RSAgrowthPercntDed1[i];
															let diff=0;
															let pDiff=0;
															for(var j=0;j<=ind;j++){
																diff += this.showdiff[j];
																if(j<ind)
																	pDiff += this.showdiff[j];
															}

															if(RetirmentDeductAmt>=0 || RSAgrouwthDed>=0 || enteredRate>=0){

																for(var l=0;l<diff;l++){
																	RetirmentDeductAmt+=RSAgrouwthDed;
																	RetirmentDeductAmt+=(RetirmentDeductAmt*enteredRate)/100;               
																}
																for(var l=0;l<pDiff;l++){
																	RetirmentDeductAmtP+=RSAgrouwthDed;
																	RetirmentDeductAmtP+=(RetirmentDeductAmtP*enteredRate)/100;             
																}

																this.amuntded = false;
																this.amuntded1 = true;    
																this.RetContTotDed1[i]=new Intl.NumberFormat().format(parseInt(RetirmentDeductAmt));
																this.RetirmentDeductA[i]= new Intl.NumberFormat().format(parseInt(RetirmentDeductAmtP));
															}  
															this.totalCount++;  
														}

														/*----Tax Deffered 2---*/
														if(this.Retirment2ndControlsAmt[i]){
															if(this.RSAgrouwthAmount1[i] == 0){
																this.RSgrwth1 = 0;
																let RSAgrowthAmout1 = this.RSgrwth1;
															}else{
																this.RSgrwth1 = parseInt(this.RSAgrouwthAmount1[i].replace(/,/g, ''));
																let RSAgrowthAmout1 = this.RSgrwth1;
															}
															let RSAgrowthAmout1 = this.RSgrwth1;
															let Retirment2ndControlsAmt:any =0;
															let Retirment2ndControlsAmtP:any =0;

															if(this.Retirment2ndControlsAmt[i]!=0){
																Retirment2ndControlsAmt= parseInt(this.Retirment2ndControlsAmt[i].replace(/,/g, '')); 
																Retirment2ndControlsAmtP= Retirment2ndControlsAmt; 
															}
															let enteredRate=this.RSAgrowthPercnt1[i];
															let diff=0;
															let pDiff=0;
															for(var j=0;j<=ind;j++){
																diff += this.showdiff[j];
																if(j<ind)
																	pDiff += this.showdiff[j];
															}

															if(Retirment2ndControlsAmt>=0 || RSAgrowthAmout1>=0 || enteredRate>=0){

																for(var l=0;l<diff;l++){
																	Retirment2ndControlsAmt+=RSAgrowthAmout1;
																	Retirment2ndControlsAmt+=(Retirment2ndControlsAmt*enteredRate)/100;
																}
																for(var l=0;l<pDiff;l++){
																	Retirment2ndControlsAmtP+=RSAgrowthAmout1;
																	Retirment2ndControlsAmtP+=(Retirment2ndControlsAmtP*enteredRate)/100;
																}   
																this.mDef12 = false;
																this.showMiddleArrowDef2= -1

																this.amntdeff = false;
																this.amntdeff1 = true;  
																this.RetContTotCalAmt1[i]=new Intl.NumberFormat().format(parseInt(Retirment2ndControlsAmt));
																this.Retirment2ndControlsA[i]= new Intl.NumberFormat().format(parseInt(Retirment2ndControlsAmtP));

															}   
															this.totalCount++; 
														}
														/*-----Tax Free2--------*/  
														if(this.RetirmentTaxFree2ndAmt[i]){
															if(this.RSAgrouwthTax1[i] == 0){
																this.RSgrwthTx1 = 0;
																let RSAgrouwthTax1 = this.RSgrwthTx1;
															}else{
																this.RSgrwthTx1 = parseInt(this.RSAgrouwthTax1[i].replace(/,/g, ''));
																let RSAgrouwthTax1 = this.RSgrwthTx1;
															}
															let RSAgrouwthTax1 = this.RSgrwthTx1;
															let RetirmentTaxFree2ndAmt:any =0;
															let RetirmentTaxFree2ndAmtP:any =0;

															if(this.RetirmentTaxFree2ndAmt[i]!=0){
																RetirmentTaxFree2ndAmt= parseInt(this.RetirmentTaxFree2ndAmt[i].replace(/,/g, '')); 
																RetirmentTaxFree2ndAmtP = RetirmentTaxFree2ndAmt
															}
															let enteredRate=this.RSAgrowthPercntTex2[i];
															let diff=0;
															let pDiff=0;
															for(var j=0;j<=ind;j++){
																diff += this.showdiff[j];
																if(j<ind)
																	pDiff += this.showdiff[j];
															}
															if(RetirmentTaxFree2ndAmt>=0 || RSAgrouwthTax1>=0 || enteredRate>=0){

																for(var l=0;l<diff;l++){
																	RetirmentTaxFree2ndAmt+=RSAgrouwthTax1;
																	RetirmentTaxFree2ndAmt+=(RetirmentTaxFree2ndAmt*enteredRate)/100;
																}
																for(var l=0;l<pDiff;l++){
																	RetirmentTaxFree2ndAmtP+=RSAgrouwthTax1;
																	RetirmentTaxFree2ndAmtP+=(RetirmentTaxFree2ndAmtP*enteredRate)/100;
																}   
																this.amnttaxF = false;
																this.amnttaxF1 = true;  
																this.RetContTotTax2[i]=new Intl.NumberFormat().format(parseInt(RetirmentTaxFree2ndAmt));
																this.RetirmentTaxFree2ndA[i]=new Intl.NumberFormat().format(parseInt(RetirmentTaxFree2ndAmtP));

															}
															this.totalCount++;    
														} 
														/*---Tax Deductible 2--------*/

														if(this.RetirmentTaxDeduct2ndAmt[i]){
															if(this.RSAgrouwthDed1[i] == 0){
																this.RSgrwthD1 = 0;
																let RSAgrouwthDed1 = this.RSgrwthD1;
															}else{
																this.RSgrwthD1 = parseInt(this.RSAgrouwthDed1[i].replace(/,/g, ''));
																let RSAgrouwthDed1 = this.RSgrwthD1;
															}
															let RSAgrouwthDed1 = this.RSgrwthD1;

															let RetirmentTaxDeduct2ndAmt:any =0;
															let RetirmentTaxDeduct2ndAmtP:any =0;

															if(this.RetirmentTaxDeduct2ndAmt[i]!=0){
																RetirmentTaxDeduct2ndAmt= parseInt(this.RetirmentTaxDeduct2ndAmt[i].replace(/,/g, '')); 
																RetirmentTaxDeduct2ndAmtP = RetirmentTaxDeduct2ndAmt;
															}
															let enteredRate=this.RSAgrowthPercntDed2[i];
															console.log(enteredRate);
															let diff=0;
															let pDiff=0;
															for(var j=0;j<=ind;j++){
																diff += this.showdiff[j];
																console.log(diff);
																if(j<ind)
																	pDiff += this.showdiff[j];
																console.log(pDiff);
															}
															if(RetirmentTaxDeduct2ndAmt>=0 || RSAgrouwthDed1>=0 || enteredRate>=0){

																for(var l=0;l<diff;l++){
																	console.log(RetirmentTaxDeduct2ndAmt)
																	RetirmentTaxDeduct2ndAmt+=RSAgrouwthDed1;
																	RetirmentTaxDeduct2ndAmt+=(RetirmentTaxDeduct2ndAmt*enteredRate)/100;
																	console.log(RetirmentTaxDeduct2ndAmt);
																}     
																for(var l=0;l<pDiff;l++){
																	console.log(RetirmentTaxDeduct2ndAmtP);
																	RetirmentTaxDeduct2ndAmtP+=RSAgrouwthDed1;
																	RetirmentTaxDeduct2ndAmtP+=(RetirmentTaxDeduct2ndAmtP*enteredRate)/100;
																	console.log(RetirmentTaxDeduct2ndAmtP);
																}
																this.amuntdeduct = false;
																this.amuntdeduct1 = true;
																this.RetContTotDed2[i]=new Intl.NumberFormat().format(parseInt(RetirmentTaxDeduct2ndAmt));
																console.log(this.RetContTotDed2[i]);
																this.RetirmentTaxDeduct2ndA[i]=new Intl.NumberFormat().format(parseInt(RetirmentTaxDeduct2ndAmtP));
															} 
															//console.log(this.totalCount); 
															this.totalCount++;  

														}
														/*------Investment--------*/
														if(this.InvestControlsAmt[i]){
															if(this.InvestAmount[i] == 0){
																this.IntGrowth = 0;
																let RSAgrowthAmout1 = this.IntGrowth;
															}else{
																this.IntGrowth = parseInt(this.InvestAmount[i].replace(/,/g, ''));
																let RSAgrowthAmout1 = this.IntGrowth;
															}
															let RSAgrowthAmout1 = this.IntGrowth;
															let InvestControlsAmt:any =0;
															let InvestControlsAmtP:any =0;

															if(this.InvestControlsAmt[i]!=0){
																InvestControlsAmt= parseInt(this.InvestControlsAmt[i].replace(/,/g, '')); 
																InvestControlsAmtP = InvestControlsAmt;
															}
															let enteredRate=this.RSAgrowthInvest[i];
															let diff=0;
															let pDiff=0;
															for(var j=0;j<=ind;j++){
																diff += this.showdiff[j];
																if(j<ind)
																	pDiff += this.showdiff[j];
															}
															if(InvestControlsAmt>=0 || RSAgrowthAmout1>=0 || enteredRate>=0){

																for(var l=0;l<diff;l++){
																	InvestControlsAmt+=RSAgrowthAmout1;
																	InvestControlsAmt+=(InvestControlsAmt*enteredRate)/100;
																}   
																for(var l=0;l<pDiff;l++){
																	InvestControlsAmtP+=RSAgrowthAmout1;
																	InvestControlsAmtP+=(InvestControlsAmtP*enteredRate)/100;
																} 
																this.amntInvst = false;
																this.amntInvst1 = true; 
																this.InvestControlsA[i]=new Intl.NumberFormat().format(parseInt(InvestControlsAmtP));
																this.InvestAmt[i]=new Intl.NumberFormat().format(parseInt(InvestControlsAmt));
															} 
															this.totalCount++;   
														}
													}
												}


				dataSaving(){
					let data = 50;
					for(var i=0;i<data;i++){
						if(this.SavingControlsAmt[i]){
							let savingA = parseInt(this.SavAmount[i].replace(/,/g, '')); 
							let SavingControlsAmt:any =0;

							if(this.SavingControlsAmt[i]!=0)
								SavingControlsAmt= parseInt(this.SavingControlsAmt[i].replace(/,/g, '')); 
								let enteredRate= this.SavingSidbar[i];
								 //let diff = this.stop[i] - this.start[i];
								 let diff = this.showAge;
									//let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
									if(SavingControlsAmt>=0 || savingA>=0 || enteredRate>=0){
										//this.SavingControlsAmt[i] = this.TotSav[i];
										for(var l=0;l<diff;l++){
											SavingControlsAmt+=savingA;
											SavingControlsAmt+=(SavingControlsAmt*enteredRate)/100;
											
										}     
										this.TotSav[i]=new Intl.NumberFormat().format(parseInt(SavingControlsAmt));

									}   
								}
							}
							for(var i=0;i<data;i++){

								if(this.CollageControlsAmt[i]){
									let collageA = parseInt(this.ColAmount[i].replace(/,/g, '')); 
									let CollageControlsAmt:any =0;

									if(this.CollageControlsAmt[i]!=0)
										CollageControlsAmt= parseInt(this.CollageControlsAmt[i].replace(/,/g, '')); 

									let enteredRate=this.CollageSidebar[i];
									let diff = this.showAge;
						  //let diff = this.stop[i] - this.start[i];
							//let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
							
							if(CollageControlsAmt>=0 || collageA>=0 || enteredRate>=0){

								for(var l=0;l<diff;l++){
									CollageControlsAmt+=collageA;
									CollageControlsAmt+=(CollageControlsAmt*enteredRate)/100;
									
								}     
								this.TotCol[i]=new Intl.NumberFormat().format(parseInt(CollageControlsAmt));
							}    
						}
					}
					for(var i=0;i<data;i++){
						if(this.RetirmentControlsAmt[i]){
							let RSAgrowthAmout = parseInt(this.RSAgrouwthAmount[i].replace(/,/g, '')); 
							let RetirmentControlsAmt:any =0;

							if(this.RetirmentControlsAmt[i]!=0)
								RetirmentControlsAmt= parseInt(this.RetirmentControlsAmt[i].replace(/,/g, '')); 

							let enteredRate=this.RSAgrowthPercnt[i];
							let diff = this.showAge;
						  //let diff = this.stop[i] - this.start[i];
							//let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
							
							if(RetirmentControlsAmt>=0 || RSAgrowthAmout>=0 || enteredRate>=0){
								for(var l=0;l<diff;l++){
									RetirmentControlsAmt+=RSAgrowthAmout;
									RetirmentControlsAmt+=(RetirmentControlsAmt*enteredRate)/100;               
								}
							  //this.changeAmount();
							  this.amntTest = false;
							  this.amntTest1 = true;
							  this.RetirmentTaxFreeA[i]= this.RetContTotCalAmt[i];
							  this.RetContTotCalAmt[i]=new Intl.NumberFormat().format(parseInt(RetirmentControlsAmt));
							}    
						}
					}
					for(var i=0;i<data;i++){
						if(this.RetirmentTaxFreeAmt[i]){
							let RSAgrouwthTax = parseInt(this.RSAgrouwthTax[i].replace(/,/g, '')); 
							let RetirmentTaxFreeAmt:any =0;

							if(this.RetirmentTaxFreeAmt[i]!=0)
								RetirmentTaxFreeAmt= parseInt(this.RetirmentTaxFreeAmt[i].replace(/,/g, '')); 

							let enteredRate=this.RSAgrowthPercntTex1[i];
							let diff = this.showAge;
						 // let diff = this.stop[i] - this.start[i];
						   // let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         

						   if(RetirmentTaxFreeAmt>=0 || RSAgrouwthTax>=0 || enteredRate>=0){

						   	for(var l=0;l<diff;l++){
						   		RetirmentTaxFreeAmt+=RSAgrouwthTax;
						   		RetirmentTaxFreeAmt+=(RetirmentTaxFreeAmt*enteredRate)/100;

						   	}
						   	this.amntTax = false;
						   	this.RetirmentTaxFreeA[i] = this.RetContTotTax1[i];
						   	this.RetContTotTax1[i]=new Intl.NumberFormat().format(parseInt(RetirmentTaxFreeAmt));
						   }    
						}
					}
					for(var i=0;i<data;i++){
						if(this.RetirmentDeductAmt[i]){
							let RSAgrouwthDed = parseInt(this.RSAgrouwthDed[i].replace(/,/g, '')); 
							let RetirmentDeductAmt:any =0;

							if(this.RetirmentDeductAmt[i]!=0)
								RetirmentDeductAmt= parseInt(this.RetirmentDeductAmt[i].replace(/,/g, '')); 

							let enteredRate=this.RSAgrowthPercntDed1[i];
							let diff = this.showAge;
							  //let diff = this.stop[i] - this.start[i];
								//let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
								
								if(RetirmentDeductAmt>=0 || RSAgrouwthDed>=0 || enteredRate>=0){

									for(var l=0;l<diff;l++){
										RetirmentDeductAmt+=RSAgrouwthDed;
										RetirmentDeductAmt+=(RetirmentDeductAmt*enteredRate)/100;               
									}     
									this.RetContTotDed1[i]=new Intl.NumberFormat().format(parseInt(RetirmentDeductAmt));
								}    
							}
						}
						for(var i=0;i<data;i++){
							if(this.Retirment2ndControlsAmt[i]){
								let RSAgrowthAmout1 = parseInt(this.RSAgrouwthAmount1[i].replace(/,/g, '')); 
								let Retirment2ndControlsAmt:any =0;

								if(this.Retirment2ndControlsAmt[i]!=0)
									Retirment2ndControlsAmt= parseInt(this.Retirment2ndControlsAmt[i].replace(/,/g, '')); 

								let enteredRate=this.RSAgrowthPercnt1[i];
								let diff = this.showAge;
					  //let diff = this.stop[i] - this.start[i];
					   // let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
					   
					   if(Retirment2ndControlsAmt>=0 || RSAgrowthAmout1>=0 || enteredRate>=0){

					   	for(var l=0;l<diff;l++){
					   		Retirment2ndControlsAmt+=RSAgrowthAmout1;
					   		Retirment2ndControlsAmt+=(Retirment2ndControlsAmt*enteredRate)/100;

					   	}     
					   	this.RetContTotCalAmt1[i]=new Intl.NumberFormat().format(parseInt(Retirment2ndControlsAmt));
					   }    
					}
				}
				for(var i=0;i<data;i++){
					if(this.RetirmentTaxFree2ndAmt[i]){
						let RSAgrouwthTax1 = parseInt(this.RSAgrouwthTax1[i].replace(/,/g, '')); 
						let RetirmentTaxFree2ndAmt:any =0;

						if(this.RetirmentTaxFree2ndAmt[i]!=0)
							RetirmentTaxFree2ndAmt= parseInt(this.RetirmentTaxFree2ndAmt[i].replace(/,/g, '')); 

						let enteredRate=this.RSAgrowthPercntTex2[i];
						let diff = this.showAge;
				  //let diff = this.stop[i] - this.start[i];
					//let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
					
					if(RetirmentTaxFree2ndAmt>=0 || RSAgrouwthTax1>=0 || enteredRate>=0){

						for(var l=0;l<diff;l++){
							RetirmentTaxFree2ndAmt+=RSAgrouwthTax1;
							RetirmentTaxFree2ndAmt+=(RetirmentTaxFree2ndAmt*enteredRate)/100;
							
						}     
						this.RetContTotTax2[i]=new Intl.NumberFormat().format(parseInt(RetirmentTaxFree2ndAmt));
					}    
				}
			}
			for(var i=0;i<data;i++){
				if(this.RetirmentTaxDeduct2ndAmt[i]){
					let RSAgrouwthDed1 = parseInt(this.RSAgrouwthDed1[i].replace(/,/g, '')); 
					let RetirmentTaxDeduct2ndAmt:any =0;

					if(this.RetirmentTaxDeduct2ndAmt[i]!=0)
						RetirmentTaxDeduct2ndAmt= parseInt(this.RetirmentTaxDeduct2ndAmt[i].replace(/,/g, '')); 

					let enteredRate=this.RSAgrowthPercntDed2[i];
					let diff = this.showAge;
				  //let diff = this.stop[i] - this.start[i];
					//let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
					
					if(RetirmentTaxDeduct2ndAmt>=0 || RSAgrouwthDed1>=0 || enteredRate>=0){

						for(var l=0;l<diff;l++){
							RetirmentTaxDeduct2ndAmt+=RSAgrouwthDed1;
							RetirmentTaxDeduct2ndAmt+=(RetirmentTaxDeduct2ndAmt*enteredRate)/100;

						}     
						this.RetContTotDed2[i]=new Intl.NumberFormat().format(parseInt(RetirmentTaxDeduct2ndAmt));
					}    
				}
			}
			for(var i=0;i<data;i++){
				if(this.InvestControlsAmt[i]){
					let RSAgrowthAmout1 = parseInt(this.InvestAmount[i].replace(/,/g, '')); 
					let InvestControlsAmt:any =0;

					if(this.InvestControlsAmt[i]!=0)
						InvestControlsAmt= parseInt(this.InvestControlsAmt[i].replace(/,/g, '')); 
					let enteredRate=this.RSAgrowthInvest[i];
					let diff = this.showAge;
			  //let diff = this.stop[i] - this.start[i];
				//let diff=this.txtAgePrjPrf.nativeElement.value-this.txtAgePrf.nativeElement.value;         
				
				if(InvestControlsAmt>=0 || RSAgrowthAmout1>=0 || enteredRate>=0){

					for(var l=0;l<diff;l++){
						InvestControlsAmt+=RSAgrowthAmout1;
						InvestControlsAmt+=(InvestControlsAmt*enteredRate)/100;
						
					}     
					this.InvestAmt[i]=new Intl.NumberFormat().format(parseInt(InvestControlsAmt));
				}    
			}
		}
	}


	/*---------------Interval Box Start Here---------------*/
	InterValCol(){
		this.intervalControls++;
 //this.NameControl.push('Name '+this.intervalControls);
}

InterValRem(){
	if(this.intervalControls>1)
		this.intervalControls--; 
		//this.NameControl.splice(this.intervalControls,1);
	}

	NameIntCol(i){
		this.accordianData = i;
		this.showYear = false;
		this.iconShow = true;
//this.expandedIndex = i === this.expandedIndex ? -1 : i;  
}

NameIntExp(i){
	this.accordianData = i;
	this.showYear = true;
	this.iconShow = false;
//this.expandedIndex = i === this.expandedIndex ? -1 : i; 
}
/*---------------Interval Box End Here---------------*/



getname($evt){
	this.client = this.txtAndrew.nativeElement.value;
}

getname1($evt){
	this.spouse = this.txtDebbie.nativeElement.value;
}

showIntValue(){
	this.showInt = true;
}

SumofAll(){
	let total2Ret :number = 0;
	for(var i in this.RetirmentPension1){
		let fetch13:any = '';
		fetch13 = this.RetirmentPension1[i];
		if(fetch13!=0)
			total2Ret  += parseInt(fetch13.replace(/,/g, ''));
	}
	let pension = total2Ret;

	let total :number = 0;
	for(var i in this.RetirmentControlsDed){
		let fetch1:any = '';
		fetch1 = this.RetirmentControlsDed[i];
		if(fetch1!=0)
			total  += parseInt(fetch1.replace(/,/g, ''));
	}
	let deduct = total;



	let total4 :number = 0;
	for(var i in this.RetirmentControlsTaxFree){
		let fetch13:any = '';
		fetch13 = this.RetirmentControlsTaxFree[i];
		if(fetch13!=0)
			total4  += parseInt(fetch13.replace(/,/g, ''));
	}
	let taxfree = total4;
	let total2 :number = 0;
	for(var i in this.RetirmentControlsDeffTax){
		let fetch13:any = '';
		fetch13 = this.RetirmentControlsDeffTax[i];
		if(fetch13!=0)
			total2  += parseInt(fetch13.replace(/,/g, ''));
	}

	let taxDeffered = total2;

	if(pension >=0 || deduct >=0 || taxfree>=0 || taxDeffered>=0){
		pension+=deduct;
		taxfree+=pension;
		taxDeffered+=taxfree;
	}

	this.TotalAll1 = new Intl.NumberFormat().format(taxDeffered);
}

SumofAcc(){
	let total:number=0;
	for(var i in this.RetirmentControlsAmt) {
		let fetch11:any = '';
		fetch11=this.RetirmentControlsAmt[i];
		if(fetch11!=0)
			total += parseInt(fetch11.replace(/,/g, ''));               
	}
	let defferd = total;
	console.log(defferd);

	let total1:number=0;
	for(var i in this.RetirmentTaxFreeAmt) {
		let fetchfree:any='';
		fetchfree=this.RetirmentTaxFreeAmt[i];
		if(fetchfree!=0)
			total1 += parseInt(fetchfree.replace(/,/g, ''));
		
	}
	let taxfree = total1;
	console.log(taxfree);

	let total2:number=0;
	for(var i in this.RetirmentDeductAmt) {
		let fetchAmt:any='';
		fetchAmt=this.RetirmentDeductAmt[i];
		if(fetchAmt!=0)

			total2 += parseInt(fetchAmt.replace(/,/g, ''));
		
	}
	let deduct = total2;
	console.log(deduct);


	if(defferd >=0 || deduct >=0 || taxfree>=0){
		defferd+=taxfree;
		deduct+=defferd;
	//deffered2+=deduct;
}

this.TotalAllR = new Intl.NumberFormat().format(deduct);
console.log(this.TotalAllR);
}

SumofAll2(){
	let total :number = 0;
	for(var i in this.RetPension1){
		let fetch13:any = '';
		fetch13 = this.RetPension1[i];
		if(fetch13!=0)
			total  += parseInt(fetch13.replace(/,/g, ''));
	}
	let pension = total;

	let total2 :number=0;
	for(var i in this.RetirmentControlsDeffTax1){
		let fetch12:any = '';
		fetch12 = this.RetirmentControlsDeffTax1[i];
		if(fetch12!=0)
			total2  += parseInt(fetch12.replace(/,/g, ''));
	}
	let taxDeffered = total2;
	let total3 :number = 0;
	for(var i in this.RetirmentControlsTaxFree1){
		let fetch4:any = '';
		fetch4 = this.RetirmentControlsTaxFree1[i];
		if(fetch4!=0)
			total3  += parseInt(fetch4.replace(/,/g, ''));
	}
	let taxfree = total3;
	let total22 :number=0;
	for(var i in this.RetirmentControlsDed1) {
		let fetch12: any = '';
		fetch12 = this.RetirmentControlsDed1[i];

		if(fetch12!=0)
			total22 += parseInt(fetch12.replace(/,/g, ''));
	}
	let deduct = total22;

	if(pension >=0 || deduct >=0 || taxfree>=0 || taxDeffered>=0){
		pension+=deduct;
		taxfree+=pension;
		taxDeffered+=taxfree;
	}

	this.TotalAll2 = new Intl.NumberFormat().format(taxDeffered);
}

SumofAcc1(){
	let total:number=0;
	for(var i in this.RetirmentTaxDeduct2ndAmt) {
		console.log(this.RetirmentTaxDeduct2ndAmt[i]);
		let fetchAmt:any='';
		fetchAmt=this.RetirmentTaxDeduct2ndAmt[i];
		if(fetchAmt!=0)

			total += parseInt(fetchAmt.replace(/,/g, ''));      
	}
	let deduct = total;
	console.log(deduct);

	let total1:number=0;
	for(var i in this.RetirmentTaxFree2ndAmt) {
		let fetch:any='';
		fetch=this.RetirmentTaxFree2ndAmt[i];
		if(fetch!=0)
			total1 += parseInt(fetch.replace(/,/g, ''));

	}
	let taxfree = total1;
	
	let total3:number=0;
	for(var i in this.Retirment2ndControlsAmt) {
		let fetchA:any='';
		fetchA=this.Retirment2ndControlsAmt[i];
		if(fetchA!=0)
			total3 += parseInt(fetchA.replace(/,/g, ''));
	}
	let defferd = total3;


	if(defferd >=0 || deduct >=0 || taxfree>=0){
		defferd+=taxfree;
		deduct+=defferd;
	}

	this.TotalAllR1 = new Intl.NumberFormat().format(deduct);
	this.clickdef2 = false;
	this.beforeclick2 = true;
}

Sumofclick(){
	let total4:number=0;
	for(var i in this.RetirmentControlsA) {
		let fetch12:any = '';
		fetch12=this.RetirmentControlsA[i];
		if(fetch12!=0)
			total4 += parseInt(fetch12.replace(/,/g, ''));              
	}

	let deffered2 = total4;
	let total:number=0;
	for(var i in this.RetirmentTaxFreeA) {
		let fetchfree:any='';
		fetchfree=this.RetirmentTaxFreeA[i];
		if(fetchfree!=0)
			total += parseInt(fetchfree.replace(/,/g, ''));
		
	}
	let taxfree = total;

	let total3:number=0;
	for(var i in this.RetirmentDeductA) {
		let fetchf:any='';
		fetchf=this.RetirmentDeductA[i];
		if(fetchf!=0)
			total3 += parseInt(fetchf.replace(/,/g, ''));
		
	}
	let taxdedd = total3;
	if(deffered2 >=0 || taxfree>=0 || taxdedd>=0){
		deffered2+=taxfree;
		taxdedd+=deffered2;
	}
	this.TotalAllR11 = new Intl.NumberFormat().format(taxdedd);
	this.clickdef = true;
	this.beforeclick = false;
}

Sumofclick2(){
	let total4:number=0;
	for(var i in this.Retirment2ndControlsA) {
		let fetch12:any = '';
		fetch12=this.Retirment2ndControlsA[i];
		if(fetch12!=0)
			total4 += parseInt(fetch12.replace(/,/g, ''));              
	}

	let deffered2 = total4;
	let total:number=0;
	for(var i in this.RetirmentTaxFree2ndA) {
		let fetchfree:any='';
		fetchfree=this.RetirmentTaxFree2ndA[i];
		if(fetchfree!=0)
			total += parseInt(fetchfree.replace(/,/g, ''));
		
	}
	let taxfree = total;

	let total3:number=0;
	for(var i in this.RetirmentTaxDeduct2ndA) {
		let fetchf:any='';
		fetchf=this.RetirmentTaxDeduct2ndA[i];
		if(fetchf!=0)
			total3 += parseInt(fetchf.replace(/,/g, ''));
		
	}
	let taxdedd = total3;
	if(deffered2 >=0 || taxfree>=0 || taxdedd>=0){
		deffered2+=taxfree;
		taxdedd+=deffered2;
	}
	this.TotalAllR2 = new Intl.NumberFormat().format(taxdedd);
	this.clickdef2 = true;
	this.beforeclick2 = false;
}


update(data){
	this.auth.addData(data);
	const user = this.auth.getUser();
	if(user._id){
		let res = user;
		this.adminN = user.Admin_Name;
		this.ShowCltName = true;
		this.clientt[0] = user.Firstname
		this.spousee[0] = user.Spouse_First;
		this.ClName = user.Firstname;
		if(user.Spouse_First == ''){
			this.spList = false
		}else{
			this.spList = true
			this.SpName = user.Spouse_First;
		}
	}
}



fetchName(event){

	let data = event.target.value;
	if(event.target.value == ""){
		this.showSearch = false;
	}else{
			//this.searchResult1 = []
			
			this.showSearch = true;
			var query = {
				"Admin_Name": event.target.value
			}

			this._amunt.getClientList().subscribe(res => {
				let arr=[];
				let data: any = res;
				this.dataa = res;
				for(let item of this.dataa){
					arr.push({Admin_Name: item.Admin_Name, _id:item._id})
				}
				this.items = arr;
		});     /*this._amunt.getClient(query).subscribe(res => {
			let data: any = res;
			this.dataa = res;
			for(var i=0;i<this.dataa.length;i++){
				this.searchResult1 = this.dataa;


			}  
		});*/
	}

}


getClientList(_id,name){
	let param = {
		'Admin_Name': name,
		'Admin_Id': _id
	}   
	this.loader = true;
	this._ret.getList(param).subscribe(res =>{
		this.loader = false;

		let data1: any = res;
		if(data1 != 0){
			let parms = {
				'_id': _id
			}   
			this._amunt.getCltList(parms).subscribe(res =>{
				let data: any = res;
				this.auth.addData(data);
				this.router.navigate(['/edit-client'])
			});
		}else{

			this._Sav.getList(param).subscribe(res =>{
				let data1: any = res;
				if(data1 != 0){
					let parms = {
						'_id': _id
					}   
					this._amunt.getCltList(parms).subscribe(res =>{

						let data: any = res;
						this.auth.addData(data);
						this.router.navigate(['/edit-client'])
					});
				}else{

					let parms = {
						'_id': _id
					}   
					this._amunt.getCltList(parms).subscribe(res =>{
						let data: any = res;
						this.auth.addData(data);
						this.searchInput = "";
						this.showSearch = false;
						setTimeout(() => {
							window.location.reload();
						}, );
					});
				}
			});

		}
	});

}

private getDismissReason(reason: any): string {
	if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
	} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
	} else {
		return `with: ${reason}`;
	}
}


openModal() {
	const ngmodalRef = this.ngbService.open(AddClientComponent, {
		size: 'sm',
		backdrop: 'static'
	});

	ngmodalRef.componentInstance.updateNewClient.subscribe((rdata) => {
		let data:any = rdata;
		this.dataA = rdata;
		this.admin = data._id;
		this.adminN  = data.Admin_Name;
		let adminId = this.admin;
		this.showClient = true
		this.update(data)
		this.ShowAddClt = false;
		this.ShowCltName = true;
	});
}


Index(){
	const user = this.auth.getUser();
	if(user == false){
		this.router.navigate(['/index']);
	}else{
		let param = {
			"Admin_Name": user.Admin_Name,
			"Admin_Id": user._id
		}
		this._ret.getList(param).subscribe(res =>{
			let data: any =  res;
			if(data != 0){
				this.router.navigate(['/edit-client']);

			}else{
				this._Sav.getList(param).subscribe(res =>{
					let data: any =  res;
					if(data != 0){
						this.router.navigate(['/edit-client']);
					}else{

						this.router.navigate(['/index']);
					}
				});

			}
		});
	}
}

/*    Index(){
		  const user = this.auth.getUser();
		  if(user == false){
			 this.router.navigate(['/index']);
			}else{
		 let param = {
			"Admin_Name": user.Admin_Name,
			"Admin_Id": user._id
		}
		this._ret.getList(param).subscribe(res =>{
		let data: any=  res;
		if(data != 0){
			this.router.navigate(['/edit-client']);

		}else{

			this.router.navigate(['/index']);
		}
	 });

   }
}*/   

logOut(){
	this.auth.removeData();
	this.router.navigate(['/']);
}

}


