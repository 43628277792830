
<div class="modal-dialog modal-dialog modal-sm">
    <div class="modal-content">
        <div class="modal-header modal-new">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeForm()">
                <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel">Add Client</h4>
        </div>
        <div class="modal-body modal-new">
            <div class="account-area">
                <form [formGroup]="adminForm" (ngSubmit)="submitClient(adminForm)" name="adminForm">

                    <div class="form-group">
                        <label for="first">First Name</label>
                        <input type="text" placeholder="First name" style="text-transform: capitalize;" class="form-control" formControlName="first"required>
                        <span class="errorClr" *ngIf="!adminForm.get('first').valid && adminForm.get('first').touched">First name is required</span> 
                    </div>

                    <div class="form-group">
                        <label for="last">Last Name</label>
                        <input type="text" placeholder="Last name" style="text-transform: capitalize;" class="form-control" formControlName="last"required>
                        <span class="errorClr" *ngIf="!adminForm.get('last').valid && adminForm.get('last').touched">Last name is required</span>                     
                    </div>

                    <div class="form-group state_div">
                        <label class="pnum">State</label>
                        <select name="state" id="state" formControlName="state" class="form-control width">
                            <option value="" selected="selected">Select a State</option>
                            <option value="Alabama">Alabama</option>
                            <option value="Alaska">Alaska</option>
                            <option value="Arizona">Arizona</option>
                            <option value="Arkansas">Arkansas</option>
                            <option value="California">California</option>
                            <option value="Colorado">Colorado</option>
                            <option value="Connecticut">Connecticut</option>
                            <option value="Delaware">Delaware</option>
                            <option value="District Of Columbia">District Of Columbia</option>
                            <option value="Florida">Florida</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Idaho">Idaho</option>
                            <option value="Illinois">Illinois</option>
                            <option value="Indiana">Indiana</option>
                            <option value="Iowa">Iowa</option>
                            <option value="Kansas">Kansas</option>
                            <option value="Kentucky">Kentucky</option>
                            <option value="Louisiana">Louisiana</option>
                            <option value="Maine">Maine</option>
                            <option value="Maryland">Maryland</option>
                            <option value="Massachusetts">Massachusetts</option>
                            <option value="Michigan">Michigan</option>
                            <option value="Minnesota">Minnesota</option>
                            <option value="Mississippi">Mississippi</option>
                            <option value="Missouri">Missouri</option>
                            <option value="Montana">Montana</option>
                            <option value="Nebraska">Nebraska</option>
                            <option value="Nevada">Nevada</option>
                            <option value="New Hampshire">New Hampshire</option>
                            <option value="New Jersey">New Jersey</option>
                            <option value="New Mexico">New Mexico</option>
                            <option value="New York">New York</option>
                            <option value="North Carolina">North Carolina</option>
                            <option value="North Dakota">North Dakota</option>
                            <option value="Ohio">Ohio</option>
                            <option value="Oklahoma">Oklahoma</option>
                            <option value="Oregon">Oregon</option>
                            <option value="Pennsylvania">Pennsylvania</option>
                            <option value="Rhode Island">Rhode Island</option>
                            <option value="South Carolina">South Carolina</option>
                            <option value="South Dakota">South Dakota</option>
                            <option value="Tennessee">Tennessee</option>
                            <option value="Texas">Texas</option>
                            <option value="Utah">Utah</option>
                            <option value="Vermont">Vermont</option>
                            <option value="Virginia">Virginia</option>
                            <option value="Washington">Washington</option>
                            <option value="West Virginia">West Virginia</option>
                            <option value="Wisconsin">Wisconsin</option>
                            <option value="Wyoming">Wyoming</option>
                        </select>

                        <span class="errorClr ml-1" *ngIf="!adminForm.get('state').valid && adminForm.get('state').touched">State is required</span>
                    </div>

                    <div class="married-unm">
                        <label class="married">Are you currently married?</label>
                        <input type="radio" id="married" name="marr" value="married" (click)="CurrMarried('yes')">
                        <label for="married">Married</label>
                        <input type="radio" id="unmarried" name="marr" value="unmarried" (click)="CurrMarried('no')">
                        <label for="unmarried">UnMarried</label>
                    </div>

                    <div class="form-group" *ngIf="showSpouseForm">
                        <label class="Spouse_First">Spouse First Name</label>
                        <input type="text" placeholder="First name" class="form-control" style="text-transform: capitalize;" formControlName="Spouse_First"required>
                    </div>

                    <div class="form-group" *ngIf="showSpouseForm">
                        <label class="Spouse_Last">Spouse Last Name</label>
                        <input type="text" placeholder="Last name" class="form-control" style="text-transform: capitalize;"formControlName="Spouse_Last"required>
                    </div>

                    <button type="submit" class="btn btn-primary">Save</button>
                </form>
            </div>
        </div>
    </div>
</div>