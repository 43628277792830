<!DOCTYPE html>
<html>

<body>


  <div class="center">
<h1>Sign Up</h1>
<form [formGroup]="superadmin" (ngSubmit)="submit(superadmin)" name="superadmin">
  <!-- <div class="imgcontainer">
    <img src="../assets/images/img_avatar2.png" alt="Avatar" class="avatar">
  </div> -->
          <div class="form-group">
                   <input type="text"  style="text-transform: capitalize;"  formControlName="name"required>
                   <span></span>
                <label class="name">Full Name</label>
            </div>
             <span class="clrA" *ngIf="!superadmin.get('name').valid && superadmin.get('name').touched">Please Enter Name</span>

            <div class="form-group">
                    <input type="number"  formControlName="p_num" required>
                  <span></span>
               <label class="p_num">Phone Number</label>
            </div>
            <span class="clrA" *ngIf="!superadmin.get('p_num').valid && superadmin.get('p_num').touched">Please Enter Phone Number</span>

            <div class="form-group txt_field">
          <input type="text"  class="form-control" formControlName="email" (blur)="textValidation($event)" required>
                   <span></span>
                 <label class="email">Email</label>          
          </div>
          <span style="color: red;">{{errorDisp}}</span>

       <!--    <span class="clrA" *ngIf="!superadmin.get('p_num').valid && superadmin.get('p_num').touched">Please Enter Valid Email</span> -->

          <div class="form-group txt_field">
          <input type="text"  class="form-control" formControlName="passwd"required>
                   <span></span>
                 <label class="passwd">Password</label>
          </div>
           <span class="clrA" *ngIf="!superadmin.get('passwd').valid && superadmin.get('passwd').touched">Please Enter Password</span>

             <button type="submit" class="sign">Submit</button>
        
</form>
             <div class="signlink">
             	Already have an account?<a href="javascript:void(0)" (click)="bcktologin()"> Sign in</a>
             </div>
  </div>

</body>
</html>
