import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './Home/index/index.component';
import { ProtectionComponent } from './Home/protection/protection.component';
import { ClientInfoComponent } from './Home/client-info/client-info.component';
import { EditClientComponent } from './Home/edit-client/edit-client.component';
import { LoginComponent } from './Home/login/login.component';
//import { SignUpComponent } from './Home/sign-up/sign-up.component';
import { AuthenticationGuard } from './Home/security/authentication.guard';

const routes: Routes = [

{
  path: '',
  component: LoginComponent,
  children: [
   {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
   },
   {
  path: 'login',
  component: LoginComponent,
 },
  ]
},

 

/*{
  path: 'sign-up',
  component: SignUpComponent,

},*/

  {
    path : 'client-info',
    component: ClientInfoComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'protection',
    component: ProtectionComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path : 'edit-client',
    component: EditClientComponent,
    canActivate: [AuthenticationGuard]
  },

  {
    path: '',
    component: IndexComponent,
    children: [
  {
    path: '',
    redirectTo: 'index',
    pathMatch: 'full',
  },{
       path: 'index',
       component: IndexComponent,
      canActivate: [AuthenticationGuard]
    },
   ]
  }
];
/*  {
  path: '',
  component: IndexComponent,
  children: [
  {
    path: 'dashboard',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  ]
},*/


@NgModule({
  /*imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],*/
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
