import { Component } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { Router } from "@angular/router";
import { AuthService } from './Home/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Cjcwealth360';

   constructor(private auth: AuthService,
        private router: Router,
        private userIdle: UserIdleService,
        ) { }

  ngOnInit() {

        this.userIdle.startWatching();
    this.logoutAcc();

    this.userIdle.onTimerStart().subscribe(count =>{} );
    
    this.userIdle.onTimeout().subscribe(() => console.log('Time is up!'));
}



    logoutAcc(){
 
    this.userIdle.onTimerStart().subscribe(count => {
            if(count == 150){
              /*console.log('yes')*/
              this.auth.removeData();
              this.router.navigate(['/']);
            }
          });
  }


}