 <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="">
    <meta name="author" content="">
    <title>Dashboard</title>
   
</head>

<body>
        <div class="loading" *ngIf ="loader">Loading&#8230;</div> 
    <div class="pagerow">
        <div class="laft-bar bg-light float-left">
            <div class="site-logo">
                <a href="#">
                    <img src="../assets/images/logo.png" class="side-logo" alt="Logo">
                </a>

            </div>
            <div class="admin-panel">
                 <ul> 
                     <!-- <li> <a [routerLink]="['/index']" routerLinkActive="active"><i class="fas fa-home"></i> Model</a></li> -->
                  <li> <a  (click)="Index()"><i class="fas fa-home"></i> Model</a></li>
                  <li ><a [routerLink]="['/client-info']" routerLinkActive="active"><i aria-hidden="true" class="fa fa-info-circle"></i> Client Info</a></li>
                   <li><a [routerLink]="['/protection']" routerLinkActive="active"><img src="../assets/images/protection.png" class="pro"> Protection</a></li>
                </ul>
            </div>
        </div>
        <div class="container-fluid content-area">
            <div class="cover-header">
                <div class="top-row">
                    <div class="search-wrap">

                          <input type="text" #searchbar class="option_input" style="text-transform: capitalize;" [(ngModel)]="searchInput"  (keyup)="fetchName($event)" placeholder="Search.." />
                        <button type="submit" name="submit" class="info-menu"><i class="fas fa-search"></i></button>
                          <div *ngIf="showSearch" class="search-list">
                           <li  *ngFor="let item of items|filter:searchInput" (click)="getClientList(item._id)"> {{item.Admin_Name | titlecase}}</li>
                    </div>
                 </div>
                    <div class="info-menu">
                        <ul>
                           <!--  <li><a href="#"><i class="far fa-bell"></i></a></li>
                            <li><a href="#"><i class="far fa-question-circle"></i></a></li> -->
                             <li>
                         <h4><i class="fa fa-user"></i>Login as {{adminN | titlecase}}</h4>
                          </li>
                            <li>                             
                          <button   (click)="openModal()">Add Client</button>
                            </li>
                            <li>
                        <button class="loginbtn" (click)="logOut()">Log Out</button>
                          </li>
                        </ul>
                    </div>
                </div>
               
            </div>
<div class="dashboard">

     <div class="bredcrumb">
                    <ul>
                        <li><a href="#"><i class="fas fa-home"></i> Home</a></li> -
                        <li>Dashboard</li>
                    </ul>
                </div>
                          <div class="family-info retirement protectionbox">
                                                    <div class="family-info-inner">
                                                        <div class="box-icon">
                                                            <img src="../assets/images/protection.png" class="box-img" alt="protection">
                                                        </div>
                                                        <h5>Protection</h5>
                                                        <div class="top-form">
                                                            <p class="sub-title">Liabilty</p>
                                                            <form>
                                                                <div>
                                                                    <div>
                                                                        <ul class="form-row d-flex">
                                                                            <li class="form-group w-50">
                                                                                <input type="text" placeholder="Liabilty" required>
                                                                            </li>
                                                                            <li class="form-group w-50 no-outline">
                                                                            <span class="SizeDollar">$</span>

                                                                                <input type="text" placeholder="Amount" 
                                                                                class ="SizeAmount" required>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            <p class="sub-title">Wills & Trust</p>
                                                            <form>
                                                                <div>
                                                                    <div>
                                                                        <ul class="form-row d-flex">
                                                                            <li class="form-group w-50">
                                                                                <input type="text" placeholder="Wills & Trust" required>
                                                                            </li>
                                                                            <li class="form-group w-50 no-outline">
                                                                                <span class="SizeDollar">$</span>
                                                                                <input type="text" placeholder="Amount"  class ="SizeAmount"required>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            <div class="personForm">
                                                                <h6>Andrew</h6>
                                                                <div class="d-flex ">
                                                                    <div class="w-50">
                                                                        <ol class="d-flex">
                                                                            <li><i class="fas fa-plus" aria-hidden="true" ></i></li>
                                                                            <li>Disability</li>
                                                                            <li><i class="fas fa-minus" aria-hidden="true" ></i></li>
                                                                        </ol>

                                                                        <form>
                                                                            <div>
                                                                                <div>
                                                                                    <ul class="form-row d-flex" >
                                                                                        <li class="form-group w-50">
                                                                                            <input type="text" name="txtAndrewDisab" placeholder="Disability" required>
                                                                                        </li>
                                                                                        <li class="form-group w-50 no-outline">
                                                                                            <span class="SizeDollar">$</span>
                                                                                            <input type="text" name="txtAndrewAmtDisab" placeholder="Amount" class ="SizeAmountHalf" required>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div class="w-50">
                                                                        <ol>
                                                                            <li>
                                                                                <i  class="fas fa-plus" aria-hidden="true"></i>
                                                                            </li>
                                                                            <li>
                                                                                Long Term Care
                                                                            </li>
                                                                            <li>
                                                                                <i  class="fas fa-minus" aria-hidden="true"></i>
                                                                            </li>
                                                                        </ol>
                                                                        <form>
                                                                            <div>
                                                                                <div>
                                                                                    <ul class="form-row d-flex">
                                                                                        <li class="form-group w-50">
                                                                                            <input name="LTTermName" type="text" placeholder="Term" required>
                                                                                        </li>
                                                                                        <li class="form-group w-50 no-outline">
                                                                                            <span class="SizeDollar">$</span>
                                                                                            <input name="LTTermAmt" type="text" placeholder="Amount" class ="SizeAmountHalf"
                                                                                            required>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                                <ol>
                                                                    <li>
                                                                        <i  class="fas fa-plus" aria-hidden="true"></i>
                                                                    </li>
                                                                    <li>
                                                                        Life Insurance
                                                                    </li>
                                                                    <li>
                                                                        <i  class="fas fa-minus" aria-hidden="true"></i>
                                                                    </li>
                                                                </ol>
                                                                <form>
                                                                    <div>
                                                                        <div>
                                                                            <ul  class="form-row d-flex">
                                                                                <li class="form-group w-50">
                                                                                    <input name="LifeInsurName" type="text" placeholder="Life Insurance" required>
                                                                                </li>
                                                                                <li class="form-group w-50 no-outline">
                                                                                    <span class="SizeDollar">$</span>
                                                                                    <input name="LifeInsurAmt" type="text" placeholder="Amount" class =" SizeAmountHalf" required>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                                <h6>Debbie</h6>
                                                                <div class="d-flex ">
                                                                    <div class="w-50">
                                                                        <ol>
                                                                            <li><i  class="fas fa-plus" aria-hidden="true"></i></li>
                                                                            <li>Disability</li>
                                                                            <li><i  class="fas fa-minus" aria-hidden="true"></i></li>
                                                                        </ol>
                                                                        <form>
                                                                            <div>
                                                                                <div>
                                                                                    <ul  class="form-row d-flex">
                                                                                        <li class="form-group w-50">
                                                                                            <input name="txtDebbieDisab" type="text" placeholder="Disability" required>
                                                                                        </li>
                                                                                        <li class="form-group w-50 no-outline">
 <span class="SizeDollar">$</span>

                                                                                            <input name="txtDebbieAmt" type="text" placeholder="Amount" class ="SizeAmountHalf" required>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div class="w-50">
                                                                        <ol>
                                                                            <li>
                                                                                <i  class="fas fa-plus" aria-hidden="true"></i>
                                                                            </li>
                                                                            <li>
                                                                                Long Term Care
                                                                            </li>
                                                                            <li>
                                                                                <i  class="fas fa-minus" aria-hidden="true"></i>
                                                                            </li>
                                                                        </ol>
                                                                        <form>
                                                                            <div>
                                                                                <div>
                                                                                    <ul  class="form-row d-flex">
                                                                                        <li class="form-group w-50">
                                                                                            <input type="text" placeholder="Term" required>
                                                                                        </li>
                                                                                        <li class="form-group w-50 no-outline">
 <span class="SizeDollar">$</span>

                                                                                            <input type="text" placeholder="Amount" class ="SizeAmountHalf" required>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                                <ol>
                                                                    <li>
                                                                        <i  class="fas fa-plus" aria-hidden="true"></i>
                                                                    </li>
                                                                    <li>
                                                                        Life Insurance
                                                                    </li>
                                                                    <li>
                                                                        <i  class="fas fa-minus" aria-hidden="true"></i>
                                                                    </li>
                                                                </ol>
                                                                <form>
                                                                    <div>
                                                                        <div>
                                                                            <ul  class="form-row d-flex">
                                                                                <li class="form-group w-50">
                                                                                    <input type="text" placeholder="Life Insurance" required>
                                                                                </li>
                                                                                <li class="form-group w-50 no-outline">
                                                                                <span class="SizeDollar">$</span>

                                                                                    <input type="text" placeholder="Amount"class ="SizeAmount"required>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

</div>
</div>
</body>
</html> 