import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './Home/index/index.component';
import { ProtectionComponent } from './Home/protection/protection.component';
import { ClientInfoComponent } from './Home/client-info/client-info.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AmountService } from './Home/services/amount.service';
import { EditClientComponent } from './Home/edit-client/edit-client.component';
//import { StoreModule } from '@ngrx/store';
//import { counterReducer } from './store/reducers/counter';
//import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoginComponent } from './Home/login/login.component';
import { SignUpComponent } from './Home/sign-up/sign-up.component';
import { AuthenticationGuard } from './Home/security/authentication.guard';
import { AuthService } from './Home/services/auth.service';
import { UserIdleModule } from 'angular-user-idle';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AddClientComponent } from './Home/component/add-client/add-client.component';
//import { HashLocationStrategy, LocationStrategy } from '@angular/common';
@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    ProtectionComponent,
    ClientInfoComponent,
    EditClientComponent,
    LoginComponent,
    SignUpComponent,
    AddClientComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    //Ng2SearchPipeModule,
    NgbModule,
   Ng2SearchPipeModule,
   HttpClientModule,
   ModalModule.forRoot(),
   /*StoreModule.forRoot({ 
    count: counterReducer
  })*/
  UserIdleModule.forRoot({idle: 3600, timeout: 1800, ping: 870}),

  ],
  providers: [AmountService,AuthenticationGuard,AuthService],
  bootstrap: [AppComponent]
  /*{provide: LocationStrategy, useClass: HashLocationStrategy}*/
})
export class AppModule { }
