import { Component, OnInit, ViewChild, ElementRef , TemplateRef } from '@angular/core';
import * as myGlob from '../../globals';
//import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth.service';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { AmountService } from '../services/amount.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
 superadmin: FormGroup
 errorDisp: any;
 constructor(private auth: AuthService,
    private fb: FormBuilder,
    private _amunt: AmountService,
    private router: Router) { }

 ngOnInit(): void {
    this.superadmin = this.fb.group({
        name: ['',[Validators.required]],
        p_num:['',[Validators.required]],
        passwd: ['',[Validators.required]],
        email: ['',[Validators.required]],
    });
}

   textValidation(event){
     let textVal=event.target.value;
    if(textVal == ''){
      this.errorDisp = 'Email is required';
    }else{
    var regexp = new RegExp(/^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}),?\s*){1,3}$/);
      var serchfind = regexp.test(textVal);
       if(!serchfind){
           this.errorDisp = 'Please Enter Valid Email';        
      }else{
        this.errorDisp = ''; 
      }
    }
   }


submit(superadmin){
    console.log(superadmin.value)
     if(this.superadmin.value.email == ''){
        this.errorDisp = 'Please Enter Valid Email';
    }
    if (this.superadmin.invalid) {
        this.superadmin.controls['passwd'].markAsTouched();
        this.superadmin.controls['email'].markAsTouched();
        this.superadmin.controls['name'].markAsTouched();
        this.superadmin.controls['p_num'].markAsTouched();
        

    }else{
       let param = {
        'Super_Admin': this.superadmin.value.name,
        'Phone': this.superadmin.value.p_num,
        'Email': this.superadmin.value.email,
        'Password': this.superadmin.value.passwd,
    }

    this._amunt.addAdmin(param).subscribe(res =>{
        console.log(param)
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Client has been added successfully.',
            showConfirmButton: false,
            timer: 1500
        });
        this.router.navigate(['/'])

    });

   }
 }

 bcktologin(){
        this.router.navigate(['/'])

 } 
}