import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TaxDeffered2 } from '../schemas/tax-deffered2';
import { TaxFree2 } from '../schemas/tax-free2';
import { TaxDeductible2 } from '../schemas/tax-deductible2';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class Retirement2Service {
      //url = 'http://localhost:4000';
      //url = 'http://cjcwealth360.com';
      url = environment.APP_URL;

  constructor(private http:HttpClient) { }

  /*---Tax Deffered For Spouse Start Here-----------*/

  addDeffered2(param: any):Observable<TaxDeffered2> {
		//return this.http.post<TaxDeffered2>(this.url+'/deffered2/add',param)
		   return this.http.post<TaxDeffered2>(this.url+'/deffered2/add',param)
	}

	getDeffered2(){
		//return this.http.get(this.url+'/deffered2/')
		   return this.http.get(this.url+'/deffered2/')
	}

	getCurrentDeffered2(parms){		
		  //return this.http.post(this.url+'/deffered2/findOne/',parms)
		    return this.http.post(this.url+'/deffered2/findOne/',parms)
	}

	updateDeffered2(param: any,name: any){
		  //return this.http.post<TaxDeffered2>(this.url+'/deffered2/update/',param,name)
		    return this.http.post<TaxDeffered2>(this.url+'/deffered2/update',param,name)
	}

	getDefferedList(parms){		
		  //return this.http.post(this.url+'/deffered2/findlist/',parms)
		      return this.http.post(this.url+'/deffered2/findlist/',parms)
	}


	/*---Tax Free For Spouse Start Here-----------*/

	addTax2(parmTax: any) {
          //return this.http.post<TaxFree2>(this.url+'/tax2/add',parmTax)
            return this.http.post<TaxFree2>(this.url+'/tax2/add',parmTax)
    }

    getTax2(){
          // return this.http.get(this.url+'/tax2/')
             return this.http.get(this.url+'/tax2/')
    }

    getCurrentTax2(parmT){
           //return this.http.post(this.url+'/tax2/findOne/',parmT)
            return this.http.post(this.url+'/tax2/findOne/',parmT)
    }

    updateTax2(parmTax: any,name: any){
           //return this.http.post<TaxFree2>(this.url+'/tax2/update',parmTax,name)
            return this.http.post<TaxFree2>(this.url+'/tax2/update',parmTax,name)
    }

    getTax2List(parms){
		   // return this.http.post(this.url+'/tax2/findTaxlist/',parms)
		      return this.http.post(this.url+'/tax2/findTaxlist/',parms)
	}


	/*---Tax Deductible For Spouse Start Here-----------*/
    addDed2(param: any) {
		   //return this.http.post<TaxDeductible2>(this.url+'/ded2/add',param)
		      return this.http.post<TaxDeductible2>(this.url+'/ded2/add',param)
	}

	getDed2(){
		  //return this.http.get(this.url+'/ded2/')
		    return this.http.get(this.url+'/ded2/')
	}

    getCurrentDed2(prmTax){
        //return this.http.post(this.url+'/ded2/findOne/',prmTax)
          return this.http.post(this.url+'/ded2/findOne/',prmTax)
    }

    updateDed2(prmTax: any,name: any){
        //return this.http.post<TaxDeductible2>(this.url+'/ded2/update',prmTax,name)
          return this.http.post<TaxDeductible2>(this.url+'/ded2/update',prmTax,name)
    }

    getListDed2(parms){
		//return this.http.post(this.url+'/ded2/findlistD/',parms)
		  return this.http.post(this.url+'/ded2/findlistD/',parms)
	}

}
