import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
 dataA: any;
 dataN: any;
    private messageSource = new BehaviorSubject('false');
  	currentMessage = this.messageSource.asObservable();

    constructor() {
    }

    changeMessage(message: string) {
    this.messageSource.next(message)
  }
  


  addData(dataA){

     localStorage.setItem('dataA', JSON.stringify(dataA));
     
  }  

   getUser(){
    if(localStorage.getItem('dataA')){
      const data = JSON.parse(localStorage.getItem('dataA'));
      return data;
    } else {
      return false;
    }
}

addSAdmin(dataN){
     localStorage.setItem('dataN', JSON.stringify(dataN));
     
  }

   getSuper(){
    if(localStorage.getItem('dataN')){
      const data = JSON.parse(localStorage.getItem('dataN'));
      return data;
    } else {
      return false;
    }
}

getRes(dataC){
  //console.log(dataC);
  localStorage.setItem('dataC', JSON.stringify(dataC))
}


removeData(){
  localStorage.removeItem("dataN");
    localStorage.removeItem("dataA");
    localStorage.removeItem("dataC");
  
}


  isLoggedIn() {
    if (localStorage.getItem('dataN') !== null) {
      return true;
    }  else {
      return false;
    }
  }

/*else if (localStorage.getItem('dataA') !== null) {
      return true;
    }*/

}
