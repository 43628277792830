import { Component, OnInit,ElementRef,TemplateRef } from '@angular/core';
import { Router } from "@angular/router";
import * as myGlob from '../../globals';
import { AuthService } from '../services/auth.service';
import { AmountService } from '../services/amount.service';
import { Retirement1Service } from '../services/retirement1.service';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Amountdetail } from '../schemas/amountdetail';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SavingService } from '../services/saving.service';
import { AddClientComponent } from '../component/add-client/add-client.component';

import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-protection',
  templateUrl: './protection.component.html',
  styleUrls: ['./protection.component.css']
})
export class ProtectionComponent implements OnInit {
  showSpouseForm: boolean = false;
  baseUrl= myGlob.baseUrl;
  showSearch: boolean =true;
  modalRef: BsModalRef;
  viewAdmin: any;
  ShowAddClt: boolean = true;
  ShowCltName: boolean = false;
  dataA: any;
  adminN:any;
  admin : any;
  searchInput: any;

  loader:boolean= false;
  searchResult1: Array<any> = [];
  dataa: any;
  idd: any;
  items: string[]
  constructor(private auth: AuthService,
    private _amunt: AmountService,
    private _ret: Retirement1Service,
    private fb: FormBuilder,
    private router: Router,
    private modalService: BsModalService,
    private _Sav : SavingService,
    private ngbService: NgbModal
    ) { }

  ngOnInit() {

    const user = this.auth.getUser();
    if(user._id){
      let res = user;
      this.adminN = user.Admin_Name;
      this.ShowCltName = true;
    }


    this._amunt.getAdminList().subscribe(res =>{
      let data: any = res;
      this.viewAdmin = data;

    })
  }

  
  openModal() {
     const ngmodalRef = this.ngbService.open(AddClientComponent, {
      size: 'sm',
      backdrop: 'static'
    });

     ngmodalRef.componentInstance.updateNewClient.subscribe((rdata) => {
          let data:any = rdata;
        this.dataA = rdata;
        this.admin = data._id;
        this.adminN  = data.Admin_Name;
        let adminId = this.admin;
          this.auth.addData(data);
        this.ShowAddClt = false;
        this.ShowCltName = true;
      });
  }

  fetchName(event){

    let data = event.target.value;
         if(event.target.value == ""){
            this.showSearch = false;
        }else{
            //this.items = []
            
            this.showSearch = true;
        var query = {
            "Admin_Name": event.target.value
        }
        
        this._amunt.getClientList().subscribe(res => {
        let arr=[];
            let data: any = res;
            this.dataa = res;
                for(let item of this.dataa){
               arr.push({Admin_Name: item.Admin_Name, _id:item._id})
            }
            this.items = arr;
        });

     /* this._amunt.getClient(query).subscribe(res => {

         let data: any = res;
         this.dataa = res;
        for(var i=0;i<this.dataa.length;i++){

         this.searchResult1 = this.dataa;

         }  
      })*/
    }
  }

  getClientList(_id){
    this.loader = true;

    this.idd = _id
    let parms = {
      '_id': this.idd
    }   
    this._amunt.getCltList(parms).subscribe(res =>{
      let data: any = res;
      this.update(data)
      this.searchInput = "";
      this.showSearch = false;
      this.loader = false;
    });
  }

  update(data){
    this.auth.addData(data);

    const user = this.auth.getUser();
    if(user._id){
      let res = user;
      this.adminN = user.Admin_Name;
      this.ShowCltName = true;
    }
  }

  Index(){
    const user = this.auth.getUser();
    if(user == false){
             this.router.navigate(['/index']);
            }else{
    let param = {
      "Admin_Name": user.Admin_Name,
      "Admin_Id": user._id
    }
     this._ret.getList(param).subscribe(res =>{
         console.log(res)
         let data: any =  res;
         if(data != 0){
            this.router.navigate(['/edit-client']);

        }else{
            this._Sav.getList(param).subscribe(res =>{
                console.log(res)
                let data: any =  res;
                if(data != 0){
                    this.router.navigate(['/edit-client']);
                }else{
                    
                    this.router.navigate(['/index']);
                }
            });

        }
    });
  }
}


  logOut(){
    this.auth.removeData();
    this.router.navigate(['/']);
  }
}